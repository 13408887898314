.cart__page {
    margin-bottom: 3rem;

    .btn-link {
        color: $color-text;
        font-weight: bold;
        padding: 0;
    }

    .cart__products {
        .list-header {
            font-weight: bold;
            font-size: 0.8rem;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
        }
        .product-block {
            background-color: #ffffff;
            padding: 1rem;
            margin-bottom: 0.5rem;
            box-shadow: $box-shadow-light;

            .btn-qtd {
                border-color: transparent;
                .btn {
                    width: 36px;
                }
            }

            a {
                color: $color-text;
                font-size: 0.9rem;
                &:hover {
                    text-decoration: none;
                }
            }
            .link-delete {
                font-weight: 100;
            }
            .produto-nome {
                font-weight: 700;
            }
            .product-content {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }

            .product-image {
                max-width: 70px;
                margin-right: 1rem;
                max-height: 130px;
            }
            label {
                display: none;
            }
            .cart-total {
                text-align: right;
            }
        }
    }

    .cart__buttons {
        &--secondary {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2rem;

            .cart__update {
                text-align: right;
            }
        }
    }

    .cart__resumo {
        background-color: #ffffff;
        padding: 1rem;
        box-shadow: $box-shadow-light;
        .title {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        .list-group-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .cart__buttons {
            margin-top: 1rem;
        }

        .shipping__block {
            width: 100%;
            display: block;
            .shipping__form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1rem 0;
                .form-input {
                    display: flex;
                }
                label {
                    margin-bottom: 0;
                }
            }
            .shipping__results {
                .result {
                    font-size: 0.85rem;
                    &__price {
                        font-weight: bold;
                        margin-left: 0.5rem;
                        color: $color-success-text;
                    }
                    &__deadline {
                        font-weight: bold;
                        margin-left: 0.5rem;
                    }
                    &__type {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}
@media (max-width: $breakpoint-lg) {
    .cart__page {
        .cart__resumo {
            margin-top: 1.5rem;
            margin-bottom: 2rem;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .cart__page {
        .cart__products {
            .product-block {
                .btn-qtd {
                    margin-top: 1rem;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .cart__page {
        .cart__buttons--secondary {
            display: flex;
            flex-direction: column-reverse;
            margin-top: 1rem;

            .cart__update {
                text-align: right;
            }

            .btn {
                width: 100%;
                margin-top: 1rem;
            }
        }

        .cart__products {
            .list-header {
                display: none;
            }

            .product-block {
                .btn-qtd {
                    .btn {
                        width: 36px;
                        padding: 10px;
                    }
                }
            }
        }
        .cart__resumo {
            margin-top: 1.5rem;
            margin-bottom: 2rem;

            .shipping__block {
                .shipping__form {
                    flex-direction: column;
                    align-items: baseline;
                    justify-content: space-between;
                    label {
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }
    .product-content {
        margin-bottom: 1rem;
    }
}
