@charset "UTF-8";
:root {
  --color-primary: rgb(51, 121, 190);
  --color-primary-r: 51;
  --color-primary-g: 121;
  --color-primary-b: 190;
  --color-secondary: #0097f5;
  --color-bg-topbar: #ffffff;
  --color-text-topbar: #333333;
  --color-bg-header: #ffffff;
  --color-text-header: rgb(51, 51, 51);
  --color-text-header-r: 51;
  --color-text-header-g: 51;
  --color-text-header-b: 51;
  --color-bg-navbar: rgb(50, 120, 189);
  --color-bg-navbar-r: 50;
  --color-bg-navbar-g: 120;
  --color-bg-navbar-b: 189;
  --color-text-navbar: rgb(255, 255, 255);
  --color-text-navbar-r: 255;
  --color-text-navbar-g: 255;
  --color-text-navbar-b: 255;
  --color-bg-footer: #3379be;
  --color-text-footer: #ffffff;
  --color-bg-footerbottom: #f7f7f7;
  --color-text-footerbottom: #333333;
  --color-bg-categories: #3379be;
  --color-bg-opacity-categories: #3379becc;
  --color-text-categories: #ffffff;
  --success-light: #e9f4eb;
  --font-primary: "Roboto", sans-serif;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #82c785;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 auto;
  margin-bottom: 2rem;
  box-shadow: inset 0px 0px 0px #82c785;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.reveal {
  position: relative;
  opacity: 0;
}
.reveal.active {
  opacity: 1;
}
.reveal.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.reveal.active.fade-left {
  animation: fade-left 1s ease-in;
}
.reveal.active.fade-right {
  animation: fade-right 1s ease-in;
}
.reveal.active.fade-in--1 {
  animation: fadeIn 1s ease-in;
}
.reveal.active.fade-in--2 {
  animation: fadeIn 2s ease-in;
}
.reveal.active.fade-in--3 {
  animation: fadeIn 3s ease-in;
}
.reveal.active.fade-in--4 {
  animation: fadeIn 4s ease-in;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #82c785;
  }
}
.animation-hide {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.ph-item {
  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px 15px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  width: 100%;
}

.ph-item,
.ph-item *,
.ph-item :after,
.ph-item :before {
  box-sizing: border-box;
}

.ph-item:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 0.8s linear infinite;
  animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}

.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-right: 15px;
  padding-left: 15px;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}

.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #dddddd;
}

.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}

.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.66667%;
}

.ph-col-4 {
  flex: 0 0 33.33333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.66667%;
}

.ph-col-10 {
  flex: 0 0 83.33333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #dddddd;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.ph-avatar:before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: #dddddd;
  margin-bottom: 15px;
}

.ph-huge-picture {
  width: 100%;
  height: 280px;
  background-color: #dddddd;
  margin-bottom: 15px;
}

.loading-content {
  width: 100%;
  height: 400px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spin {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 7px solid;
  border-color: var(--color-primary) rgba(255, 255, 255, 0.048);
  animation: 1.5s loadingSpin infinite;
}

@-webkit-keyframes loadingSpin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loadingSpin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  to {
    transform: translate3d(30%, 0, 0);
  }
}
@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  to {
    transform: translate3d(30%, 0, 0);
  }
}
/* Spin */
.icon-spin svg {
  -webkit-animation: iconSpin 2s linear infinite;
  animation: iconSpin 2s linear infinite;
}

@-webkit-keyframes iconSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes iconSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
/* Loading - CEP */
.loading-circle {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-circle div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loading-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  right: 50%;
  left: 50%;
}

.loading-circle div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-circle div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-circle div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 6px solid;
  border-color: #f8fdff #065ed033;
  animation: 1.5s spin infinite;
  transition: all 0.5s ease;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.underline-effect .underline-link {
  margin: 0 auto;
  padding: 3px 0;
  transition: all 0.1s ease-in-out;
  position: relative;
}
.underline-effect .underline-link:before, .underline-effect .underline-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0px;
  height: 1px;
  margin: 5px 0 0;
  transition: all 0.1s ease-in-out;
  transition-duration: 0.5s;
  opacity: 0;
  background-color: #f2f2f2;
}
.underline-effect .underline-link:hover:before, .underline-effect .underline-link:hover:after {
  width: 100%;
  opacity: 1;
}

/* Animate */
.animate__animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animate__animated.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@media (prefers-reduced-motion: reduce), print {
  .animate__animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*=Out] {
    opacity: 0;
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3%, 0);
    transform: translate3d(0, 3%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3%, 0);
    transform: translate3d(0, 3%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@media (max-width: 575.98px) {
  /* Placeholder Card */
  .ph-picture {
    height: 60px;
    width: 80%;
  }

  .ph-col-12 {
    flex: 0 0 50%;
  }

  .ph-row .empty {
    background-color: #ddd;
  }

  .loading-content {
    height: 150px;
  }

  .loading-spin {
    width: 40px;
    height: 40px;
    border-width: 5px;
  }
}
::selection {
  text-shadow: none;
  background: var(--color-bg-navbar);
  color: var(--color-text-navbar);
}

html {
  scroll-behavior: smooth;
}

html,
body {
  position: relative;
  overflow-y: auto;
}

.pre-loading {
  display: none !important;
}

body {
  font-family: var(--font-primary);
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #fff;
  transition: all 0.5s ease-out;
  scroll-behavior: smooth;
}
body.modal-open {
  padding-right: 0 !important;
}

#wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

#wrapper--content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 76vh;
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.wrapper-content {
  margin-top: 0;
}
.wrapper-content.top-fixed {
  margin-top: calc(38px + 115px);
}

.home__margin-top {
  margin-top: calc(38px + 115px);
}

.overlayer, .overlayer__loading {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  display: none;
  /* CEP */
}
.overlayer.show-overlayer, .show-overlayer.overlayer__loading {
  display: flex;
}
.overlayer__loading {
  display: none;
  z-index: 10002;
  align-items: center;
  justify-content: center;
}
.overlayer__loading.show-overlayer {
  display: flex;
}

a {
  color: #0156b1;
  transition: all 0.5s;
}
a:hover {
  text-decoration: none;
  opacity: 0.9;
}

strong {
  font-weight: bold;
}

hr {
  color: #aaaaaa;
}

.opacity-0 {
  opacity: 0;
}

.text-primary {
  color: #3379be;
}

.text-primary-dark {
  color: #194b97;
}

.text-dark {
  color: #333;
}

.bg-primary-light {
  background-color: #f4f4f4;
}

.pl-color {
  color: #dddddd;
}

.regioes__modal .link {
  color: #262636;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: #fff;
  font-size: 1.25rem;
}
.regioes__modal .link:hover {
  opacity: 0.8;
}
.regioes__modal .titulo {
  font-weight: bold;
  font-size: 1.5rem;
}
.regioes__modal svg {
  color: #fff;
}

.regiao__bloco {
  max-width: 280px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.regiao__card {
  border: 2px solid #fff;
  border-radius: 0.75rem;
  background-color: var(--color-primary);
}
.regiao__descricao {
  font-size: 0.75rem;
  font-weight: 300;
  font-style: italic;
}
.regiao__icone {
  padding-right: 0.5rem;
}
.regiao__icone svg {
  max-height: 28px;
}

.main-title {
  font-size: 1.5rem;
  padding-top: 3rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 1rem;
  font-weight: bold;
  color: var(--color-text);
  font-family: var(--font-primary);
  position: relative;
}
.main-title::after {
  content: " ";
  width: 4.56em;
  border-bottom: 2px solid transparent;
  border-color: var(--color-secondary);
  display: block;
  position: absolute;
  bottom: -1px;
}

.clean-list, .social-network, .navbar .menu-user, .menu-list, .topbar .topbar__menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.placeholder {
  margin: 0 auto;
  max-width: 100%;
  min-height: 200px;
  background-color: #eee;
}

.input-group img.bandeira {
  max-width: 20px;
  margin-right: 0.5rem;
}
.input-group .btn.dropdown-toggle:focus {
  box-shadow: none;
}
.input-group .input-group-text {
  border-radius: 1.5rem;
}
.input-group .dropdown-item {
  cursor: pointer;
}

.btn-dropdown-telefone {
  background-color: transparent;
  border-radius: 1.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #ced4da;
  display: flex;
  align-items: center;
}
.btn-dropdown-telefone:hover, .btn-dropdown-telefone:active {
  border-color: #ced4da !important;
}

input {
  border-radius: 2rem;
}

input.form-control, input.btn-qtd,
select.form-control,
select.btn-qtd {
  border-radius: 2rem;
  height: 38px;
}

label {
  margin-bottom: 0.5rem;
}

.custom-file-block {
  background: #ffffff;
  width: 100%;
  background-color: #f4f4f4;
}
.custom-file-block .file-name {
  padding: 0.75rem 1rem;
  border-radius: 0;
}
.custom-file-block .custom-input-file {
  background: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 0;
  width: 100%;
  border: 1px solid #f4f4f4;
}
.custom-file-block .file-success {
  border-color: #82c785;
}
.custom-file-block .file-error {
  border-color: #dc3545;
}
.custom-file-block .msg-success {
  font-weight: bold;
  color: #066806;
  background-color: #eefcf0;
}
.custom-file-block .msg-danger {
  font-weight: bold;
  color: #dc3545;
  background-color: #fdf5f5;
}

.form-group {
  margin-bottom: 1rem;
}

fieldset legend {
  font-size: 1rem;
}

.list-group-item {
  padding: 0.75rem 0;
}

/* Img - Editor */
.product-details__description img,
.product-details__description figure,
.blog__post img,
.blog__post figure,
.blog__post-details img,
.blog__post-details figure,
.navbar-footer img,
.navbar-footer figure,
.bloco-html-banner img,
.bloco-html-banner figure,
.bloco-html-destaque img,
.bloco-html-destaque figure,
.bloco-html-categorias img,
.bloco-html-categorias figure,
.bloco-html-produtos img,
.bloco-html-produtos figure {
  max-width: 100%;
}

/* Select - Safari */
select {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDkwLjY4OCA0OTAuNjg4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTAuNjg4IDQ5MC42ODg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiNGRkMxMDc7IiBkPSJNNDcyLjMyOCwxMjAuNTI5TDI0NS4yMTMsMzQ3LjY2NUwxOC4wOTgsMTIwLjUyOWMtNC4yMzctNC4wOTMtMTAuOTktMy45NzUtMTUuMDgzLDAuMjYyDQoJYy0zLjk5Miw0LjEzNC0zLjk5MiwxMC42ODcsMCwxNC44MmwyMzQuNjY3LDIzNC42NjdjNC4xNjUsNC4xNjQsMTAuOTE3LDQuMTY0LDE1LjA4MywwbDIzNC42NjctMjM0LjY2Nw0KCWM0LjIzNy00LjA5Myw0LjM1NC0xMC44NDUsMC4yNjItMTUuMDgzYy00LjA5My00LjIzNy0xMC44NDUtNC4zNTQtMTUuMDgzLTAuMjYyYy0wLjA4OSwwLjA4Ni0wLjE3NiwwLjE3My0wLjI2MiwwLjI2Mg0KCUw0NzIuMzI4LDEyMC41Mjl6Ii8+DQo8cGF0aCBkPSJNMjQ1LjIxMywzNzMuNDE1Yy0yLjgzMSwwLjAwNS01LjU0OC0xLjExNS03LjU1Mi0zLjExNUwyLjk5NCwxMzUuNjMzYy00LjA5My00LjIzNy0zLjk3NS0xMC45OSwwLjI2Mi0xNS4wODMNCgljNC4xMzQtMy45OTIsMTAuNjg3LTMuOTkyLDE0LjgyLDBsMjI3LjEzNiwyMjcuMTE1bDIyNy4xMTUtMjI3LjEzNmM0LjA5My00LjIzNywxMC44NDUtNC4zNTQsMTUuMDgzLTAuMjYyDQoJYzQuMjM3LDQuMDkzLDQuMzU0LDEwLjg0NSwwLjI2MiwxNS4wODNjLTAuMDg2LDAuMDg5LTAuMTczLDAuMTc2LTAuMjYyLDAuMjYyTDI1Mi43NDQsMzcwLjI3OQ0KCUMyNTAuNzQ4LDM3Mi4yODEsMjQ4LjAzOSwzNzMuNDA4LDI0NS4yMTMsMzczLjQxNXoiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K) no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 30px;
  width: 100px;
  padding: 5px;
  background-size: 1rem;
}

.select-date, .select-time {
  position: relative;
  display: flex;
  align-items: center;
}
.select-date input, .select-time input {
  padding-left: 40px;
}
.select-date i, .select-time i,
.select-date img,
.select-time img,
.select-date svg,
.select-time svg {
  position: absolute;
  left: 15px;
  width: 16px;
}

.select-time select {
  padding-left: 35px;
}

.default-option {
  color: #6c757d;
}

.custom-badge.badge {
  padding: 0.25rem 0.65rem;
}
.custom-badge.badge-primary {
  color: #194b97;
  background-color: #d9f4fd;
}
.custom-badge.badge-success {
  color: #245324;
  background-color: #d4edd4;
}

/* Datepicker Calendar */
.ui-state-default,
.ui-widget-content .ui-state-default {
  border-color: #fff;
  background-color: #fff;
  color: #333333;
  border-radius: 5px;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  background-color: #3379be;
  color: #fff;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight {
  background-color: #e9e9e9;
  color: #333;
}

.ui-datepicker .ui-datepicker-title select {
  padding: 0;
}

.dropdown-menu-custom, .navbar .menu-user .dropdown-menu, .navbar--block .dropdown-menu {
  border: none;
  box-shadow: 0px 2px 5px #7c44443b;
}

/* Textarea */
textarea.form-control, textarea.btn-qtd {
  border-radius: 1rem;
}

/* Alert */
.alert {
  font-weight: bold;
  font-size: 0.85rem;
}
.alert.alert-warning {
  color: #ce7d05;
  background-color: #fffaf2;
  border-color: #ff980045;
}
.alert.alert-warning .alert-link {
  color: #8c3f07;
}
.alert.alert-info {
  color: #0c5460;
  background-color: #d1ecf18c;
  border-color: #bee5eb;
}
.alert.alert-danger {
  background-color: #f8d7daab;
  border-color: #e8c5c8;
}
.alert.alert-success {
  background-color: #d4edda8f;
}

.text-success {
  color: #066806 !important;
}

.text-info {
  color: #047487 !important;
}

.text-warning {
  color: #ce7d05 !important;
}

/* Linha Ou Login */
.line-divisor {
  text-align: center;
  background-image: -webkit-linear-gradient(top, transparent 10px, #e3e3e3 0, #e3e3e3 12px, transparent 0);
  background-image: -o-linear-gradient(top, transparent 10px, #e3e3e3 10px, #e3e3e3 12px, transparent 12px);
  background-image: linear-gradient(180deg, transparent 10px, #e3e3e3 0, #e3e3e3 12px, transparent 0);
  margin-top: 2rem;
}
.line-divisor span {
  display: inline-block;
  background-color: #fff;
  padding: 0 10px;
  text-transform: none;
}

/* Empty results */
.empty-results {
  text-align: center;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50vh;
}
.empty-results h2,
.empty-results h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #262636;
}
.empty-results .icon-block {
  max-width: 100%;
  padding: 1rem;
}
.empty-results .icon-block svg {
  max-width: 100%;
}

/* Card Shadow */
.card-shadow, .card-custom-radio {
  border: 2px solid #f4f4f4;
  box-shadow: 0px 2px 5px #7c44443b;
  border-radius: 0.3rem;
  background-color: #fff;
}

.card-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-selected {
  display: none;
}

.card-custom-radio {
  box-shadow: none;
}
.card-custom-radio:hover {
  background-color: #f9f9f9;
}
.card-custom-radio .form-check {
  padding-left: 2rem;
}
.card-custom-radio .form-check-input:disabled {
  opacity: 0;
}

.card-disabled {
  opacity: 0.35;
}

.card-selected {
  background-color: var(--success-light);
  border: 2px solid #066806;
}
.card-selected:hover {
  background-color: var(--success-light);
}
.card-selected .custom-radio span, .card-selected .custom-radio--info {
  color: #066806;
}
.card-selected .radio-checkmark {
  display: none;
}
.card-selected .icon-selected {
  display: flex;
}
.card-selected .icon-selected svg {
  color: #066806;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -20px;
}

/* Custom Radio Button Card */
.custom-radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 8px;
  left: 0;
  width: 20px;
  height: 20px;
}
.custom-radio input:checked ~ .radio-checkmark {
  display: none;
}
.custom-radio--info {
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 0.85rem;
  color: #666;
}

.radio-checkmark {
  position: absolute;
  top: 1rem;
  left: -20px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
}

.icon-contrato {
  height: 180px;
}

.icon-xs svg {
  width: 10px;
}

.icon-sm svg {
  width: 15px;
}

.icon-m svg {
  width: 18px;
}

.icon-md svg {
  width: 24px;
}

.icon-lg svg {
  width: 40px;
}

.icon-x svg {
  width: 60px;
}

.icon-xl svg {
  width: 100px;
}

.icon-arrow-down svg {
  width: 12px;
  stroke: 12px;
}

.tag-ambiente-testes {
  background-color: #ffe000;
  color: #333;
  padding: 0.3rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
  position: fixed;
  z-index: 2000;
  width: 100%;
  bottom: 0;
}

.label-optional {
  font-size: 0.8rem;
  font-style: italic;
  color: #9e9e9e;
}

.form-helper-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cookies__modal {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 5px #7c44443b;
  padding: 1.5rem;
  background-color: #fff;
  z-index: 9999999;
  width: 40vw;
}
.cookies__modal a.btn {
  color: #fff;
}
.cookies__modal a.btn:hover {
  color: #fff;
}

.card__movimentacao .note {
  display: flex;
  position: relative;
}
.card__movimentacao .note .note-content {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  color: #444444;
}
.card__movimentacao .note:last-child .icon-status::after {
  border: none;
}
.card__movimentacao .icon-status {
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-right: 1rem;
}
.card__movimentacao .icon-status.icon-delivered {
  color: #194b97;
  background-color: #d9f4fd;
}
.card__movimentacao .icon-status.icon-returned {
  color: #245324;
  background-color: #d4edd4;
}
.card__movimentacao .icon-status::after {
  content: "";
  border: 1px solid #ccc;
  height: calc(100% - 40px);
  width: 1px;
  left: 19px;
  right: 0;
  position: absolute;
  bottom: 0;
}

.divProdutoDestaque {
  margin-top: 1px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  background-color: #eeeeee;
  padding-top: 30px;
  height: 0;
  width: 100%;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199.98px) {
  .cookies__modal {
    width: 50%;
    padding: 1rem;
    left: 0;
    right: 0;
    bottom: 15px;
  }
}
@media (max-width: 767.98px) {
  .cookies__modal {
    width: 90%;
    padding: 0.5rem;
    left: 0;
    right: 0;
    bottom: 85px;
  }
}
@media (max-width: 991.98px) {
  .wrapper-content.fixed-tablet {
    margin-top: 128px;
  }

  .bloco-home.home__margin-top {
    margin-top: 0;
  }

  .regiao {
    padding: 0 0.25rem;
  }
  .regiao__icone svg {
    max-width: 20px;
    max-height: 34px;
  }
}
@media (max-width: 575.98px) {
  html,
body {
    height: auto;
  }

  .wrapper-content {
    margin-top: 0;
  }
  .wrapper-content.top-fixed, .wrapper-content.fixed-tablet {
    margin-top: 0;
  }

  .home__margin-top {
    margin-top: 0;
  }

  .main-title {
    font-size: 1.2rem;
    padding-top: 2rem;
    margin-bottom: 1rem;
  }

  .empty-results {
    min-height: 40vh;
  }
  .empty-results h2 {
    margin: 1rem 0;
  }

  .form-helper-block {
    margin-bottom: 1rem;
  }

  .cookies__modal {
    width: 90%;
    padding: 0.5rem;
    left: 0;
    right: 0;
    bottom: 85px;
    font-size: 0.8rem;
  }

  .alert {
    font-size: 0.85rem;
  }

  .display-none {
    display: none;
  }
}
.topbar {
  background-color: var(--color-bg-topbar);
  color: var(--color-text-topbar);
  border-bottom: 1px solid rgba(221, 221, 221, 0.54);
  font-size: 0.85rem;
  width: 100%;
  z-index: 1020;
  height: 38px;
  top: 0;
}
.topbar a {
  color: var(--color-text-topbar);
}
.topbar .topbar__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
}
.topbar .topbar__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.topbar .topbar--full {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  font-size: 0.75rem;
  font-weight: bold;
}

@media (max-width: 991.98px) {
  .topbar .topbar--full {
    font-size: 0.5rem;
    font-weight: 100;
  }
}
@media (max-width: 575.98px) {
  .topbar {
    display: none;
  }
}
.navbar--block {
  padding: 0.5rem 0;
  background-color: var(--color-bg-navbar);
  color: var(--color-text-navbar);
}
.navbar--block.fixed-height {
  margin-top: calc(38px + 115px);
}
.navbar--block a,
.navbar--block button {
  color: var(--color-text-navbar);
  background-color: var(--color-bg-navbar);
  font-size: 0.93rem;
}
.navbar--block .navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  font-weight: bold;
}
.navbar--block .navbar-nav .link-allcategories {
  white-space: nowrap;
}
.navbar--block .navbar-nav .nav-link {
  padding: 0.5rem 0.4rem;
}
.navbar--block .navbar-nav .nav-link:hover {
  background-color: rgba(var(--color-text-navbar-r), var(--color-text-navbar-g), var(--color-text-navbar-b), 0.2);
  color: var(--color-text-navbar);
  opacity: 0.8;
  border-radius: 3px;
}
.navbar--block .dropdown-menu {
  background-color: var(--color-bg-navbar);
  color: var(--color-text-navbar);
  min-width: 20rem;
  padding: 1rem 0;
  /* Menu Horizontal - Mais que 15 categorias */
  /* Mais que 35 categorias */
}
.navbar--block .dropdown-menu .nav-link {
  padding: 0.5rem 1rem;
}
.navbar--block .dropdown-menu .nav-link:hover {
  background-color: rgba(var(--color-text-navbar-r), var(--color-text-navbar-g), var(--color-text-navbar-b), 0.2);
  color: var(--color-text-navbar);
  opacity: 0.8;
}
.navbar--block .dropdown-menu .list-many-categories {
  -webkit-column-count: 3;
  column-count: 3;
  -webkit-column-fill: auto;
  column-fill: auto;
  height: 100%;
  width: 100%;
  min-width: 50vw;
  box-sizing: border-box;
  overflow: visible;
}
.navbar--block .dropdown-menu .list-many-categories--large {
  -webkit-column-fill: auto;
  column-fill: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  -webkit-column-count: 4;
  column-count: 4;
  min-width: 65vw;
}
.navbar--block .btn-dropdown {
  color: var(--color-text-navbar);
}

.nav-link:hover {
  opacity: 0.8;
}
.nav-link.subcategory__link {
  padding-left: 2rem !important;
  font-weight: 100;
  font-size: 90%;
  background-color: var(--color-bg-navbar);
}
.nav-link__show-all {
  border-top: 1px solid rgba(var(--color-text-navbar-r), var(--color-text-navbar-g), var(--color-text-navbar-b), 0.1);
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

.menu-list .nav-link {
  padding: 0.3rem 0;
}
.menu-list .nav-link:hover {
  opacity: 0.8;
}

/* Desktop - Submenu com Hover animado */
@media all and (min-width: 992px) {
  .custom-hover-dropdown .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;
    margin-top: 0;
    display: none;
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }
  .custom-hover-dropdown .dropdown-menu li {
    position: relative;
  }
  .custom-hover-dropdown .dropdown-menu li:hover > .submenu {
    display: block;
  }
  .custom-hover-dropdown:hover {
    position: relative;
  }
  .custom-hover-dropdown:hover .dropdown-menu {
    display: block;
    position: absolute;
    transition: 0.1s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }
  .custom-hover-dropdown:hover .icon-arrow-down svg {
    transition: all 0.5s ease;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .custom-hover-dropdown .icon-arrow-right svg {
    width: 6px;
    float: right;
  }
  .custom-hover-dropdown .subcategory {
    position: relative;
  }
  .custom-hover-dropdown .subcategory .submenu {
    display: none;
    position: absolute;
    transition: 0.1s;
    opacity: 1;
    visibility: visible;
    left: 100%;
    top: 0px;
    transform: rotateX(0deg);
  }
}
.menu-icon {
  width: 32px;
  max-height: 26px;
}
.menu-icon.header__icon rect {
  fill: var(--color-bg-header);
}

@media (max-width: 767.98px) {
  .navbar--block .navbar-nav .nav-link {
    padding: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .navbar--block {
    display: none;
  }

  .nav-link.subcategory__link {
    background-color: #fff;
  }

  .menu-icon.header__icon rect {
    fill: #fff;
  }
}
@media (max-width: 767.98px) {
  .navbar--block {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar--block {
    display: none;
  }

  .custom-hover-dropdown .dropdown-menu {
    display: block;
    opacity: 1;
    border: none;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .custom-hover-dropdown .btn-dropdown {
    font-weight: 400;
    text-align: left;
  }
}
@media (min-width: 1399.98px) {
  .navbar--block .navbar-nav .nav-link {
    padding: 0.5rem 0.7rem;
  }
}
.sidebar-container, .search-container {
  height: 100%;
  width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10002;
  transform: translateX(-320px);
  transition: 0.2s linear all;
  padding: 0.5rem 1rem;
  background: #ffffff !important;
  color: #343434 !important;
  display: none;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  transition: 400ms cubic-bezier(0.4, 0, 1, 1);
  transition-property: transform;
}
.sidebar-container a, .search-container a,
.sidebar-container button,
.search-container button {
  color: #343434 !important;
}
.sidebar-container.open-block, .open-block.search-container {
  height: 100%;
  transform: translateX(0);
}
.sidebar-container li .nav-link, .search-container li .nav-link,
.sidebar-container li a,
.search-container li a {
  padding: 0.3rem 0;
}
.sidebar-container li .nav-link svg, .search-container li .nav-link svg,
.sidebar-container li a svg,
.search-container li a svg {
  width: 20px;
}
.sidebar-container .sidebar-block, .search-container .sidebar-block {
  border-top: 1px solid #eeeeee;
  padding-top: 1rem;
}
.sidebar-container .sidebar-block .sidebar-title, .search-container .sidebar-block .sidebar-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
}

@media (max-width: 992px) {
  .sidebar-container, .search-container {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .sidebar-container, .search-container {
    padding: 1.5rem 3rem;
    font-size: 1.25rem;
    display: block;
  }
}
@media (max-width: 575.98px) {
  .sidebar-container, .search-container {
    padding: 0 1rem;
    font-size: 1rem;
    display: block;
  }
}
/* Busca */
.search-block {
  display: flex;
  flex: 1;
  padding: 0 1rem;
}

.search-form {
  width: 100%;
  flex: 1;
}
.search-form input {
  border-radius: 50px;
  border-right: none;
  height: 39px;
}
.search-form input:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.search-form .input-group {
  width: 100%;
  margin: 0;
}
.search-form .input-group:focus {
  border-radius: 50px;
}
.search-form .btn-search {
  background-color: #ffffff;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: 1px solid #ced4da;
  border-left: none;
  height: 39px;
}
.search-form .btn-search:hover {
  background-color: #ffffff;
  border-color: #ced4da;
  color: #333333;
}
.search-form .btn-search:focus {
  box-shadow: none;
}

.search__results .search__results--message {
  font-weight: bold;
}

.search-container {
  top: 0;
  transform: translateY(-375px);
  transition: 0.5s ease all;
  height: 18%;
  max-width: 100%;
  min-height: 130px;
  width: 100%;
}
.search-container a,
.search-container button {
  color: var(--color-text-header);
}
.search-container.open-block {
  transform: translateY(0);
  box-shadow: 0px 2px 5px #7c44443b;
  height: auto;
  padding: 0.5rem 1rem;
}

.banner-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-mobile {
  display: none;
}

.banner-desktop {
  display: block;
}

.banner-block.container {
  padding: auto;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (max-width: 767.98px) {
  .banner-block.container {
    padding: 0;
  }

  .banner-mobile {
    display: block;
  }

  .banner-desktop {
    display: none;
  }

  .banner-tarja .container {
    padding: 0 !important;
  }
}
.nav-fixed {
  width: 100%;
  z-index: 1020;
  height: 115px;
}
.nav-fixed.with-topbar {
  position: fixed;
  top: 38px;
}

.nav-fixed.onscroll {
  top: 0;
  height: 70px;
  box-shadow: 0px 2px 5px #7c44443b;
  position: fixed;
}
.nav-fixed.onscroll .navbar-brand img {
  max-height: calc(70px - 20px);
  width: auto;
}
.nav-fixed.onscroll.with-topbar {
  top: 38px;
}

.nav-fixed + .content {
  padding-top: 102px;
}

.navbar {
  background-color: var(--color-bg-header);
  color: var(--color-text-header);
  /* Header Simples */
  /* Menu do Usuário */
}
.navbar .navbar-brand {
  width: auto;
  min-width: 200px;
}
.navbar .navbar-brand img {
  max-width: 100%;
  transition: all 0.3s;
  max-height: calc(115px - 20px);
  max-width: 250px;
  width: auto;
}
.navbar .navbar-nav {
  background-color: var(--color-bg-navbar);
  color: var(--color-text-navbar);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 200px;
}
.navbar .navbar-nav .nav-link {
  color: var(--color-text-navbar);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
}
.navbar .navbar-nav.navbar-custom {
  background-color: var(--color-bg-header);
  color: var(--color-text-header);
}
.navbar .navbar-nav.navbar-custom .nav-link {
  color: var(--color-text-header);
}
.navbar .navbar-collapse {
  align-items: center;
  justify-content: space-between;
}
.navbar .navbar-custom-block {
  justify-content: flex-end;
}
.navbar.nav-simple {
  box-shadow: 0px 2px 5px #7c44443b;
  height: 100px;
}
.navbar.nav-simple .navbar-brand img {
  max-height: calc(100px - 45px);
}
.navbar .menu-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar .menu-user a {
  font-weight: 700;
  font-size: 0.95rem;
  color: var(--color-text-header);
  background-color: var(--color-bg-header);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.3rem 1rem;
  position: relative;
  outline: none;
}
.navbar .menu-user a svg,
.navbar .menu-user a i {
  font-size: 24px;
  color: var(--color-text-header);
}
.navbar .menu-user .dropdown-menu {
  color: var(--color-text-header);
  background-color: var(--color-bg-header);
  min-width: 14rem;
}
.navbar .menu-user .dropdown-menu a:hover, .navbar .menu-user .dropdown-menu a:focus {
  color: var(--color-text-header);
  background-color: rgba(var(--color-text-header-r), var(--color-text-header-g), var(--color-text-header-b), 0.2);
}
.navbar .menu-user .dropdown-menu a:hover svg,
.navbar .menu-user .dropdown-menu a:hover i, .navbar .menu-user .dropdown-menu a:focus svg,
.navbar .menu-user .dropdown-menu a:focus i {
  color: var(--color-text-header);
}
.navbar .menu-user .dropdown-menu .logout-block {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.3rem;
  margin-top: 0.3rem;
}
.navbar .menu-user .dropdown-menu .logout-block a {
  font-weight: 400;
}
.navbar .menu-user .dropdown-menu .logout-block span {
  font-weight: bold;
}
.navbar .menu-user .dropdown-toggle::after {
  display: none;
}
.navbar .menu-user .my-account-link {
  display: block;
  font-weight: 100;
  font-size: 0.9rem;
}
.navbar .menu-user .menu-user--total {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.navbar .menu-user .menu-user--total .total-price {
  display: block;
  font-weight: 100;
  font-size: 0.9rem;
}
.navbar .cart-link {
  margin-right: 1rem;
}
.navbar .cart-link__count {
  background-color: var(--color-text-header);
  color: var(--color-bg-header);
  border-radius: 50px;
  width: 22px;
  height: 22px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  position: absolute;
  top: 5px;
  left: 30px;
  transition: all 0.5s ease-in-out;
}
.navbar .cart-link__count:hover {
  transform: scale3d(1.2, 1.2, 1.2);
}

@media (max-width: 1199.98px) {
  .navbar .navbar-custom .nav-link {
    font-size: 0.8rem;
  }
}
@media (max-width: 991.98px) {
  .navbar .navbar-brand {
    min-width: 90px;
  }
  .navbar .navbar-brand img {
    max-width: 100%;
    max-height: 70px;
  }
  .navbar a,
.navbar button {
    color: var(--color-text-header);
  }
  .navbar a:focus,
.navbar button:focus {
    color: var(--color-text-header);
  }
  .navbar .cart-link__count {
    top: 0px;
    left: 30px;
  }

  .header-mobile {
    width: 100%;
    margin: 0;
  }

  .nav-simple .header-mobile {
    width: 30%;
  }

  .nav-fixed {
    height: 90px;
  }
  .nav-fixed.onscroll {
    height: 70px;
  }
}
@media (max-width: 767.98px) {
  .navbar .cart-link__count {
    top: 0px;
    left: 30px;
    font-weight: bold;
  }
}
@media (max-width: 575.98px) {
  .nav-fixed {
    height: auto;
  }
  .nav-fixed.with-topbar {
    top: 0;
    position: relative;
  }
  .nav-fixed.onscroll {
    height: 56px;
  }
  .nav-fixed.onscroll .navbar-brand {
    width: 100px;
  }
  .nav-fixed.onscroll .navbar-brand img {
    max-height: 30px;
  }
  .nav-fixed.onscroll.with-topbar {
    top: 0;
  }

  .navbar {
    /* Header Simples */
  }
  .navbar .navbar-brand img {
    max-width: 150px;
    max-height: 40px;
  }
  .navbar .search-form .input-group {
    width: 100%;
  }
  .navbar .menu-user {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar .navbar-collapse {
    background-color: var(--color-bg-header);
    height: auto;
  }
  .navbar .navbar-toggler {
    background-color: var(--color-bg-header);
    border-color: var(--color-text-header);
  }
  .navbar a,
.navbar button {
    color: var(--color-text-header);
  }
  .navbar a:hover, .navbar a:focus,
.navbar button:hover,
.navbar button:focus {
    color: var(--color-text-header);
  }
  .navbar.nav-simple {
    height: auto;
  }
  .navbar.nav-simple .navbar-brand img {
    max-height: 40px;
  }
}
@media (min-width: 1399.98px) {
  .navbar .menu-user a {
    padding: 0.5rem 1.2rem;
  }
  .navbar .cart-link__count {
    top: 7px;
    left: 35px;
  }
}
footer, .footer-bottom {
  padding: 2rem 0;
  background-color: var(--color-bg-footer);
  color: var(--color-text-footer);
}
footer a, .footer-bottom a {
  color: var(--color-text-footer);
}
footer a:hover, .footer-bottom a:hover {
  color: var(--color-text-footer);
  opacity: 0.7;
}
footer .footer__info svg, .footer-bottom .footer__info svg {
  margin-right: 0.5rem;
}
footer .footer--title, .footer-bottom .footer--title {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
footer .navbar-nav.navbar-footer .nav-link, .footer-bottom .navbar-nav.navbar-footer .nav-link {
  display: inline-block;
  font-size: 1rem;
  padding: 0.35rem 0;
}
footer .logo-thumb, .footer-bottom .logo-thumb {
  max-width: 160px;
  margin-bottom: 1rem;
}
footer .footer__payment, .footer-bottom .footer__payment {
  display: flex;
  flex-direction: column;
}

.footer-bottom {
  padding: 1rem 0;
  background-color: var(--color-bg-footerbottom);
  color: var(--color-text-footerbottom);
  font-size: 0.8rem;
}
.footer-bottom .footer--store-details {
  font-size: 0.75rem;
}
.footer-bottom .copyright {
  display: flex !important;
  align-items: center;
  flex-direction: row-reverse;
  opacity: 1 !important;
  visibility: visible !important;
  margin: 0 auto !important;
  position: static !important;
  overflow: visible !important;
}
.footer-bottom .footer__payment-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.footer-bottom .footer__payment {
  padding-right: 1rem;
  margin-bottom: 1rem;
}
.footer-bottom .footer__payment-title {
  margin-bottom: 0.5rem;
}
.footer-bottom .footer-bottom--img {
  max-height: 30px;
}
.footer-bottom .icon-pix {
  color: #32bcad;
}
.footer-bottom .icon-pix svg {
  height: 20px;
}
.footer-bottom .icon-a-combinar svg {
  color: #262636;
  height: 20px;
}
.footer-bottom .selos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer-bottom .selos li {
  margin-right: 1.5rem;
}
.footer-bottom .selos li:not:first-child {
  margin: 0;
}

.copyright {
  opacity: 1 !important;
  display: flex !important;
  visibility: visible !important;
  margin: 0 auto !important;
  position: static !important;
  overflow: visible !important;
}

@media (max-width: 991.98px) {
  footer .footer--title, .footer-bottom .footer--title {
    margin-top: 1rem;
  }
}
@media (max-width: 575.98px) {
  footer, .footer-bottom {
    text-align: center;
  }
  footer .footer--title, .footer-bottom .footer--title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .footer-bottom {
    text-align: center;
  }
  .footer-bottom .copyright {
    margin-top: 1rem;
    flex-direction: row-reverse;
    justify-content: center;
  }
  .footer-bottom .footer__payment-block {
    flex-direction: column;
  }
  .footer-bottom .footer__payment {
    margin-bottom: 1rem;
  }
  .footer-bottom .footer__payment:last-child {
    margin-left: 0;
  }
  .footer-bottom .selos {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .footer-bottom .selos li {
    margin: 0;
    padding: 0.5rem;
  }
}
/* Categorias */
.categories--block {
  padding: 2rem 0 4rem 0;
}
.categories--block .category {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.categories--block .category .category-content {
  color: var(--color-text-categories);
  background-color: var(--color-bg-categories);
  text-shadow: 0 0 1px #555555;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  font-size: 1.35rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
}
.categories--block .category .category-content.bg-opacity {
  background-color: var(--color-bg-opacity-categories);
}
.categories--block .btn {
  border-color: var(--color-text-categories);
  color: var(--color-text-categories);
}
.categories--block .btn:hover {
  background-color: var(--color-text-categories);
  color: var(--color-bg-categories);
  text-shadow: none;
}

/* Destaques - Mais locados */
.most-rented {
  padding-bottom: 4rem;
}

/* Marcas */
.brands--block {
  padding: 0 0 4rem 0;
  background-color: #fff;
}
.brands--block .brands-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.brands--block .brand {
  margin-bottom: 1rem;
}
.brands--block .brand a:hover {
  opacity: 0.7;
}
.brands--block .brand img {
  width: auto;
}

/* Slider Marcas */
.items-carousel-scroll, .products__slider {
  position: relative;
  background-color: #ffffff;
  padding-bottom: 2rem;
}
.items-carousel-scroll .items-carousel-block, .products__slider .items-carousel-block {
  position: relative;
}
.items-carousel-scroll .carousel-content, .products__slider .carousel-content {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #ffffff;
  scroll-behavior: smooth;
}
.items-carousel-scroll .carousel-content::-webkit-scrollbar, .products__slider .carousel-content::-webkit-scrollbar {
  display: none;
}
.items-carousel-scroll .carousel-content .items-carousel, .products__slider .carousel-content .items-carousel {
  list-style: none;
  padding-left: 0;
  margin: 0;
  width: auto;
  display: inline-flex;
  align-items: center;
  transition: all 0.5s ease;
  position: relative;
  top: 0px;
  left: 0px;
}
.items-carousel-scroll .carousel-content .items-carousel .brand, .products__slider .carousel-content .items-carousel .brand {
  padding: 0 1rem;
}
.items-carousel-scroll .carousel-content .items-carousel img, .products__slider .carousel-content .items-carousel img {
  max-width: 200px;
  width: auto;
  padding: 1rem;
}
.items-carousel-scroll .carousel-control-prev, .products__slider .carousel-control-prev,
.items-carousel-scroll .carousel-control-next,
.products__slider .carousel-control-next {
  color: #333;
  top: 0;
  width: 40px;
  border-radius: 50px;
}
.items-carousel-scroll .carousel-control-prev, .products__slider .carousel-control-prev {
  left: -25px;
}
.items-carousel-scroll .carousel-control-next, .products__slider .carousel-control-next {
  right: -25px;
}

/* Slider Produtos */
.products__slider {
  background-color: #fcfcfc;
  margin-top: 3rem;
  padding-bottom: 4rem;
}
.products__slider .carousel-content {
  background-color: #fcfcfc;
  padding-bottom: 1rem;
}
.products__slider .carousel-content .card-product {
  min-height: 334px;
}
.products__slider .products-content {
  background-color: transparent;
}
.products__slider .products-content .products {
  margin-bottom: 1rem;
}
.products__slider .product {
  padding: 0;
}
.products__slider .product .product__name {
  display: block;
}
.products__slider .card {
  width: 300px;
  margin-right: 0.3rem;
  border: none;
}

/* Redes Sociais */
.social-network {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5rem;
}
.social-network li {
  margin-right: 1rem;
}
.social-network a {
  border: 1px solid var(--color-text-footer);
  padding: 5px;
  text-align: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-network a:hover {
  color: #fff;
  opacity: 1;
}
.social-network a.youtube:hover {
  background-color: #ff1300;
  border-color: #ff1300;
}
.social-network a.facebook:hover {
  background-color: #4064ac;
  border-color: #4064ac;
}
.social-network a.linkedin:hover {
  background-color: #0b66c2;
  border-color: #0b66c2;
}
.social-network a.instagram:hover {
  background-image: linear-gradient(to left, #fdc468, #df4996);
  border-color: #f9b56d;
}
.social-network a.pinterest:hover {
  background-color: #e61122;
  border-color: #e61122;
}

.breadcrumb-block {
  text-transform: uppercase;
  background-color: #fff;
}
.breadcrumb-block .breadcrumb {
  padding: 1rem 0;
  font-size: 0.8rem;
  background-color: transparent;
  margin-bottom: 0;
}
.breadcrumb-block .breadcrumb a {
  color: #262636;
  font-weight: bold;
}

/* Paginação */
.pagination-block {
  justify-content: center;
}

.pagination__products {
  justify-content: center;
  margin-top: 2rem;
}
.pagination__products .page-item.active .page-link {
  background-color: transparent;
  color: #262636;
  font-weight: bold;
  border: none;
}
.pagination__products .page-link,
.pagination__products .page-item:first-child .page-link,
.pagination__products .page-item:last-child .page-link {
  border: none;
  background-color: transparent;
  color: #262636;
  border-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
}
.pagination__products .page-link:hover,
.pagination__products .page-item:first-child .page-link:hover,
.pagination__products .page-item:last-child .page-link:hover {
  background-color: #f4f4f4;
}
.pagination__products .page-link:focus,
.pagination__products .page-item:first-child .page-link:focus,
.pagination__products .page-item:last-child .page-link:focus {
  box-shadow: none;
}

/* Bloco de Contato - Mobile */
.contact-buttons {
  padding: 1rem 0;
}
.contact-buttons svg {
  height: 24px;
}
.contact-buttons svg path {
  fill: currentColor !important;
  stroke-width: 0 !important;
}
.contact-buttons__modal {
  margin: 0 1.5rem;
}
.contact-buttons__modal .contact-option {
  background-color: #f4f4f4;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}
.contact-buttons__modal .contact-option .info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.contact-buttons__modal .contact-option .info-icon {
  color: #ccc;
  margin-right: 1rem;
}
.contact-buttons__modal .contact-option .info-description {
  font-weight: bold;
  font-size: 0.8rem;
  color: #2c2c84;
}
.contact-buttons__modal .contact-option .info-number {
  font-size: 1.25rem;
  font-weight: bold;
}
.contact-buttons__modal .modal-title {
  font-weight: bold;
}
.contact-buttons__modal .modal-header {
  border-bottom: 0;
  padding: 1rem 1rem 0;
}
.contact-buttons__modal .modal-content {
  border-radius: 1rem;
}

.strong-pass {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 0.5rem;
}
.strong-pass p {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.strong-pass p span {
  width: 6px;
  height: 6px;
  display: inline-block;
  margin-right: 10px;
  font-size: 0.7rem;
}

.password-status {
  margin: 0.25rem 0.5rem;
}
.password-status .status {
  padding-top: 3px;
}

.show-password--block {
  position: relative;
}
.show-password--block input {
  position: relative;
}
.show-password--block.reset-password .show-password {
  top: 33px;
}
.show-password--block.reset-password .show-password a {
  color: #757575;
}

.show-password {
  position: absolute;
  right: 30px;
  top: 37px;
}
.show-password a {
  color: #757575;
}

.aceite-checkbox {
  text-align: left;
}
.aceite-checkbox .form-check {
  padding-left: 1.5rem;
}
.aceite-checkbox .form-check-input {
  margin-left: -1.5rem;
  margin-top: 0.35rem;
}
.aceite-checkbox label {
  line-height: 1.8;
}

.pagina-customizada img {
  height: auto;
  max-width: 100%;
}

.custom-checkbox {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.custom-checkbox--input {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 26px;
  height: 26px;
  opacity: 0;
  position: absolute;
}
.custom-checkbox--label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.custom-checkbox .content {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.custom-checkbox .content img {
  max-width: 50px;
  max-height: 80px;
  margin-left: 0.65rem;
}
.custom-checkbox .content .product__image {
  min-width: 100px;
  text-align: center;
  margin-right: 1rem;
}

.custom-checkbox--svg input:checked + .custom-checkbox__label--svg:before {
  background: var(--color-primary);
  border-color: var(--color-primary);
}
.custom-checkbox--svg input:checked + .custom-checkbox__label--svg .path {
  stroke: #fff;
  stroke-dashoffset: 0;
}

.custom-checkbox__label {
  cursor: pointer;
}
.custom-checkbox__label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 2px solid #c5c5c5;
  margin-right: 8px;
}

.custom-checkbox__label--svg:before {
  position: relative;
  left: 0;
  width: 24px;
  height: 24px;
  transition: all 0.35s ease-out;
}
.custom-checkbox__label--svg svg {
  position: absolute;
  left: 4px;
  width: 15px;
  height: 15px;
  pointer-events: none;
}
.custom-checkbox__label--svg .path {
  stroke-dashoffset: 111.46px;
  stroke-dasharray: 111.46px;
  stroke: var(--color-primary);
  transition: all 0.35s ease-out;
}

.rating-group .rating {
  margin-bottom: 2rem;
  border: none;
}
.rating-group .rating__total .stars-block {
  width: 116px;
  height: 24px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.rating-group .rating__total .stars-original {
  position: absolute;
}
.rating-group .rating__total .stars-original svg path {
  fill: #ccc;
}
.rating-group .rating__total .stars {
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
  overflow: hidden;
  position: absolute;
}
.rating-group .rating__total .stars svg path {
  fill: #ffcc00;
}
.rating-group .rating__total .total-block {
  font-weight: bold;
  font-size: 2.5rem;
  margin-top: 0;
}
.rating-group .rating__total .total {
  font-weight: normal;
  font-size: 1rem;
  margin-top: 0.5rem;
}
.rating-group .rating__star {
  color: #ccc;
}
.rating-group .rating__note {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.rating-group .rating__note .checked svg path {
  fill: #ffcc00;
}
.rating-group .rating__title {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.rating-group .rating__name {
  font-weight: bold;
}
.rating-group .rating__date {
  font-style: italic;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.rating__form .name {
  font-weight: bold;
  font-size: 1.25rem;
}
.rating__form .review {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}
.rating__form .rating {
  margin-bottom: 1rem;
}
.rating__form .rating .stars {
  color: #ccc;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  width: 216px;
}
.rating__form .rating .stars svg {
  width: 30px;
  height: 30px;
}
.rating__form .rating .stars label {
  position: relative;
}
.rating__form .rating .stars label:hover, .rating__form .rating .stars label:hover ~ label {
  cursor: pointer;
  color: #e9c63b;
}
.rating__form .rating .stars .star {
  opacity: 0;
}
.rating__form .rating .stars .star:checked + .star label, .rating__form .rating .stars .star:checked ~ svg, .rating__form .rating .stars .star:checked ~ label, .rating__form .rating .stars .star:checked {
  color: #ffcc00;
}
.rating__form .rating label > span {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
}

@media (max-width: 1199.98px) {
  .brands__slider .carousel-control-next {
    right: -40px;
  }
  .brands__slider .carousel-control-prev {
    left: -40px;
  }
  .brands__slider .brand-link img {
    max-width: 100px;
  }

  .categories--block .category {
    margin-bottom: 2rem;
  }
}
@media (max-width: 991.98px) {
  .brands__slider .carousel-control-next {
    right: -20px;
    top: 20%;
  }
  .brands__slider .carousel-control-prev {
    left: -20px;
    top: 20%;
  }
  .brands__slider .brand-link {
    padding: 0.5rem;
  }
  .brands__slider .brand-link img {
    max-width: 70px;
    width: auto;
  }
}
@media (max-width: 575.98px) {
  .categories--block {
    padding-bottom: 2rem;
  }
  .categories--block .category {
    margin-bottom: 2rem;
  }

  .social-network {
    justify-content: center;
    margin-top: 2rem;
  }

  .most-rented {
    padding-bottom: 1rem;
  }

  .items-carousel-scroll .carousel-control-prev, .products__slider .carousel-control-prev,
.items-carousel-scroll .carousel-control-next,
.products__slider .carousel-control-next {
    display: none;
  }

  .products__slider {
    background-color: #fcfcfc;
    padding-bottom: 2rem;
  }
  .products__slider .carousel-content {
    background-color: #fcfcfc;
  }
  .products__slider .carousel-content .items-carousel .item {
    height: 175px;
  }
  .products__slider .carousel-content .items-carousel .card-product {
    min-height: 204px;
  }
  .products__slider .carousel-content .items-carousel .product__photo {
    width: 35%;
  }
  .products__slider .carousel-content .items-carousel .product__content {
    width: 65%;
  }
  .products__slider .carousel-content .items-carousel .product__photo--content {
    height: 150px;
    min-width: 120px;
  }
  .products__slider .carousel-content .items-carousel img {
    max-width: 140px;
    min-width: 100px;
    width: 100%;
    padding: 0;
  }

  .strong-pass {
    flex-direction: column;
  }
  .strong-pass p {
    margin-bottom: 0;
  }

  .custom-checkbox .content .product__image {
    min-width: 50px;
  }
  .custom-checkbox .content .product__image img {
    max-width: 46px;
  }
}
.btn {
  font-weight: 600;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-link {
  color: #0156b1;
  text-decoration: none;
}
.btn-link:hover {
  text-decoration: none;
  opacity: 0.8;
}
.btn-link:focus, .btn-link:active, .btn-link:not(:disabled):not(.disabled) {
  outline: none;
}

.btn-success {
  background-color: #2e8540;
  border-color: #2e8540;
}

.btn-see-more, .btn-show, .categories--block .btn {
  margin-top: 1rem;
  padding: 0.8rem 2.5rem;
  border-radius: 50px;
  font-family: var(--font-primary);
  transition: all 0.25s ease-in;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
}
.btn-see-more .hidden-icon, .btn-show .hidden-icon, .categories--block .btn .hidden-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  opacity: 0;
  z-index: 1;
  -moz-transform: translate3d(-5px, -50%, 0);
  -webkit-transform: translate3d(-5px, -50%, 0);
  transform: translate3d(-5px, -50%, 0);
}
.btn-see-more:hover, .btn-show:hover, .categories--block .btn:hover {
  padding-right: 3rem;
}
.btn-see-more:hover .hidden-icon, .btn-show:hover .hidden-icon, .categories--block .btn:hover .hidden-icon {
  display: inline;
  opacity: 1;
  -moz-transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.btn-show {
  padding: 0.5rem 1rem;
  margin-top: 0;
}

.btn-go-back {
  text-align: initial;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #444444;
}

.btn-dropdown:focus {
  box-shadow: none;
  outline: none;
}

.btn-rounded, .btn-qtd, .btn-rent, .btn-login-google, .btn-login-facebook, .btn-cep, .btn-download, .btn-download-small, .btn-filter, .btn-buy {
  border-radius: 2rem;
}

.btn-close {
  border-radius: 1rem;
  padding: 1rem 0.65rem;
}

.btn-outline-primary {
  background-color: transparent;
  color: var(--color-primary);
  border-color: var(--color-primary);
}
.btn-outline-primary:hover {
  border-color: var(--color-primary) !important;
  background-color: rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 1) !important;
  color: #ffffff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 1);
  color: #ffffff;
}
.btn-outline-primary:focus {
  background-color: rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 1);
  color: #ffffff;
}

.btn-cancel {
  margin-top: 0;
}

.btn-custom-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #ffffff;
}
.btn-custom-primary:hover {
  background-color: var(--color-primary) !important;
  color: #ffffff !important;
  box-shadow: 0px 0px 2px #333;
}

.btn-rent, .btn-login-google, .btn-login-facebook, .btn-cep, .btn-download, .btn-download-small, .btn-filter, .btn-buy {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-rent:hover, .btn-login-google:hover, .btn-login-facebook:hover, .btn-cep:hover, .btn-download:hover, .btn-download-small:hover, .btn-filter:hover, .btn-buy:hover {
  border-color: var(--color-primary);
  color: #fff;
  background-color: rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 1);
  box-shadow: 0px 0px 2px #333;
  opacity: 1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: rgba(
      var(--color-primary-r),
      var(--color-primary-g),
      var(--color-primary-b),
      0.9
  );
}
.btn-rent:active, .btn-login-google:active, .btn-login-facebook:active, .btn-cep:active, .btn-download:active, .btn-download-small:active, .btn-filter:active, .btn-buy:active {
  border-color: var(--color-primary);
  color: #fff !important;
}
.btn-rent .icon-reload, .btn-login-google .icon-reload, .btn-login-facebook .icon-reload, .btn-cep .icon-reload, .btn-download .icon-reload, .btn-download-small .icon-reload, .btn-filter .icon-reload, .btn-buy .icon-reload {
  display: none;
}
.btn-rent.js-loading-btn .icon-reload, .js-loading-btn.btn-login-google .icon-reload, .js-loading-btn.btn-login-facebook .icon-reload, .js-loading-btn.btn-cep .icon-reload, .js-loading-btn.btn-download .icon-reload, .js-loading-btn.btn-download-small .icon-reload, .js-loading-btn.btn-filter .icon-reload, .js-loading-btn.btn-buy .icon-reload {
  display: flex;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-rent.js-loading-btn .btn-label, .js-loading-btn.btn-login-google .btn-label, .js-loading-btn.btn-login-facebook .btn-label, .js-loading-btn.btn-cep .btn-label, .js-loading-btn.btn-download .btn-label, .js-loading-btn.btn-download-small .btn-label, .js-loading-btn.btn-filter .btn-label, .js-loading-btn.btn-buy .btn-label {
  display: none;
}
.btn-rent:not(:disabled):not(.disabled):active, .btn-login-google:not(:disabled):not(.disabled):active, .btn-login-facebook:not(:disabled):not(.disabled):active, .btn-cep:not(:disabled):not(.disabled):active, .btn-download:not(:disabled):not(.disabled):active, .btn-download-small:not(:disabled):not(.disabled):active, .btn-filter:not(:disabled):not(.disabled):active, .btn-buy:not(:disabled):not(.disabled):active {
  background-color: rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 0.98);
}
.btn-rent:focus, .btn-login-google:focus, .btn-login-facebook:focus, .btn-cep:focus, .btn-download:focus, .btn-download-small:focus, .btn-filter:focus, .btn-buy:focus {
  background-color: rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 0.95);
}
.btn-rent:disabled, .btn-login-google:disabled, .btn-login-facebook:disabled, .btn-cep:disabled, .btn-download:disabled, .btn-download-small:disabled, .btn-filter:disabled, .btn-buy:disabled {
  background-color: #ddd;
  border-color: #ccc;
  color: #777;
}
.btn-rent:disabled:hover, .btn-login-google:disabled:hover, .btn-login-facebook:disabled:hover, .btn-cep:disabled:hover, .btn-download:disabled:hover, .btn-download-small:disabled:hover, .btn-filter:disabled:hover, .btn-buy:disabled:hover {
  box-shadow: none;
}

.btn-show-filters {
  font-size: 0.9rem;
  border-radius: 2rem;
}
.btn-show-filters svg {
  width: 16px;
}

.btn-qtd {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 2rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  display: flex;
  margin-right: 1rem;
  padding: 1px;
  justify-content: space-between;
}
.btn-qtd input {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 3px;
  width: calc(100% - 6px);
  text-align: center;
  outline: 0;
}
.btn-qtd .btn {
  border-radius: 1rem;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.btn-qtd .btn:hover {
  background-color: #f4f4f4;
}

.btn-right-block {
  float: right;
}

.btn-download, .btn-download-small {
  font-size: 1rem;
  padding: 0.8rem 2.5rem;
  border-radius: 50px;
}

.btn-download-small {
  padding: 0.5rem 1.5rem;
}

.btn-cep {
  overflow: visible;
  min-width: 51px;
}

.btn-rent-whatsapp {
  background-color: #29b43f;
  border-color: #29b43f;
  color: #fff;
  margin-top: 0.5rem;
}
.btn-rent-whatsapp:hover, .btn-rent-whatsapp:active, .btn-rent-whatsapp:focus {
  background-color: #48974b !important;
  border-color: #48974b !important;
}
.btn-rent-whatsapp:not(:disabled):not(.disabled):active {
  background-color: #29b43f;
}
.btn-rent-whatsapp:disabled:hover {
  background-color: #ddd;
  border-color: #ccc;
  color: #777;
}

.btn-ripple {
  background-position: center;
  transition: background 0.5s;
}
.btn-ripple:hover {
  background: var(--success) radial-gradient(circle, transparent 1%, var(--success) 1%) center/15000%;
  border-color: green;
}
.btn-ripple:active {
  background-color: #2e8540;
  background-size: 100%;
  transition: background 0s;
}

.btn-ver-locais {
  font-size: 0.9rem;
  padding: 0;
  color: #0c6cb9;
  line-height: 1;
}

.btn-login-facebook {
  background-color: #4064ac;
  border-color: #385899;
  color: #fff !important;
  position: relative;
}
.btn-login-facebook svg {
  position: absolute;
  left: 30px;
}
.btn-login-facebook:hover, .btn-login-facebook:active, .btn-login-facebook:focus, .btn-login-facebook:not(:disabled):not(.disabled):active {
  background-color: #052b75;
  border-color: #385899;
}

.btn-login-google {
  background-color: #fff;
  border-color: #333 !important;
  position: relative;
}
.btn-login-google svg {
  position: absolute;
  left: 30px;
  height: 23px;
}
.btn-login-google:hover, .btn-login-google:active, .btn-login-google:focus, .btn-login-google:not(:disabled):not(.disabled):active {
  background-color: #eeeeee !important;
  border-color: #eeeeee;
  color: #333 !important;
}

/* WhatsApp */
.whatsapp-chat {
  position: relative;
  cursor: pointer;
}
.whatsapp-chat__text {
  display: none;
}
.whatsapp-chat__link {
  position: fixed;
  transform: scaleX(1);
  transition: transform 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  right: 15px;
  left: auto;
  bottom: 15px;
  color: #fff;
  width: auto;
  height: auto;
  z-index: 10001;
  border-radius: 50px;
  padding: 0.8rem 1rem;
  border: none;
  font-size: 14px;
  text-align: center;
  text-shadow: 1px 1px 3px #29b43f;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 1px 1px 3px #075e54;
  background-color: #29b43f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whatsapp-chat__link svg {
  width: 24px;
  height: 30px;
}
.whatsapp-chat__link:hover {
  text-decoration: none;
  background-color: #25d367;
  color: #fff;
  transition: all 0.5s;
}
.whatsapp-chat__link:hover .whatsapp-chat__text {
  display: flex;
  margin-left: 0.5rem;
}
.whatsapp-chat__link--right {
  right: 80px;
  left: auto;
}

.btn-cookies {
  padding: 0.5rem 1rem;
  background-color: #168330;
  border-color: #168330;
}

.back-to-top {
  position: relative;
}
.back-to-top .back-to-top--link {
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.719);
  z-index: 10001;
  color: #333;
  padding: 0.8rem;
  border-radius: 50px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px #333;
}
.back-to-top .back-to-top--link svg {
  transform: rotate(270deg);
}
.back-to-top .back-to-top--link:hover {
  text-decoration: none;
  background-color: #dddddd;
  border-color: #333;
}

.btn-outline-degrade {
  background-color: #fff;
  border-color: var(--roxo);
  color: #333;
  padding: 0.625rem 3rem;
  border: double 3px transparent;
  border-radius: 0.75rem;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(white, white), linear-gradient(to bottom, #f44eae, #a323db);
}
.btn-outline-degrade:hover {
  color: #444;
}
.btn-outline-degrade:hover .hidden-icon {
  display: none;
}

@media (max-width: 575.98px) {
  .btn-qtd .btn {
    padding: 5px;
  }

  .btn-rent-whatsapp {
    margin-top: 0;
  }
}
@media (max-width: 575.98px) {
  .whatsapp-chat__text {
    display: none;
  }
  .whatsapp-chat:hover .whatsapp-text {
    display: none;
  }
  .whatsapp-chat__link {
    border-radius: 50px;
    padding: 0.8rem 1rem;
    right: 15px;
  }
  .whatsapp-chat__link svg {
    margin-right: 0;
  }
  .whatsapp-chat__link--right {
    right: 15px;
    left: auto;
  }

  .btn-rent, .btn-buy, .btn-filter, .btn-download, .btn-download-small, .btn-cep, .btn-login-facebook, .btn-login-google {
    font-size: 0.9rem;
    padding: 0.469rem 0.75rem;
  }

  .btn-show {
    width: 100%;
    margin-top: 1rem;
  }

  .btn-right-block {
    float: none;
    width: 100%;
  }

  .btn-go-back {
    text-align: center;
    margin-top: 1rem;
  }

  .btn-cancel {
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }

  .btn-download, .btn-download-small {
    padding: 0.8rem 2.5rem;
  }

  .btn-download-small {
    padding: 0.75rem;
    display: block;
    width: 100%;
    margin: 1rem 0;
  }

  .btn-cookies {
    padding: 0.65rem 0.75rem;
  }

  /* Jivo chat */
  ._orientationLeft_fd90 .button_5fdd {
    margin-left: 15px;
    width: 56px;
    height: 56px;
  }
}
.product-block--3 .product .product__photo .product__photo--link {
  height: 250px;
}
.product-block--3 .product .product__buttons a {
  width: 70%;
  margin: 0 auto;
}
.product-block--6 .product .product__photo .product__photo--link {
  height: 150px;
}
.product-block--6 .product .product__name .product__name--link {
  font-size: 0.9rem;
}
.product-block--6 .product .product__price {
  font-size: 0.75rem;
}

.card-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100%;
  width: calc(99% - 3px);
}

.product {
  border: none;
  border-radius: 0;
  background-color: #ffffff;
  margin-top: 0.5rem;
  position: relative;
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;
  flex: 1;
}
.product:hover {
  box-shadow: 0px 2px 5px #7c44443b;
  -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.product:hover .product__buttons {
  display: flex;
  bottom: 15px;
}
.product:hover img {
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  backface-visibility: hidden;
  transform-origin: center center;
}
.product .product__photo {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.product .product__photo .product__photo--link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
}
.product .product__photo .product__photo--content {
  height: 100%;
  padding: 0.5rem 0.5rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product .product__photo .product__photo--content img {
  max-height: 100%;
  width: auto;
  -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.product .product__content {
  padding: 0 1rem 1rem;
  -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.product .product__content .produto-estoque {
  font-weight: bold;
  font-size: 0.85rem;
}
.product .product__content .produto-estoque .produto-em-estoque {
  color: #2b8454;
}
.product .product__content .produto-estoque .produto-fora-estoque {
  color: #757575;
}
.product .product__content .produto-estoque .produto-sob-consulta {
  color: #3379be;
}
.product .product__name {
  line-height: 1.2;
  margin-bottom: 1rem;
  overflow: hidden;
  height: 2.5rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product .product__name .product__name--link {
  color: #262636;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
}
.product .product__price {
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  min-height: 22px;
}
.product .product__price .product-pre,
.product .product__price .product-pos {
  font-weight: 100;
  font-size: 0.75rem;
}
.product .product__buttons {
  display: none;
  position: absolute;
  bottom: 30px;
  right: 15px;
  left: 15px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  padding-top: 1.5rem;
  -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.product__image-placeholder {
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 40px;
}
.product__image-placeholder svg {
  width: 30%;
  color: #aaa;
}
.product__image-placeholder.thumb-sm svg {
  width: 100%;
  padding: 1rem 0.5rem;
  color: #999;
  background-color: #f9f9f9;
}
.product__image-placeholder.thumb-md svg {
  width: 100%;
  padding: 1rem 1.5rem;
  color: #999;
  background-color: #f9f9f9;
}

.product__tag {
  color: #fff;
  background-color: #dc3545;
  border-radius: 3rem;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 3px 7px;
  font-weight: bold;
  font-size: 0.65rem;
  text-transform: uppercase;
  z-index: 1;
}

.price--original {
  font-size: 75%;
  text-decoration: line-through;
  color: #757575;
  margin-right: 0.5rem;
}

@media (max-width: 575.98px) {
  .product-block--3 .product .product__photo .product__photo--link, .product-block--6 .product .product__photo .product__photo--link {
    height: auto;
  }
  .product-block--3 .product .product__buttons a, .product-block--6 .product .product__buttons a {
    width: 100%;
    margin: 0 auto;
  }
  .product-block--6 .product .product__name .product__name--link {
    font-size: 0.9rem;
  }
  .product-block--6 .product .product__price {
    font-size: 0.75rem;
  }

  .product {
    flex-direction: row;
    margin: 0;
    border-bottom: 1px solid #f7f7f7;
  }
  .product .product__content {
    width: 63vw;
    padding: 1rem;
  }
  .product .product__content .produto-estoque {
    margin-bottom: 0.3rem;
  }
  .product .product__buttons {
    position: relative;
    display: flex;
    left: 0;
    top: 0;
    padding-top: 1rem;
  }
  .product .product__buttons .btn-rent, .product .product__buttons .btn-buy, .product .product__buttons .btn-filter, .product .product__buttons .btn-download, .product .product__buttons .btn-download-small, .product .product__buttons .btn-cep, .product .product__buttons .btn-login-facebook, .product .product__buttons .btn-login-google {
    font-size: 0.8rem;
    width: 100%;
  }
  .product .product__photo {
    min-height: auto;
    max-width: 25vw;
    width: 100px;
  }
  .product .product__photo .product__photo--link {
    height: auto;
    padding: 0;
  }
  .product .product__photo .product__image-placeholder {
    height: 60px;
  }
  .product .product__photo .product__image-placeholder svg {
    width: 70%;
  }
  .product .product__tag {
    top: 5px;
    right: 15px;
    font-size: 0.7rem;
  }
  .product .product__price {
    min-height: auto;
  }

  .product__tag {
    top: 0;
    right: 15px;
  }

  .card-product {
    width: 100%;
  }
}
.filters {
  background-color: #ffffff;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
}
.filters .filters-content {
  justify-content: space-between;
}
.filters .filter {
  border: none;
}
.filters .filter__title {
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  font-size: 0.85rem;
  color: #262636;
  border-radius: 0;
  text-decoration: none;
}
.filters .filter__title[aria-expanded=true] {
  color: #000;
}
.filters .filter__title:focus {
  box-shadow: none;
}
.filters .filter .card-header {
  background-color: #ffffff;
  padding: 0;
  border: none;
}
.filters .filter .form-group {
  margin-bottom: 0.5rem;
}
.filters .filter .form-check-label {
  font-size: 0.9rem;
}

.products__orderby select {
  border: none;
  background-color: transparent;
  color: #262636;
  font-size: 0.9rem;
  font-weight: 600;
}

.products__filter {
  text-align: right;
}
.products__filter a {
  color: #262636;
}

@media (max-width: 575.98px) {
  .products__filter {
    text-align: left;
  }
}
/* Credit Card Form */
.usable-creditcard-form,
.usable-creditcard-form * {
  font-size: 13px;
}

.usable-creditcard-form {
  position: relative;
  padding: 0px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.usable-creditcard-form .wrapper {
  border: 1px solid #ccc;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  height: 74px;
  width: 300px;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.usable-creditcard-form .input-group {
  position: absolute;
  top: 300px;
}

.usable-creditcard-form .input-group.nmb_a {
  position: absolute;
  width: 200px;
  top: 0px;
  left: 0px;
}

.usable-creditcard-form .input-group.nmb_b {
  position: absolute;
  width: 100px;
  top: 0px;
  right: 0px;
}

.usable-creditcard-form .input-group.nmb_b input,
.usable-creditcard-form .input-group.nmb_d input {
  text-align: center;
}

.usable-creditcard-form .input-group.nmb_c {
  position: absolute;
  width: 200px;
  top: 37px;
  left: 0px;
}

.usable-creditcard-form .input-group.nmb_d {
  position: absolute;
  width: 100px;
  top: 37px;
  right: 0px;
}

.usable-creditcard-form input {
  background: none;
  display: block;
  width: 100%;
  padding: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px;
  padding-left: 35px;
  border: none;
}

.usable-creditcard-form .input-group .icon {
  position: absolute;
  width: 22px;
  height: 22px;
  background: #ccc;
  left: 8px;
  top: 7px;
}

.usable-creditcard-form .input-group.nmb_a input {
  border-right: 1px solid #ececec;
}

.usable-creditcard-form .input-group.nmb_c input {
  border-top: 1px solid #ececec;
  border-right: 1px solid #ececec;
}

.usable-creditcard-form input::-webkit-input-placeholder {
  font-size: 12px;
  text-transform: none;
}

.usable-creditcard-form .input-group.nmb_d input {
  border-top: 1px solid #ececec;
}

.usable-creditcard-form .input-group.nmb_c input {
  text-transform: uppercase;
}

.usable-creditcard-form .accept {
  color: #999;
  font-size: 11px;
  margin-bottom: 5px;
}

.usable-creditcard-form .footer {
  margin-top: 3px;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
}

.usable-creditcard-form .footer img {
  padding: 0px;
  margin: 0px;
}

.usable-creditcard-form .iugu-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.usable-creditcard-form .input-group .icon.ccic-name {
  background: url("../../../../../img/templates/1/iugu/ccic-name.png") no-repeat;
}

.usable-creditcard-form .input-group .icon.ccic-exp {
  background: url("../../../../../img/templates/1/iugu/ccic-exp.png") no-repeat;
}

.usable-creditcard-form .input-group .icon.ccic-brand {
  background: url("../../../../../img/templates/1/iugu/ccic-brands.png") no-repeat;
}

.usable-creditcard-form .input-group .icon.ccic-cvv {
  background: url("../../../../../img/templates/1/iugu/ccic-cvv.png") no-repeat;
}

.usable-creditcard-form .input-group .icon.ccic-cvv,
.usable-creditcard-form .input-group .icon.ccic-brand {
  -webkit-transition: background-position 0.2s ease-in;
  -moz-transition: background-position 0.2s ease-in;
  -o-transition: background-position 0.2s ease-in;
  transition: background-position 0.2s ease-in;
}

.amex .usable-creditcard-form .input-group .icon.ccic-cvv {
  background-position: 0px -22px;
}

.amex .usable-creditcard-form .input-group .icon.ccic-brand {
  background-position: 0px -110px;
}

.visa .usable-creditcard-form .input-group .icon.ccic-brand {
  background-position: 0px -22px;
}

.diners .usable-creditcard-form .input-group .icon.ccic-brand {
  background-position: 0px -88px;
}

.mastercard .usable-creditcard-form .input-group .icon.ccic-brand {
  background-position: 0px -66px;
}

.faq__page {
  background-color: #ffffff;
  padding-bottom: 4rem;
}
.faq__page a {
  color: #262636;
}
.faq__page a:hover {
  color: #666666;
}
.faq__page .faq-link-todas-perguntas {
  color: #666666;
}
.faq__page .faq-link-todas-perguntas a {
  color: #666666;
}

.faq-block {
  margin-bottom: 3rem;
  background-color: #ffffff;
  /* Icone Accordion - Faq */
}
.faq-block .faq {
  margin-bottom: 3rem;
}
.faq-block .faq-title {
  font-weight: bold;
  font-size: 1.4rem;
  color: #666666;
}
.faq-block .card {
  background-color: #ffffff;
  border: none;
}
.faq-block .card .card-header {
  color: #333;
  border: none;
  background-color: #ffffff;
  padding: 0;
}
.faq-block .card .card-header button {
  color: #666666;
  font-weight: 400;
  font-size: 1.25rem;
  padding: 0.75rem 0;
  text-decoration: none;
  text-align: left;
}
.faq-block .card .card-header button:focus {
  box-shadow: none;
  text-decoration: none;
}
.faq-block .card .card-header button:not(.collapsed) {
  font-weight: bold;
  color: #555555;
}
.faq-block .card .card-header button:not(.collapsed) .accordion-icon {
  background-color: var(--color-primary);
  color: #ffffff;
  border-color: var(--color-primary);
}
.faq-block .card .card-header button:not(.collapsed) .accordion-icon path {
  transform: rotate(315deg);
  transform-origin: center;
  transition: all 0.25s ease;
}
.faq-block .accordion-icon {
  display: inline-block;
  line-height: 1;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  margin-right: 5px;
}
.faq-block .accordion-icon svg {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
}

@media (max-width: 575.98px) {
  .faq-block .card .card-header button,
.faq-block .card .card-footer {
    font-size: 1rem;
    text-align: left;
  }
}
/*******************************
             Flag
*******************************/
i.flag:not(.icon) {
  display: inline-block;
  width: 16px;
  height: 11px;
  line-height: 11px;
  vertical-align: baseline;
  margin: 0em 0.5em 0em 0em;
  text-decoration: inherit;
  speak: none;
  font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Sprite */
i.flag:not(.icon):before {
  display: inline-block;
  content: "";
  background: url("../../../../../img/flags/flags.png") no-repeat -108px -1976px;
  width: 16px;
  height: 11px;
}

/*******************************
         Theme Overrides
*******************************/
i.flag.ad:before,
i.flag.andorra:before {
  background-position: 0px 0px;
}

i.flag.ae:before,
i.flag.united.arab.emirates:before,
i.flag.uae:before {
  background-position: 0px -26px;
}

i.flag.af:before,
i.flag.afghanistan:before {
  background-position: 0px -52px;
}

i.flag.ag:before,
i.flag.antigua:before {
  background-position: 0px -78px;
}

i.flag.ai:before,
i.flag.anguilla:before {
  background-position: 0px -104px;
}

i.flag.al:before,
i.flag.albania:before {
  background-position: 0px -130px;
}

i.flag.am:before,
i.flag.armenia:before {
  background-position: 0px -156px;
}

i.flag.an:before,
i.flag.netherlands.antilles:before {
  background-position: 0px -182px;
}

i.flag.ao:before,
i.flag.angola:before {
  background-position: 0px -208px;
}

i.flag.ar:before,
i.flag.argentina:before {
  background-position: 0px -234px;
}

i.flag.as:before,
i.flag.american.samoa:before {
  background-position: 0px -260px;
}

i.flag.at:before,
i.flag.austria:before {
  background-position: 0px -286px;
}

i.flag.au:before,
i.flag.australia:before {
  background-position: 0px -312px;
}

i.flag.aw:before,
i.flag.aruba:before {
  background-position: 0px -338px;
}

i.flag.ax:before,
i.flag.aland.islands:before {
  background-position: 0px -364px;
}

i.flag.az:before,
i.flag.azerbaijan:before {
  background-position: 0px -390px;
}

i.flag.ba:before,
i.flag.bosnia:before {
  background-position: 0px -416px;
}

i.flag.bb:before,
i.flag.barbados:before {
  background-position: 0px -442px;
}

i.flag.bd:before,
i.flag.bangladesh:before {
  background-position: 0px -468px;
}

i.flag.be:before,
i.flag.belgium:before {
  background-position: 0px -494px;
}

i.flag.bf:before,
i.flag.burkina.faso:before {
  background-position: 0px -520px;
}

i.flag.bg:before,
i.flag.bulgaria:before {
  background-position: 0px -546px;
}

i.flag.bh:before,
i.flag.bahrain:before {
  background-position: 0px -572px;
}

i.flag.bi:before,
i.flag.burundi:before {
  background-position: 0px -598px;
}

i.flag.bj:before,
i.flag.benin:before {
  background-position: 0px -624px;
}

i.flag.bm:before,
i.flag.bermuda:before {
  background-position: 0px -650px;
}

i.flag.bn:before,
i.flag.brunei:before {
  background-position: 0px -676px;
}

i.flag.bo:before,
i.flag.bolivia:before {
  background-position: 0px -702px;
}

i.flag.br:before,
i.flag.brazil:before {
  background-position: 0px -728px;
}

i.flag.bs:before,
i.flag.bahamas:before {
  background-position: 0px -754px;
}

i.flag.bt:before,
i.flag.bhutan:before {
  background-position: 0px -780px;
}

i.flag.bv:before,
i.flag.bouvet.island:before {
  background-position: 0px -806px;
}

i.flag.bw:before,
i.flag.botswana:before {
  background-position: 0px -832px;
}

i.flag.by:before,
i.flag.belarus:before {
  background-position: 0px -858px;
}

i.flag.bz:before,
i.flag.belize:before {
  background-position: 0px -884px;
}

i.flag.ca:before,
i.flag.canada:before {
  background-position: 0px -910px;
}

i.flag.cc:before,
i.flag.cocos.islands:before {
  background-position: 0px -962px;
}

i.flag.cd:before,
i.flag.congo:before {
  background-position: 0px -988px;
}

i.flag.cf:before,
i.flag.central.african.republic:before {
  background-position: 0px -1014px;
}

i.flag.cg:before,
i.flag.congo.brazzaville:before {
  background-position: 0px -1040px;
}

i.flag.ch:before,
i.flag.switzerland:before {
  background-position: 0px -1066px;
}

i.flag.ci:before,
i.flag.cote.divoire:before {
  background-position: 0px -1092px;
}

i.flag.ck:before,
i.flag.cook.islands:before {
  background-position: 0px -1118px;
}

i.flag.cl:before,
i.flag.chile:before {
  background-position: 0px -1144px;
}

i.flag.cm:before,
i.flag.cameroon:before {
  background-position: 0px -1170px;
}

i.flag.cn:before,
i.flag.china:before {
  background-position: 0px -1196px;
}

i.flag.co:before,
i.flag.colombia:before {
  background-position: 0px -1222px;
}

i.flag.cr:before,
i.flag.costa.rica:before {
  background-position: 0px -1248px;
}

i.flag.cs:before,
i.flag.serbia:before {
  background-position: 0px -1274px;
}

i.flag.cu:before,
i.flag.cuba:before {
  background-position: 0px -1300px;
}

i.flag.cv:before,
i.flag.cape.verde:before {
  background-position: 0px -1326px;
}

i.flag.cx:before,
i.flag.christmas.island:before {
  background-position: 0px -1352px;
}

i.flag.cy:before,
i.flag.cyprus:before {
  background-position: 0px -1378px;
}

i.flag.cz:before,
i.flag.czech.republic:before {
  background-position: 0px -1404px;
}

i.flag.de:before,
i.flag.germany:before {
  background-position: 0px -1430px;
}

i.flag.dj:before,
i.flag.djibouti:before {
  background-position: 0px -1456px;
}

i.flag.dk:before,
i.flag.denmark:before {
  background-position: 0px -1482px;
}

i.flag.dm:before,
i.flag.dominica:before {
  background-position: 0px -1508px;
}

i.flag.do:before,
i.flag.dominican.republic:before {
  background-position: 0px -1534px;
}

i.flag.dz:before,
i.flag.algeria:before {
  background-position: 0px -1560px;
}

i.flag.ec:before,
i.flag.ecuador:before {
  background-position: 0px -1586px;
}

i.flag.ee:before,
i.flag.estonia:before {
  background-position: 0px -1612px;
}

i.flag.eg:before,
i.flag.egypt:before {
  background-position: 0px -1638px;
}

i.flag.eh:before,
i.flag.western.sahara:before {
  background-position: 0px -1664px;
}

i.flag.gb.eng:before,
i.flag.england:before {
  background-position: 0px -1690px;
}

i.flag.er:before,
i.flag.eritrea:before {
  background-position: 0px -1716px;
}

i.flag.es:before,
i.flag.spain:before {
  background-position: 0px -1742px;
}

i.flag.et:before,
i.flag.ethiopia:before {
  background-position: 0px -1768px;
}

i.flag.eu:before,
i.flag.european.union:before {
  background-position: 0px -1794px;
}

i.flag.fi:before,
i.flag.finland:before {
  background-position: 0px -1846px;
}

i.flag.fj:before,
i.flag.fiji:before {
  background-position: 0px -1872px;
}

i.flag.fk:before,
i.flag.falkland.islands:before {
  background-position: 0px -1898px;
}

i.flag.fm:before,
i.flag.micronesia:before {
  background-position: 0px -1924px;
}

i.flag.fo:before,
i.flag.faroe.islands:before {
  background-position: 0px -1950px;
}

i.flag.fr:before,
i.flag.france:before {
  background-position: 0px -1976px;
}

i.flag.ga:before,
i.flag.gabon:before {
  background-position: -36px 0px;
}

i.flag.gb:before,
i.flag.uk:before,
i.flag.united.kingdom:before {
  background-position: -36px -26px;
}

i.flag.gd:before,
i.flag.grenada:before {
  background-position: -36px -52px;
}

i.flag.ge:before,
i.flag.georgia:before {
  background-position: -36px -78px;
}

i.flag.gf:before,
i.flag.french.guiana:before {
  background-position: -36px -104px;
}

i.flag.gh:before,
i.flag.ghana:before {
  background-position: -36px -130px;
}

i.flag.gi:before,
i.flag.gibraltar:before {
  background-position: -36px -156px;
}

i.flag.gl:before,
i.flag.greenland:before {
  background-position: -36px -182px;
}

i.flag.gm:before,
i.flag.gambia:before {
  background-position: -36px -208px;
}

i.flag.gn:before,
i.flag.guinea:before {
  background-position: -36px -234px;
}

i.flag.gp:before,
i.flag.guadeloupe:before {
  background-position: -36px -260px;
}

i.flag.gq:before,
i.flag.equatorial.guinea:before {
  background-position: -36px -286px;
}

i.flag.gr:before,
i.flag.greece:before {
  background-position: -36px -312px;
}

i.flag.gs:before,
i.flag.sandwich.islands:before {
  background-position: -36px -338px;
}

i.flag.gt:before,
i.flag.guatemala:before {
  background-position: -36px -364px;
}

i.flag.gu:before,
i.flag.guam:before {
  background-position: -36px -390px;
}

i.flag.gw:before,
i.flag.guinea-bissau:before {
  background-position: -36px -416px;
}

i.flag.gy:before,
i.flag.guyana:before {
  background-position: -36px -442px;
}

i.flag.hk:before,
i.flag.hong.kong:before {
  background-position: -36px -468px;
}

i.flag.hm:before,
i.flag.heard.island:before {
  background-position: -36px -494px;
}

i.flag.hn:before,
i.flag.honduras:before {
  background-position: -36px -520px;
}

i.flag.hr:before,
i.flag.croatia:before {
  background-position: -36px -546px;
}

i.flag.ht:before,
i.flag.haiti:before {
  background-position: -36px -572px;
}

i.flag.hu:before,
i.flag.hungary:before {
  background-position: -36px -598px;
}

i.flag.id:before,
i.flag.indonesia:before {
  background-position: -36px -624px;
}

i.flag.ie:before,
i.flag.ireland:before {
  background-position: -36px -650px;
}

i.flag.il:before,
i.flag.israel:before {
  background-position: -36px -676px;
}

i.flag.in:before,
i.flag.india:before {
  background-position: -36px -702px;
}

i.flag.io:before,
i.flag.indian.ocean.territory:before {
  background-position: -36px -728px;
}

i.flag.iq:before,
i.flag.iraq:before {
  background-position: -36px -754px;
}

i.flag.ir:before,
i.flag.iran:before {
  background-position: -36px -780px;
}

i.flag.is:before,
i.flag.iceland:before {
  background-position: -36px -806px;
}

i.flag.it:before,
i.flag.italy:before {
  background-position: -36px -832px;
}

i.flag.jm:before,
i.flag.jamaica:before {
  background-position: -36px -858px;
}

i.flag.jo:before,
i.flag.jordan:before {
  background-position: -36px -884px;
}

i.flag.jp:before,
i.flag.japan:before {
  background-position: -36px -910px;
}

i.flag.ke:before,
i.flag.kenya:before {
  background-position: -36px -936px;
}

i.flag.kg:before,
i.flag.kyrgyzstan:before {
  background-position: -36px -962px;
}

i.flag.kh:before,
i.flag.cambodia:before {
  background-position: -36px -988px;
}

i.flag.ki:before,
i.flag.kiribati:before {
  background-position: -36px -1014px;
}

i.flag.km:before,
i.flag.comoros:before {
  background-position: -36px -1040px;
}

i.flag.kn:before,
i.flag.saint.kitts.and.nevis:before {
  background-position: -36px -1066px;
}

i.flag.kp:before,
i.flag.north.korea:before {
  background-position: -36px -1092px;
}

i.flag.kr:before,
i.flag.south.korea:before {
  background-position: -36px -1118px;
}

i.flag.kw:before,
i.flag.kuwait:before {
  background-position: -36px -1144px;
}

i.flag.ky:before,
i.flag.cayman.islands:before {
  background-position: -36px -1170px;
}

i.flag.kz:before,
i.flag.kazakhstan:before {
  background-position: -36px -1196px;
}

i.flag.la:before,
i.flag.laos:before {
  background-position: -36px -1222px;
}

i.flag.lb:before,
i.flag.lebanon:before {
  background-position: -36px -1248px;
}

i.flag.lc:before,
i.flag.saint.lucia:before {
  background-position: -36px -1274px;
}

i.flag.li:before,
i.flag.liechtenstein:before {
  background-position: -36px -1300px;
}

i.flag.lk:before,
i.flag.sri.lanka:before {
  background-position: -36px -1326px;
}

i.flag.lr:before,
i.flag.liberia:before {
  background-position: -36px -1352px;
}

i.flag.ls:before,
i.flag.lesotho:before {
  background-position: -36px -1378px;
}

i.flag.lt:before,
i.flag.lithuania:before {
  background-position: -36px -1404px;
}

i.flag.lu:before,
i.flag.luxembourg:before {
  background-position: -36px -1430px;
}

i.flag.lv:before,
i.flag.latvia:before {
  background-position: -36px -1456px;
}

i.flag.ly:before,
i.flag.libya:before {
  background-position: -36px -1482px;
}

i.flag.ma:before,
i.flag.morocco:before {
  background-position: -36px -1508px;
}

i.flag.mc:before,
i.flag.monaco:before {
  background-position: -36px -1534px;
}

i.flag.md:before,
i.flag.moldova:before {
  background-position: -36px -1560px;
}

i.flag.me:before,
i.flag.montenegro:before {
  background-position: -36px -1586px;
}

i.flag.mg:before,
i.flag.madagascar:before {
  background-position: -36px -1613px;
}

i.flag.mh:before,
i.flag.marshall.islands:before {
  background-position: -36px -1639px;
}

i.flag.mk:before,
i.flag.macedonia:before {
  background-position: -36px -1665px;
}

i.flag.ml:before,
i.flag.mali:before {
  background-position: -36px -1691px;
}

i.flag.mm:before,
i.flag.myanmar:before,
i.flag.burma:before {
  background-position: -73px -1821px;
}

i.flag.mn:before,
i.flag.mongolia:before {
  background-position: -36px -1743px;
}

i.flag.mo:before,
i.flag.macau:before {
  background-position: -36px -1769px;
}

i.flag.mp:before,
i.flag.northern.mariana.islands:before {
  background-position: -36px -1795px;
}

i.flag.mq:before,
i.flag.martinique:before {
  background-position: -36px -1821px;
}

i.flag.mr:before,
i.flag.mauritania:before {
  background-position: -36px -1847px;
}

i.flag.ms:before,
i.flag.montserrat:before {
  background-position: -36px -1873px;
}

i.flag.mt:before,
i.flag.malta:before {
  background-position: -36px -1899px;
}

i.flag.mu:before,
i.flag.mauritius:before {
  background-position: -36px -1925px;
}

i.flag.mv:before,
i.flag.maldives:before {
  background-position: -36px -1951px;
}

i.flag.mw:before,
i.flag.malawi:before {
  background-position: -36px -1977px;
}

i.flag.mx:before,
i.flag.mexico:before {
  background-position: -72px 0px;
}

i.flag.my:before,
i.flag.malaysia:before {
  background-position: -72px -26px;
}

i.flag.mz:before,
i.flag.mozambique:before {
  background-position: -72px -52px;
}

i.flag.na:before,
i.flag.namibia:before {
  background-position: -72px -78px;
}

i.flag.nc:before,
i.flag.new.caledonia:before {
  background-position: -72px -104px;
}

i.flag.ne:before,
i.flag.niger:before {
  background-position: -72px -130px;
}

i.flag.nf:before,
i.flag.norfolk.island:before {
  background-position: -72px -156px;
}

i.flag.ng:before,
i.flag.nigeria:before {
  background-position: -72px -182px;
}

i.flag.ni:before,
i.flag.nicaragua:before {
  background-position: -72px -208px;
}

i.flag.nl:before,
i.flag.netherlands:before {
  background-position: -72px -234px;
}

i.flag.no:before,
i.flag.norway:before {
  background-position: -72px -260px;
}

i.flag.np:before,
i.flag.nepal:before {
  background-position: -72px -286px;
}

i.flag.nr:before,
i.flag.nauru:before {
  background-position: -72px -312px;
}

i.flag.nu:before,
i.flag.niue:before {
  background-position: -72px -338px;
}

i.flag.nz:before,
i.flag.new.zealand:before {
  background-position: -72px -364px;
}

i.flag.om:before,
i.flag.oman:before {
  background-position: -72px -390px;
}

i.flag.pa:before,
i.flag.panama:before {
  background-position: -72px -416px;
}

i.flag.pe:before,
i.flag.peru:before {
  background-position: -72px -442px;
}

i.flag.pf:before,
i.flag.french.polynesia:before {
  background-position: -72px -468px;
}

i.flag.pg:before,
i.flag.new.guinea:before {
  background-position: -72px -494px;
}

i.flag.ph:before,
i.flag.philippines:before {
  background-position: -72px -520px;
}

i.flag.pk:before,
i.flag.pakistan:before {
  background-position: -72px -546px;
}

i.flag.pl:before,
i.flag.poland:before {
  background-position: -72px -572px;
}

i.flag.pm:before,
i.flag.saint.pierre:before {
  background-position: -72px -598px;
}

i.flag.pn:before,
i.flag.pitcairn.islands:before {
  background-position: -72px -624px;
}

i.flag.pr:before,
i.flag.puerto.rico:before {
  background-position: -72px -650px;
}

i.flag.ps:before,
i.flag.palestine:before {
  background-position: -72px -676px;
}

i.flag.pt:before,
i.flag.portugal:before {
  background-position: -72px -702px;
}

i.flag.pw:before,
i.flag.palau:before {
  background-position: -72px -728px;
}

i.flag.py:before,
i.flag.paraguay:before {
  background-position: -72px -754px;
}

i.flag.qa:before,
i.flag.qatar:before {
  background-position: -72px -780px;
}

i.flag.re:before,
i.flag.reunion:before {
  background-position: -72px -806px;
}

i.flag.ro:before,
i.flag.romania:before {
  background-position: -72px -832px;
}

i.flag.rs:before,
i.flag.serbia:before {
  background-position: -72px -858px;
}

i.flag.ru:before,
i.flag.russia:before {
  background-position: -72px -884px;
}

i.flag.rw:before,
i.flag.rwanda:before {
  background-position: -72px -910px;
}

i.flag.sa:before,
i.flag.saudi.arabia:before {
  background-position: -72px -936px;
}

i.flag.sb:before,
i.flag.solomon.islands:before {
  background-position: -72px -962px;
}

i.flag.sc:before,
i.flag.seychelles:before {
  background-position: -72px -988px;
}

i.flag.gb.sct:before,
i.flag.scotland:before {
  background-position: -72px -1014px;
}

i.flag.sd:before,
i.flag.sudan:before {
  background-position: -72px -1040px;
}

i.flag.se:before,
i.flag.sweden:before {
  background-position: -72px -1066px;
}

i.flag.sg:before,
i.flag.singapore:before {
  background-position: -72px -1092px;
}

i.flag.sh:before,
i.flag.saint.helena:before {
  background-position: -72px -1118px;
}

i.flag.si:before,
i.flag.slovenia:before {
  background-position: -72px -1144px;
}

i.flag.sj:before,
i.flag.svalbard:before,
i.flag.jan.mayen:before {
  background-position: -72px -1170px;
}

i.flag.sk:before,
i.flag.slovakia:before {
  background-position: -72px -1196px;
}

i.flag.sl:before,
i.flag.sierra.leone:before {
  background-position: -72px -1222px;
}

i.flag.sm:before,
i.flag.san.marino:before {
  background-position: -72px -1248px;
}

i.flag.sn:before,
i.flag.senegal:before {
  background-position: -72px -1274px;
}

i.flag.so:before,
i.flag.somalia:before {
  background-position: -72px -1300px;
}

i.flag.sr:before,
i.flag.suriname:before {
  background-position: -72px -1326px;
}

i.flag.st:before,
i.flag.sao.tome:before {
  background-position: -72px -1352px;
}

i.flag.sv:before,
i.flag.el.salvador:before {
  background-position: -72px -1378px;
}

i.flag.sy:before,
i.flag.syria:before {
  background-position: -72px -1404px;
}

i.flag.sz:before,
i.flag.swaziland:before {
  background-position: -72px -1430px;
}

i.flag.tc:before,
i.flag.caicos.islands:before {
  background-position: -72px -1456px;
}

i.flag.td:before,
i.flag.chad:before {
  background-position: -72px -1482px;
}

i.flag.tf:before,
i.flag.french.territories:before {
  background-position: -72px -1508px;
}

i.flag.tg:before,
i.flag.togo:before {
  background-position: -72px -1534px;
}

i.flag.th:before,
i.flag.thailand:before {
  background-position: -72px -1560px;
}

i.flag.tj:before,
i.flag.tajikistan:before {
  background-position: -72px -1586px;
}

i.flag.tk:before,
i.flag.tokelau:before {
  background-position: -72px -1612px;
}

i.flag.tl:before,
i.flag.timorleste:before {
  background-position: -72px -1638px;
}

i.flag.tm:before,
i.flag.turkmenistan:before {
  background-position: -72px -1664px;
}

i.flag.tn:before,
i.flag.tunisia:before {
  background-position: -72px -1690px;
}

i.flag.to:before,
i.flag.tonga:before {
  background-position: -72px -1716px;
}

i.flag.tr:before,
i.flag.turkey:before {
  background-position: -72px -1742px;
}

i.flag.tt:before,
i.flag.trinidad:before {
  background-position: -72px -1768px;
}

i.flag.tv:before,
i.flag.tuvalu:before {
  background-position: -72px -1794px;
}

i.flag.tw:before,
i.flag.taiwan:before {
  background-position: -72px -1820px;
}

i.flag.tz:before,
i.flag.tanzania:before {
  background-position: -72px -1846px;
}

i.flag.ua:before,
i.flag.ukraine:before {
  background-position: -72px -1872px;
}

i.flag.ug:before,
i.flag.uganda:before {
  background-position: -72px -1898px;
}

i.flag.um:before,
i.flag.us.minor.islands:before {
  background-position: -72px -1924px;
}

i.flag.us:before,
i.flag.america:before,
i.flag.united.states:before {
  background-position: -72px -1950px;
}

i.flag.uy:before,
i.flag.uruguay:before {
  background-position: -72px -1976px;
}

i.flag.uz:before,
i.flag.uzbekistan:before {
  background-position: -108px 0px;
}

i.flag.va:before,
i.flag.vatican.city:before {
  background-position: -108px -26px;
}

i.flag.vc:before,
i.flag.saint.vincent:before {
  background-position: -108px -52px;
}

i.flag.ve:before,
i.flag.venezuela:before {
  background-position: -108px -78px;
}

i.flag.vg:before,
i.flag.british.virgin.islands:before {
  background-position: -108px -104px;
}

i.flag.vi:before,
i.flag.us.virgin.islands:before {
  background-position: -108px -130px;
}

i.flag.vn:before,
i.flag.vietnam:before {
  background-position: -108px -156px;
}

i.flag.vu:before,
i.flag.vanuatu:before {
  background-position: -108px -182px;
}

i.flag.gb.wls:before,
i.flag.wales:before {
  background-position: -108px -208px;
}

i.flag.wf:before,
i.flag.wallis.and.futuna:before {
  background-position: -108px -234px;
}

i.flag.ws:before,
i.flag.samoa:before {
  background-position: -108px -260px;
}

i.flag.ye:before,
i.flag.yemen:before {
  background-position: -108px -286px;
}

i.flag.yt:before,
i.flag.mayotte:before {
  background-position: -108px -312px;
}

i.flag.za:before,
i.flag.south.africa:before {
  background-position: -108px -338px;
}

i.flag.zm:before,
i.flag.zambia:before {
  background-position: -108px -364px;
}

i.flag.zw:before,
i.flag.zimbabwe:before {
  background-position: -108px -390px;
}

.product-details {
  background-color: #fff;
}
.product-details hr {
  margin: 2rem 0;
}
.product-details.product-info {
  padding: 2rem 0;
}
.product-details .product-details__more-info {
  padding-bottom: 2rem;
}
.product-details .product-details__title {
  font-size: 2rem;
  font-weight: 500;
}
.product-details .product-gallery-block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  text-align: center;
}
.product-details .product-gallery-block .product__image-placeholder {
  background-color: #fbfbfb;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-details .product-gallery-block .product__image-placeholder svg {
  color: #bbb;
  width: 150px;
}
.product-details .product-details__gallery {
  width: 100%;
  align-self: flex-start;
}
.product-details .product-details__gallery .product-gallery {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.product-details .product-details__gallery .product-gallery:hover .carousel-control-next,
.product-details .product-details__gallery .product-gallery:hover .carousel-control-prev {
  display: flex;
}
.product-details .product-details__gallery .product-gallery .carousel-inner {
  height: 50vh;
  display: flex;
  align-items: center;
  border: 1px solid #f7f9fa;
  text-align: center;
  width: 100%;
}
.product-details .product-details__gallery .product-gallery .carousel-item {
  padding: 0 3rem;
}
.product-details .product-details__gallery .product-gallery .carousel-indicators {
  position: relative;
}
.product-details .product-details__gallery .product-gallery .carousel-indicators li {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.product-details .product-details__gallery .product-gallery .carousel-control-next,
.product-details .product-details__gallery .product-gallery .carousel-control-prev {
  color: #333;
  border-radius: 50px;
  width: 50px;
  height: 100%;
  top: 0;
  display: none;
}
.product-details .product-details__gallery .product-gallery .carousel-control-prev-icon,
.product-details .product-details__gallery .product-gallery .carousel-control-next-icon {
  width: 26px;
  height: 26px;
  background-image: none;
}
.product-details .img-zoom {
  overflow: hidden;
  width: 100%;
}
.product-details .img-zoom img {
  max-width: 100%;
  max-height: 50vh;
}
.product-details .product-details__price {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.product-details .product-details__price .product-pre,
.product-details .product-details__price .product-pos {
  font-weight: 100;
  font-size: 80%;
}
.product-details .produto-estoque {
  font-weight: bold;
  font-size: 0.85rem;
}
.product-details .produto-estoque .produto-em-estoque {
  color: #2b8454;
}
.product-details .produto-estoque .produto-fora-estoque {
  color: #757575;
}
.product-details .produto-estoque .produto-sob-consulta {
  color: #3379be;
}
.product-details .product-details__buttons {
  margin: 1rem 0;
}
.product-details .product-details__buttons .select-date, .product-details .product-details__buttons .select-time {
  margin-bottom: 0;
}
.product-details .product-details__buttons .select-date .form-control[readonly], .product-details .product-details__buttons .select-time .form-control[readonly], .product-details .product-details__buttons .select-date [readonly].btn-qtd, .product-details .product-details__buttons .select-time [readonly].btn-qtd {
  background-color: #ffffff;
}
.product-details .product-details__buttons .select-date .form-control[readonly]:hover, .product-details .product-details__buttons .select-time .form-control[readonly]:hover, .product-details .product-details__buttons .select-date [readonly].btn-qtd:hover, .product-details .product-details__buttons .select-time [readonly].btn-qtd:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}
.product-details .product-details__label {
  font-weight: 100;
  color: #717171;
  font-size: 0.9rem;
}
.product-details .product__code,
.product-details .product__categories,
.product-details .product__brand {
  font-size: 0.9rem;
}
.product-details .product__code a,
.product-details .product__categories a,
.product-details .product__brand a {
  color: #262636;
  margin-right: 0.5rem;
  font-weight: bold;
}
.product-details .product__code a:hover,
.product-details .product__categories a:hover,
.product-details .product__brand a:hover {
  text-decoration: none;
  opacity: 0.8;
}
.product-details .product__code .brand__thumb,
.product-details .product__categories .brand__thumb,
.product-details .product__brand .brand__thumb {
  max-width: 100px;
}
.product-details .product__lojista-link {
  color: #262636;
  font-weight: bold;
}
.product-details .product-details__extra-info .attribute {
  margin: 0.5rem 0;
}
.product-details .product-details__extra-info .attribute .attribute__key {
  text-transform: uppercase;
}
.product-details .product-details__extra-info .attribute .attribute__value {
  color: #757575;
}
.product-details .product-details__tag {
  color: #fff;
  background-color: #dc3545;
  border-radius: 3rem;
  padding: 3px 7px;
  font-weight: bold;
  font-size: 0.7rem;
  width: auto;
  max-width: 60px;
  display: flex;
  animation: c;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.product-details .product__faixas {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;
}
.product-details .product__faixas select {
  min-width: 240px;
  padding: 0.375rem 3rem 0.375rem 0.75rem;
  height: 38px;
}
.product-details .product__faixas.faixas__dropdown {
  flex-direction: row;
  align-items: center;
}
.product-details .product__faixas .faixas__mini-bloco {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  position: relative;
}
.product-details .product__faixas .faixas__mini-bloco .mini-bloco {
  position: relative;
  background-color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-right: 1rem;
  margin-bottom: 0.3rem;
  border: 1px solid #ccc;
  border-radius: 50px;
  cursor: pointer;
  min-width: 100px;
}
.product-details .product__faixas .faixas__mini-bloco .mini-bloco:hover {
  opacity: 0.8;
}
.product-details .product__faixas .faixas__mini-bloco .mini-bloco.is-invalid {
  border-color: #dc3545;
}
.product-details .product__faixas .faixas__mini-bloco .mini-bloco input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.product-details .product__faixas .faixas__mini-bloco .mini-bloco label {
  padding: 0.5rem 0.8rem;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.product-details .product__faixas .faixas__mini-bloco .mini-bloco .tag-desconto {
  font-size: 0.65rem;
  background-color: var(--color-primary);
  color: #ffffff;
  position: absolute;
  border-radius: 50px;
  top: -16px;
  right: -12px;
  padding: 7px 3px;
  min-width: 29px;
}
.product-details .product__faixas .faixas__mini-bloco .mini-bloco.option-selected {
  background-color: var(--color-primary);
  color: #ffffff;
}
.product-details .product__accessory {
  padding-bottom: 0.75rem;
  max-width: 80%;
}
.product-details .product__accessory .custom-switch {
  margin-bottom: 0.5rem;
}
.product-details .product__accessory .custom-control-label {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
  padding-top: 0.25rem;
}
.product-details .product__accessory .custom-control-label .with-badge {
  display: flex;
  flex-direction: row;
}
.product-details .product__accessory .custom-control-label .name {
  padding-left: 1.5rem;
}
.product-details .product__accessory .custom-control-label .price {
  min-width: 100px;
  margin-left: 0.5rem;
  font-weight: bold;
  text-align: right;
}
.product-details .product__accessory .custom-control-label::before {
  width: 3rem;
  height: 1.5rem;
  border-radius: 3rem;
}
.product-details .product__accessory .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: 3rem;
}
.product-details .product__accessory .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.product-details .product__accessory .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.5rem);
}
.product-details .product__accessory .with-badge .badge {
  float: right;
}
.product-details .product__accessory .name {
  padding-left: 0.65rem;
  display: flex;
  align-items: center;
}
.product-details .product__accessory .price {
  min-width: 100px;
  margin-left: 0.5rem;
  font-weight: bold;
  text-align: right;
}
.product-details .product__hide-price {
  font-weight: bold;
  margin-top: 1rem;
  padding: 0.85rem 1.25rem;
  display: inline-block;
  background-color: #f2f8fd;
  border: 1px solid #cddff2;
  border-radius: 0.5rem;
}
.product-details .alert-tempo-minimo {
  color: #3379be;
  background-color: #ffffff;
  font-weight: bold;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  display: block;
  position: relative;
  font-size: 0.9rem;
}
.product-details .bloco-data-devolucao {
  font-weight: bold;
  color: green;
}
.product-details .product__shipping .shipping__form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.product-details .product__shipping .shipping__form label {
  margin-bottom: 0;
}
.product-details .product__shipping .shipping__form .form-input {
  display: flex;
}
.product-details .product__shipping .shipping__results {
  border: 1px solid #e5e5e5;
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: 1rem;
  font-size: 0.9rem;
}
.product-details .product__shipping .shipping__results .result__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.product-details .product__shipping .shipping__results .result {
  margin: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ddd;
}
.product-details .product__shipping .shipping__results .result:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.product-details .product__shipping .shipping__results .result__price {
  font-weight: bold;
  color: #2b8454;
}
.product-details .product__shipping .shipping__results .result__deadline {
  font-weight: bold;
}
.product-details .pedido-assinatura .form-check {
  padding-left: 0;
  position: relative;
}
.product-details .pedido-assinatura .form-check .form-check-label {
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 1rem 1.5rem 1.5rem;
  width: 70%;
  color: #666;
  display: flex;
  align-items: center;
}
.product-details .pedido-assinatura .form-check .form-check-label .descricao {
  font-weight: 100;
}
.product-details .pedido-assinatura .form-check .form-check-label:hover {
  opacity: 0.8;
}
.product-details .pedido-assinatura .form-check .form-check-label .selected {
  display: none;
}
.product-details .pedido-assinatura .form-check .form-check-label .option::before {
  border: 2px solid #ccc;
  content: "";
  height: 24px;
  width: 24px;
  border-radius: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.product-details .pedido-assinatura .form-check .form-check-label .price {
  font-size: 1.15rem;
  font-weight: bold;
}
.product-details .pedido-assinatura .form-check .form-check-label .price--original {
  color: inherit;
}
.product-details .pedido-assinatura .form-check svg {
  border-color: #ccc;
  color: #ccc;
}
.product-details .pedido-assinatura .form-check input {
  opacity: 0;
  position: absolute;
  left: 46px;
  top: 35px;
  width: 24px;
  height: 24px;
}
.product-details .pedido-assinatura .form-check input:checked + label {
  color: var(--color-primary);
  border-color: var(--color-primary);
  background-color: rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 0.05);
}
.product-details .pedido-assinatura .form-check input:checked + label .option {
  display: none;
}
.product-details .pedido-assinatura .form-check input:checked + label .selected {
  display: block;
}
.product-details .pedido-assinatura .form-check input:checked + label svg {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

/* Modal - Galeria de fotos */
.product-carousel-modal {
  padding-right: 0 !important;
  z-index: 9999999999;
  overflow: hidden !important;
}
.product-carousel-modal .modal-dialog {
  height: 100vh;
  max-width: 100vw;
  margin: 0;
  padding: 0;
}
.product-carousel-modal .modal-dialog .modal-content {
  height: 100%;
  width: 100%;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.96);
}
.product-carousel-modal .modal-dialog .modal-content .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-carousel-modal .product-details__gallery .product-gallery {
  height: 90vh;
  width: 100vw;
}
.product-carousel-modal .product-details__gallery .product-gallery .carousel-inner {
  height: 100%;
  border: none;
  width: 90vw;
}
.product-carousel-modal .product-details__gallery .product-gallery .carousel-item {
  padding: 0;
  margin: 0 auto;
  text-align: center;
}
.product-carousel-modal .product-details__gallery .product-gallery .carousel-item img {
  max-width: 900px;
  max-height: 90vh;
}
.product-carousel-modal .product-details__gallery .product-gallery .carousel-control-prev,
.product-carousel-modal .product-details__gallery .product-gallery .carousel-control-next {
  width: 100px;
}

/* Tipo de Produto - Spot */
.product-spot {
  margin-bottom: 1rem;
}
.product-spot .product-spot__total {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
}
.product-spot .alert {
  padding: 7px 20px;
}

/* Produtos Relacionados */
.products__related {
  padding-bottom: 2rem;
}

@media (max-width: 991.98px) {
  .product-details .product-details__title {
    font-size: 1.5rem;
  }
  .product-details .product-gallery-block {
    align-items: flex-start;
  }
  .product-details .product-details__gallery .product-gallery {
    min-height: 30vh;
  }
  .product-details .product-details__gallery .product-gallery .carousel-inner {
    height: 30vh;
  }
  .product-details .product__accessory {
    max-width: 100%;
  }
  .product-details .product__accessory .custom-control-label {
    width: 100%;
    padding-top: 4px;
  }
  .product-details .product__accessory .custom-control-label .with-badge {
    flex-direction: column;
  }
  .product-details .product__accessory .custom-checkbox {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    left: 0;
  }
  .product-details .product__accessory .custom-checkbox .name {
    padding-left: 0.5rem;
  }
  .product-details .product__accessory .custom-checkbox .custom-checkbox__label--svg:before {
    width: 32px;
    height: 32px;
  }
  .product-details .product__accessory .custom-checkbox .custom-checkbox__label--svg svg {
    left: 7px;
  }
  .product-details .product__accessory .custom-checkbox .with-badge .price {
    position: absolute;
    bottom: 5px;
    right: 0;
  }
  .product-details .product-carousel-modal .product-gallery {
    height: 70vh;
  }
  .product-details .product-carousel-modal .product-gallery .carousel-inner {
    height: auto;
  }
  .product-details .product-details__buttons .btn-rent, .product-details .product-details__buttons .btn-buy, .product-details .product-details__buttons .btn-filter, .product-details .product-details__buttons .btn-download, .product-details .product-details__buttons .btn-download-small, .product-details .product-details__buttons .btn-cep, .product-details .product-details__buttons .btn-login-facebook, .product-details .product-details__buttons .btn-login-google {
    margin-top: 1rem;
  }
  .product-details .product__faixas .faixas__mini-bloco .mini-bloco {
    margin-top: 1rem;
  }
  .product-details .product__faixas.faixas__dropdown {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 575.98px) {
  .product-details {
    padding: 0 0 2rem 0;
  }
  .product-details .product-details__gallery .product-gallery {
    justify-content: center;
    min-height: auto;
  }
  .product-details .product-details__gallery .product-gallery .carousel-inner {
    height: 40vh;
  }
  .product-details .product-details__title {
    font-size: 1.25rem;
    margin-top: 1rem;
  }
  .product-details .product-details__buttons .select-date, .product-details .product-details__buttons .select-time {
    margin-bottom: 0;
  }
  .product-details .product-details__buttons .qtd-label {
    margin-top: 1rem;
  }
  .product-details .product-details__tag {
    margin-top: 0.5rem;
  }
  .product-details .product__shipping .shipping__form {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-details .product__shipping .shipping__results {
    font-size: 0.8rem;
  }
  .product-details .pedido-assinatura .form-check .form-check-label {
    width: 100%;
  }

  .product-scheduled .btn-rent, .product-scheduled .btn-buy, .product-scheduled .btn-filter, .product-scheduled .btn-download, .product-scheduled .btn-download-small, .product-scheduled .btn-cep, .product-scheduled .btn-login-facebook, .product-scheduled .btn-login-google {
    margin-top: 1rem;
  }

  .product-carousel-modal .product-details__gallery {
    width: auto;
  }
  .product-carousel-modal .product-details__gallery .product-gallery {
    height: 80vh;
  }
  .product-carousel-modal .product-details__gallery .product-gallery .carousel-inner {
    height: 80vh;
  }
  .product-carousel-modal .product-details__gallery .product-gallery .carousel-item img {
    max-width: 100%;
  }
}
.my-account, .checkout__page {
  margin-bottom: 2rem;
  /* Pedidos */
  /* Pedidos - Detalhes */
  /* Endereços */
}
.my-account__picture {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.my-account__picture .user-icon {
  background-color: #fff;
  border: 2px solid #262636;
  border-radius: 50px;
  color: #262636;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-account__user {
  margin-bottom: 2rem;
  color: #262636;
}
.my-account__user .user-name {
  font-weight: bold;
}
.my-account .sub-title, .checkout__page .sub-title {
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-account .nav-pills .nav-link, .checkout__page .nav-pills .nav-link {
  padding: 0.5rem 0;
  color: #262636;
  width: fit-content;
  border-radius: 0;
}
.my-account .nav-pills .nav-link svg, .checkout__page .nav-pills .nav-link svg {
  margin-right: 0.5rem;
  width: 26px;
  max-height: 26px;
}
.my-account .nav-pills .nav-link.active, .checkout__page .nav-pills .nav-link.active {
  background-color: transparent;
  color: var(--color-primary);
  border-radius: 0;
  font-weight: bold;
}
.my-account .nav-pills .nav-link.logout-link, .checkout__page .nav-pills .nav-link.logout-link {
  border-top: 1px solid #ddd;
  margin-top: 1rem;
  padding-top: 1.5rem;
}
.my-account .card, .checkout__page .card {
  border: none;
  box-shadow: 0px 2px 5px #7c44443b;
}
.my-account .card .card-header, .checkout__page .card .card-header {
  background-color: transparent;
  border-bottom: none;
}
.my-account .card .card-header h3, .checkout__page .card .card-header h3 {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  padding-top: 0.5rem;
}
.my-account .card .card-title, .checkout__page .card .card-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0;
  padding-top: 1rem;
}
.my-account .card .card-footer, .checkout__page .card .card-footer {
  background-color: transparent;
  border-top: none;
}
.my-account .orders__list, .checkout__page .orders__list {
  margin-bottom: 3rem;
}
.my-account .orders__list .card, .checkout__page .orders__list .card {
  border-left: 5px solid lightgray;
}
.my-account .orders__list .card--paid, .checkout__page .orders__list .card--paid {
  border-color: #82c785;
}
.my-account .orders__list .card--done, .checkout__page .orders__list .card--done {
  border-color: #3379be;
}
.my-account .orders__list .card--devolucao, .checkout__page .orders__list .card--devolucao {
  border-color: #e8198d;
}
.my-account .orders__list .card--canceled, .checkout__page .orders__list .card--canceled {
  border-color: #ec553d;
}
.my-account .orders__list .card-header, .checkout__page .orders__list .card-header {
  font-size: 0.9rem;
}
.my-account .order__details, .checkout__page .order__details {
  /* Produtos */
  /* Card Footer */
}
.my-account .order__details .order-item, .checkout__page .order__details .order-item {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f7f7f7;
  align-items: flex-start;
  justify-content: space-between;
}
.my-account .order__details .order-item a, .checkout__page .order__details .order-item a {
  color: #262636;
  font-weight: bold;
  display: flex;
  max-width: fit-content;
}
.my-account .order__details .order-item a:hover, .checkout__page .order__details .order-item a:hover {
  color: #4d4949;
}
.my-account .order__details .order-item .product__dates, .checkout__page .order__details .order-item .product__dates {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.my-account .order__details .order-item .product__dates .date-block, .checkout__page .order__details .order-item .product__dates .date-block {
  margin-top: 0.5rem;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background-color: #f8f9fa;
  width: 100%;
  max-width: 33%;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
}
.my-account .order__details .order-item .product__image-placeholder, .checkout__page .order__details .order-item .product__image-placeholder {
  height: 100px;
}
.my-account .order__details .order-item .product__image-placeholder svg, .checkout__page .order__details .order-item .product__image-placeholder svg {
  width: 50%;
}
.my-account .order__details .list-group-item, .checkout__page .order__details .list-group-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.my-account .order__details .list-group-item.total-item, .checkout__page .order__details .list-group-item.total-item {
  font-size: 1.25rem;
  font-weight: bold;
}
.my-account .order__details .payment-type, .checkout__page .order__details .payment-type {
  text-align: center;
}
.my-account.user-address .card .card-body p, .user-address.checkout__page .card .card-body p {
  margin-bottom: 0;
}
.my-account.user-address .card .card-footer, .user-address.checkout__page .card .card-footer {
  background-color: #f7f7f7;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-account.user-address .card .card-footer form, .user-address.checkout__page .card .card-footer form {
  position: absolute;
}
.my-account .address__header, .checkout__page .address__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-account .tabs-forms, .checkout__page .tabs-forms {
  font-weight: bold;
}
.my-account .tabs-forms .nav-tabs, .checkout__page .tabs-forms .nav-tabs {
  border: none;
}
.my-account .tabs-forms .selected, .checkout__page .tabs-forms .selected {
  display: none;
}
.my-account .tabs-forms .nav-link, .checkout__page .tabs-forms .nav-link {
  color: #262636;
  border-radius: 0;
  padding: 1rem 1.5rem;
  width: 25%;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  border: 2px solid #ddd;
}
.my-account .tabs-forms .nav-link:first-child, .checkout__page .tabs-forms .nav-link:first-child {
  margin-right: 1rem;
}
.my-account .tabs-forms .nav-link .tab-form-option::before, .checkout__page .tabs-forms .nav-link .tab-form-option::before {
  border: 2px solid #ccc;
  content: "";
  height: 24px;
  width: 24px;
  margin-right: 5px;
  border-radius: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.my-account .tabs-forms .nav-link.active, .checkout__page .tabs-forms .nav-link.active {
  background-color: #fff;
  border-color: #2e8540;
  color: #2e8540;
}
.my-account .tabs-forms .nav-link.active .tab-form-option::before, .checkout__page .tabs-forms .nav-link.active .tab-form-option::before {
  display: none;
}
.my-account .tabs-forms .nav-link.active .selected, .checkout__page .tabs-forms .nav-link.active .selected {
  display: inline-block;
}
.my-account .tabs-forms .nav-link.active .selected svg, .checkout__page .tabs-forms .nav-link.active .selected svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.my-account .modal-devolucao img, .checkout__page .modal-devolucao img {
  max-width: 80px;
  margin-left: 0;
}
.my-account .modal-devolucao .product__image-placeholder, .checkout__page .modal-devolucao .product__image-placeholder {
  width: 100px;
  margin-right: 1rem;
  height: 50px;
  text-align: center;
}
.my-account .modal-devolucao .product__image-placeholder svg, .checkout__page .modal-devolucao .product__image-placeholder svg {
  position: relative;
  width: 60%;
  height: 100%;
}

@media (max-width: 1399.98px) {
  .my-account .tabs-forms .nav-link, .checkout__page .tabs-forms .nav-link {
    width: 48%;
  }
}
@media (max-width: 1199.98px) {
  .my-account__user {
    text-align: center;
  }
  .my-account__picture {
    justify-content: center;
  }
}
@media (max-width: 991.98px) {
  .my-account .order__details .order-item, .checkout__page .order__details .order-item {
    flex-direction: row;
  }
  .my-account .order__details .order-item .product__dates, .checkout__page .order__details .order-item .product__dates {
    flex-direction: row;
  }
  .my-account .order__details .order-item .product__dates .date-block, .checkout__page .order__details .order-item .product__dates .date-block {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .my-account .sub-title, .checkout__page .sub-title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .my-account .sub-title .actions .btn, .checkout__page .sub-title .actions .btn {
    width: 100%;
    margin-top: 0.5rem;
    font-size: 1rem;
  }
  .my-account .order__details .order-item, .checkout__page .order__details .order-item {
    flex-direction: column;
  }
  .my-account .order__details .order-item .product__dates, .checkout__page .order__details .order-item .product__dates {
    flex-direction: column;
  }
  .my-account .order__details .order-item .product__dates .date-block, .checkout__page .order__details .order-item .product__dates .date-block {
    max-width: 100%;
  }
  .my-account .checkout__page .custom-creditcard, .checkout__page .checkout__page .custom-creditcard {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .my-account .sub-title .actions .btn, .checkout__page .sub-title .actions .btn {
    width: 100%;
  }
  .my-account.user-address .card, .user-address.checkout__page .card {
    margin-bottom: 1rem;
    height: auto;
  }
  .my-account.user-address .address__header, .user-address.checkout__page .address__header {
    margin-top: 0;
  }
  .my-account .tabs-forms .nav-link, .checkout__page .tabs-forms .nav-link {
    padding: 0.5rem;
    font-size: 0.85rem;
  }
  .my-account .tabs-forms .nav-link:first-child, .checkout__page .tabs-forms .nav-link:first-child {
    margin-right: 0.2rem;
  }
  .my-account .tabs-forms .nav-link .tab-form-option::before, .checkout__page .tabs-forms .nav-link .tab-form-option::before {
    height: 20px;
    width: 20px;
  }
  .my-account .tabs-forms .nav-link.active .selected svg, .checkout__page .tabs-forms .nav-link.active .selected svg {
    width: 20px;
    height: 20px;
  }
  .my-account .order__details .order-item, .checkout__page .order__details .order-item {
    flex-direction: column;
  }
  .my-account .order__details .order-item .product__image-placeholder, .checkout__page .order__details .order-item .product__image-placeholder {
    height: 60px;
    padding: 0;
  }
  .my-account .order__details .order-item .product__image-placeholder svg, .checkout__page .order__details .order-item .product__image-placeholder svg {
    width: 80%;
  }
  .my-account .order__details .order-item .product__dates, .checkout__page .order__details .order-item .product__dates {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .my-account .order__details .order-item .product__dates .date-block, .checkout__page .order__details .order-item .product__dates .date-block {
    max-width: 100%;
  }
  .my-account .order__details .list-group-item, .checkout__page .order__details .list-group-item {
    font-size: 0.9rem;
  }
  .my-account .order__details .payment-type, .checkout__page .order__details .payment-type {
    text-align: right;
  }
}
.authentication {
  margin-bottom: 2rem;
}
.authentication .card {
  border: none;
  box-shadow: 0px 2px 5px #7c44443b;
}
.authentication .card .card-header {
  background-color: transparent;
  text-align: center;
  border-bottom: none;
}
.authentication .card .card-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0;
  padding-top: 1rem;
}
.authentication .card .card-footer {
  background-color: transparent;
  border-top: none;
  text-align: center;
  margin-bottom: 0.5rem;
}
.authentication .btn {
  font-weight: bold;
}
.authentication a {
  color: #262636;
  font-weight: bold;
}
.authentication a:hover {
  opacity: 0.7;
}
.authentication .btn-outline-primary {
  color: var(--color-primary);
}
.authentication .btn-outline-primary:hover, .authentication .btn-outline-primary:focus {
  color: #fff;
}

.cart__page {
  margin-bottom: 3rem;
}
.cart__page .btn-link {
  color: #262636;
  font-weight: bold;
  padding: 0;
}
.cart__page .cart__products .list-header {
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.cart__page .cart__products .product-block {
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 0.5rem;
  box-shadow: 0px 2px 5px #7c44443b;
}
.cart__page .cart__products .product-block .btn-qtd {
  border-color: transparent;
}
.cart__page .cart__products .product-block .btn-qtd .btn {
  width: 36px;
}
.cart__page .cart__products .product-block a {
  color: #262636;
  font-size: 0.9rem;
}
.cart__page .cart__products .product-block a:hover {
  text-decoration: none;
}
.cart__page .cart__products .product-block .link-delete {
  font-weight: 100;
}
.cart__page .cart__products .product-block .produto-nome {
  font-weight: 700;
}
.cart__page .cart__products .product-block .product-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cart__page .cart__products .product-block .product-image {
  max-width: 70px;
  margin-right: 1rem;
  max-height: 130px;
}
.cart__page .cart__products .product-block label {
  display: none;
}
.cart__page .cart__products .product-block .cart-total {
  text-align: right;
}
.cart__page .cart__buttons--secondary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.cart__page .cart__buttons--secondary .cart__update {
  text-align: right;
}
.cart__page .cart__resumo {
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0px 2px 5px #7c44443b;
}
.cart__page .cart__resumo .title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.cart__page .cart__resumo .list-group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart__page .cart__resumo .cart__buttons {
  margin-top: 1rem;
}
.cart__page .cart__resumo .shipping__block {
  width: 100%;
  display: block;
}
.cart__page .cart__resumo .shipping__block .shipping__form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
.cart__page .cart__resumo .shipping__block .shipping__form .form-input {
  display: flex;
}
.cart__page .cart__resumo .shipping__block .shipping__form label {
  margin-bottom: 0;
}
.cart__page .cart__resumo .shipping__block .shipping__results .result {
  font-size: 0.85rem;
}
.cart__page .cart__resumo .shipping__block .shipping__results .result__price {
  font-weight: bold;
  margin-left: 0.5rem;
  color: #2b8454;
}
.cart__page .cart__resumo .shipping__block .shipping__results .result__deadline {
  font-weight: bold;
  margin-left: 0.5rem;
}
.cart__page .cart__resumo .shipping__block .shipping__results .result__type {
  margin-left: 0.5rem;
}

@media (max-width: 991.98px) {
  .cart__page .cart__resumo {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 767.98px) {
  .cart__page .cart__products .product-block .btn-qtd {
    margin-top: 1rem;
  }
}
@media (max-width: 575.98px) {
  .cart__page .cart__buttons--secondary {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 1rem;
  }
  .cart__page .cart__buttons--secondary .cart__update {
    text-align: right;
  }
  .cart__page .cart__buttons--secondary .btn {
    width: 100%;
    margin-top: 1rem;
  }
  .cart__page .cart__products .list-header {
    display: none;
  }
  .cart__page .cart__products .product-block .btn-qtd .btn {
    width: 36px;
    padding: 10px;
  }
  .cart__page .cart__resumo {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  .cart__page .cart__resumo .shipping__block .shipping__form {
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
  }
  .cart__page .cart__resumo .shipping__block .shipping__form label {
    margin-bottom: 0.5rem;
  }

  .product-content {
    margin-bottom: 1rem;
  }
}
.checkout__steps {
  background-color: var(--color-bg-header);
  width: 100%;
}
.checkout__steps .progress-bar__bg {
  background-color: var(--color-text-header);
  height: 2px;
  width: 85%;
  position: relative;
  bottom: 10px;
  margin: 0 auto;
  opacity: 0.5;
}
.checkout__steps .steps {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
}
.checkout__steps .steps .step {
  text-align: center;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.checkout__steps .steps .step .step__number {
  color: var(--color-text-header);
  line-height: 1.7;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.checkout__steps .steps .step svg {
  display: none;
}
.checkout__steps .steps .step.done .step__icon {
  background-color: transparent;
  border: none;
}
.checkout__steps .steps .step.done .step__icon svg {
  width: 30px;
  height: 30px;
  display: block;
}
.checkout__steps .steps .step.done .step__number {
  display: none;
}
.checkout__steps .steps .step.current {
  opacity: 1;
}
.checkout__steps .steps .step.current .step__icon {
  background-color: var(--color-text-header);
}
.checkout__steps .steps .step.current .step__number {
  display: block;
  color: var(--color-bg-header);
}
.checkout__steps .steps .step__line {
  background-color: var(--color-text-header);
  height: 2px;
  width: 100%;
  opacity: 0.5;
}
.checkout__steps .steps .step__icon {
  border-radius: 50px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 2px solid var(--color-text-header);
}
.checkout__steps .step__link {
  color: var(--color-text-header);
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-top: 0.35rem;
  font-weight: bold;
}

.checkout__page {
  /* Tipos de Pagamento */
  /* Retirada */
  /* Iugu */
  /* Checkout - Validacao Facial */
}
.checkout__page .checkout__container {
  padding-bottom: 3rem;
}
.checkout__page .link-login {
  text-align: right;
  margin-bottom: 0.5rem;
}
.checkout__page .checkout__question {
  font-size: 1.25rem;
  font-weight: bold;
}
.checkout__page .last-step__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout__page .payment-methods {
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
}
.checkout__page .payment-methods .payment-type-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: inherit;
}
.checkout__page .payment-methods .payment-type-content svg {
  margin-bottom: 1rem;
  height: 26px;
  color: #666;
}
.checkout__page .payment-methods .card-selected svg {
  color: #066806;
}
.checkout__page .payment-methods .card-custom-radio {
  flex: 1;
  margin-right: 0.5rem;
  max-width: 48%;
}
.checkout__page .payment-methods .card-custom-radio .form-check {
  height: 100%;
}
.checkout__page .payment-methods .card-custom-radio .custom-radio {
  padding-left: 0;
  height: 100%;
}
.checkout__page .checkout__footer {
  padding: 1rem 0;
}
.checkout__page .locais-retirada {
  margin-top: 0.5rem;
  border-radius: 10px;
}
.checkout__page .locais-retirada .select-date input, .checkout__page .locais-retirada .select-time input {
  width: auto;
  min-width: 200px;
}
.checkout__page .locais-retirada .select-date .form-control[readonly], .checkout__page .locais-retirada .select-time .form-control[readonly], .checkout__page .locais-retirada .select-date [readonly].btn-qtd, .checkout__page .locais-retirada .select-time [readonly].btn-qtd {
  background-color: #ffffff;
}
.checkout__page .locais-retirada .select-date .form-control[readonly]:hover, .checkout__page .locais-retirada .select-time .form-control[readonly]:hover, .checkout__page .locais-retirada .select-date [readonly].btn-qtd:hover, .checkout__page .locais-retirada .select-time [readonly].btn-qtd:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}
.checkout__page .locais-retirada__opcoes ul {
  list-style: none;
  padding-left: 0;
}
.checkout__page .locais-retirada .local-name {
  font-weight: bold;
}
.checkout__page .locais-retirada .custom-radio {
  position: relative;
  padding-left: 2.5rem;
}
.checkout__page .locais-retirada .icon-selected {
  position: relative;
  left: -10px;
}
.checkout__page .locais-retirada .radio-checkmark {
  left: 10px;
}
.checkout__page .locais-retirada .card-selected .local-name {
  color: #066806;
}
.checkout__page .custom-creditcard {
  width: 380px;
  position: relative;
  margin: 1rem 0 0 0;
}
.checkout__page .custom-creditcard .input-invalid-value {
  border-color: red !important;
  color: red;
}
.checkout__page .custom-creditcard .input-valid-value {
  border-color: green !important;
  color: green;
}
.checkout__page .custom-creditcard .usable-creditcard-form {
  margin-left: 0;
  width: 100%;
  position: relative;
}
.checkout__page .custom-creditcard .usable-creditcard-form input {
  background-color: #fff;
  outline: none;
  font-size: 1rem;
}
.checkout__page .custom-creditcard .usable-creditcard-form .wrapper {
  border: none;
  width: 100%;
  height: auto;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group {
  /* Numero do cartao */
  /* CVV */
  /* Titular do Cartão */
  /* Data vencimento */
  /* Numero de Parcelas */
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group .icon {
  top: 12px;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group input {
  border-radius: 3rem !important;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_a {
  width: auto;
  position: relative;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_a input {
  margin-bottom: 1rem;
  border: 1px solid #ced4da;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_b {
  position: relative;
  top: auto;
  width: auto;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_b input {
  text-align: left;
  border: 1px solid #ced4da;
  border-radius: 3rem;
  margin-bottom: 1rem;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_c {
  position: relative;
  top: auto;
  width: auto;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_c input {
  border: 1px solid #ced4da;
  border-radius: 3rem;
  margin-bottom: 1rem;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_d {
  position: relative;
  top: auto;
  width: auto;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_d input {
  text-align: left;
  border: 1px solid #ced4da;
  border-radius: 3rem;
  margin-bottom: 1rem;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_np {
  position: relative;
  top: auto;
  width: auto;
}
.checkout__page .custom-creditcard .usable-creditcard-form .input-group.nmb_np select {
  text-align: left;
  border: 1px solid #ced4da;
  border-radius: 3rem;
  margin-bottom: 1rem;
  padding: 0.625rem 1rem;
  height: auto;
  width: 100%;
  font-size: 1rem;
}
.checkout__page .checkout__resumo, .checkout__page .checkout__resumo--orcamento, .checkout__page .checkout__resumo--detailed {
  padding: 1rem;
  margin-bottom: 1rem;
  border: none;
  box-shadow: 0px 2px 5px #7c44443b;
  background-color: #fff;
}
.checkout__page .checkout__resumo .list-products, .checkout__page .checkout__resumo--orcamento .list-products, .checkout__page .checkout__resumo--detailed .list-products {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.checkout__page .checkout__resumo .list-products li, .checkout__page .checkout__resumo--orcamento .list-products li, .checkout__page .checkout__resumo--detailed .list-products li {
  border: none;
  padding: 0.1rem 0;
  font-size: 0.8rem;
}
.checkout__page .checkout__resumo .list-products li .product__name, .checkout__page .checkout__resumo--orcamento .list-products li .product__name, .checkout__page .checkout__resumo--detailed .list-products li .product__name {
  max-width: 60%;
  color: #262636;
}
.checkout__page .checkout__resumo--detailed {
  box-shadow: none;
  padding: 0;
}
.checkout__page .checkout__resumo--detailed .list-products {
  display: flex;
}
.checkout__page .checkout__resumo--detailed .list-products li {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  font-size: 0.9rem;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.checkout__page .checkout__resumo--detailed .list-products li:last-child {
  border-bottom: none;
}
.checkout__page .checkout__resumo--detailed .list-products li .product__info {
  display: flex;
  flex-direction: row;
}
.checkout__page .checkout__resumo--detailed .product__photo {
  margin-right: 1rem;
  width: 110px;
}
.checkout__page .checkout__resumo--detailed .product__photo img {
  max-width: 100px;
  width: auto;
  max-height: 100px;
  height: auto;
}
.checkout__page .checkout__resumo--detailed .product__name {
  font-weight: bold;
}
.checkout__page .checkout__resumo--orcamento .list-products {
  border-bottom: none;
}
.checkout__page .checkout__resumo--orcamento .list-products img {
  width: 50px;
  margin-right: 0.5rem;
}
.checkout__page .checkout__resumo--orcamento .list-products li {
  border-bottom: 1px solid #f7f7f7;
  padding: 0.5rem 0;
}
.checkout__page .checkout__resumo--orcamento .list-products li .product__name {
  max-width: 100%;
}
.checkout__page .checkout__resumo--orcamento .list-products li:last-child {
  border-bottom: none;
}
.checkout__page .checkout__resumo--orcamento .list-products li .product__photo--content {
  display: inline-block;
}
.checkout__page .checkout__resumo--orcamento .list-products li .product__photo--content .product__image-placeholder {
  width: 50px;
  margin-right: 0.5rem;
}
.checkout__page .checkout__resumo .title, .checkout__page .checkout__resumo--detailed .title, .checkout__page .checkout__resumo--orcamento .title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.checkout__page .checkout__resumo .list-group-item, .checkout__page .checkout__resumo--detailed .list-group-item, .checkout__page .checkout__resumo--orcamento .list-group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 0;
}
.checkout__page .checkout__resumo .total, .checkout__page .checkout__resumo--detailed .total, .checkout__page .checkout__resumo--orcamento .total {
  font-weight: bold;
  font-size: 1.25rem;
}
.checkout__page .checkout__resumo .checkout__buttons, .checkout__page .checkout__resumo--detailed .checkout__buttons, .checkout__page .checkout__resumo--orcamento .checkout__buttons {
  margin: 1rem 0 0.5rem;
}
.checkout__page .checkout__buttons--steps {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.checkout__page .checkout__buttons--steps .btn-success {
  padding: 0.5rem 2rem;
  margin-left: 0.5rem;
}
.checkout__page .checkout__buttons--steps .btn-block + .btn-block {
  margin-top: 0;
  margin-left: 0.5rem;
}
.checkout__page .checkout__buttons--steps.last-step {
  float: right;
}
.checkout__page .checkout__confirm .card {
  height: 100%;
}
.checkout__page .checkout__confirm .card-header {
  color: #262636;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.checkout__page .checkout__confirm .card-header svg {
  height: 20px;
  width: auto;
  margin-right: 10px;
}
.checkout__page .checkout__confirm .card-footer {
  background-color: #f7f7f7;
  padding: 0.5rem;
}
.checkout__page .checkout__confirm .user-details {
  margin-bottom: 2rem;
}
.checkout__page .checkout__confirm .user-details p {
  margin-bottom: 0.3rem;
}
.checkout__page .checkout__confirm .alert__custom-message {
  margin-bottom: 0;
  font-size: 1rem;
}
.checkout__page .checkout__radio {
  margin-bottom: 1rem;
  padding: 0;
}
.checkout__page .checkout__radio label {
  cursor: pointer;
  width: 100%;
  padding: 1rem;
}
.checkout__page .checkout__radio input:checked + label span {
  color: #066806;
}
.checkout__page .checkout__radio span {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
}
.checkout__page .checkout__radio.shipping label {
  padding: 0.5rem;
  margin-bottom: 0;
}
.checkout__page .checkout__radio.shipping .radio-checkmark {
  top: 0.6rem;
}
.checkout__page .checkout__radio.shipping span {
  margin-right: 1rem;
  text-transform: none;
}
.checkout__page .checkout__radio.shipping .deadline {
  color: #666;
  float: right;
}
.checkout__page .checkout__radio.shipping .custom-radio--info {
  float: right;
  margin: 0;
}
.checkout__page .checkout__radio.shipping .form-check .logo {
  max-width: 80px;
  margin-right: 0.5rem;
  display: block;
}
.checkout__page .checkout__radio.shipping.card-selected .deadline {
  color: #066806;
}
.checkout__page .checkout__radio.shipping.card-selected .icon-selected svg {
  top: 10px;
}
.checkout__page .face-validation .camera-block {
  background-color: #333;
  position: relative;
  text-align: center;
  height: 300px !important;
  background-color: bisque;
  background-color: #fff;
}
.checkout__page .face-validation .camera-block::after {
  content: "";
  background: url("../../../../../img/templates/1/mask-face.png");
  height: 300px;
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
}
.checkout__page .face-validation .camera-block video {
  height: 300px !important;
  width: 300px !important;
  background-color: #fff;
}
.checkout__page .face-validation .img-face {
  max-width: 300px;
}
.checkout__page .face-validation canvas {
  height: 300px !important;
  width: 300px !important;
}

.pix {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  width: auto;
  max-width: 300px;
  margin: 0 auto;
}
.pix img {
  border: 2px solid #ddd;
  width: 200px;
  height: 200px;
}

.pix-link {
  border: 2px solid #ddd;
  max-width: 200px;
  margin-top: 1rem;
}
.pix-link:hover {
  background-color: #ddd !important;
}

@media (min-width: 767.98px) {
  .checkout__steps .steps .step {
    font-size: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .checkout__page .link-login {
    margin-bottom: 1rem;
  }
  .checkout__page .checkout__resumo .list-group-item, .checkout__page .checkout__resumo--detailed .list-group-item, .checkout__page .checkout__resumo--orcamento .list-group-item {
    font-size: 0.9rem;
  }
}
@media (max-width: 991.98px) {
  .checkout__page .checkout__container {
    padding-bottom: 0;
  }
  .checkout__page .checkout__confirm .card {
    margin-top: 1rem;
    height: auto;
  }
  .checkout__page .checkout__resumo, .checkout__page .checkout__resumo--detailed, .checkout__page .checkout__resumo--orcamento {
    margin-top: 2rem;
  }
  .checkout__page .checkout__resumo .list-group-item, .checkout__page .checkout__resumo--detailed .list-group-item, .checkout__page .checkout__resumo--orcamento .list-group-item {
    font-size: 0.9rem;
  }
  .checkout__page .link-login {
    margin-bottom: 2rem;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .checkout__steps .steps {
    padding-left: 0;
    padding-top: 0.5rem;
  }
  .checkout__steps .steps .step {
    font-size: 0.6rem;
    font-weight: bold;
  }
  .checkout__steps .steps .step .step__link {
    font-size: 0.7rem;
    text-transform: capitalize;
  }
  .checkout__steps .steps .step .step__number {
    font-size: 0.75rem;
    line-height: 1.5;
    text-align: center;
  }
  .checkout__steps .steps .step.current {
    display: flex;
  }
  .checkout__steps .steps .step.current .step__link {
    text-align: center;
  }
  .checkout__steps .steps .step .step__icon {
    width: 22px;
    height: 22px;
    margin-bottom: 0.3rem;
  }
  .checkout__steps .steps .step.done .step__icon svg {
    width: 22px;
    height: 22px;
  }
  .checkout__steps .progress-bar__content {
    width: 85vw;
  }

  .checkout__page .checkout__container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .checkout__page .custom-creditcard {
    width: 100%;
    padding: 0;
  }
  .checkout__page .custom-creditcard .usable-creditcard-form input {
    font-size: 0.9rem;
  }
  .checkout__page .payment-methods {
    flex-direction: column;
  }
  .checkout__page .payment-methods .card-custom-radio {
    margin: 0;
    flex: 1;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5rem;
  }
  .checkout__page .payment-methods .card-custom-radio .payment-type-content {
    align-items: center;
    margin-left: 1rem;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
  }
  .checkout__page .payment-methods .card-custom-radio .payment-type-content svg {
    margin-right: 0.5rem;
    width: 20px;
    margin-bottom: 0;
  }
  .checkout__page .link-login {
    margin-bottom: 2rem;
    text-align: center;
  }
  .checkout__page .last-step__title {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
  }
  .checkout__page .last-step__title .btn {
    margin-top: 1rem;
  }
  .checkout__page .checkout__resumo, .checkout__page .checkout__resumo--detailed, .checkout__page .checkout__resumo--orcamento {
    margin-top: 2rem;
  }
  .checkout__page .checkout__resumo .list-products li, .checkout__page .checkout__resumo--detailed .list-products li, .checkout__page .checkout__resumo--orcamento .list-products li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
  .checkout__page .checkout__resumo .list-products li .product__name, .checkout__page .checkout__resumo--detailed .list-products li .product__name, .checkout__page .checkout__resumo--orcamento .list-products li .product__name {
    max-width: 100%;
    font-size: 0.9rem;
  }
  .checkout__page .checkout__buttons--steps {
    flex-direction: column-reverse;
  }
  .checkout__page .checkout__buttons--steps .btn {
    width: 100%;
    font-size: 1.1rem;
    margin-left: 0;
  }
  .checkout__page .checkout__buttons--steps .btn-go-back {
    text-transform: lowercase;
  }
  .checkout__page .checkout__radio label {
    display: flex;
    flex-direction: column;
  }
  .checkout__page .checkout__radio.shipping .custom-radio--info {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .checkout__page .face-validation .alert {
    padding: 0.5rem 3rem 0.5rem 1rem;
  }
  .checkout__page .face-validation .alert ol {
    padding-left: 5px;
  }
  .checkout__page .face-validation canvas {
    height: auto !important;
  }
  .checkout__page .face-validation .checkout__buttons--steps a {
    margin-top: 0.5rem;
  }
}
.contact__page {
  background-color: #fff;
}
.contact__page .map-block {
  display: flex;
  margin-top: 3rem;
  background-color: #eee;
  margin-bottom: 0;
  border-radius: 1rem;
  overflow: hidden;
}

.map__page .map-block {
  overflow: hidden;
  border-radius: 1rem;
  max-width: 100%;
  margin-bottom: 3rem;
}
.map__page .map-block iframe {
  overflow: hidden;
  max-width: 100%;
}

.blog {
  background-color: #fff;
  padding-bottom: 4rem;
  min-height: 71vh;
}
.blog .raw-html-embed img {
  margin-bottom: 1rem;
  max-width: 100%;
  height: auto;
}
.blog .card {
  border: none;
  padding: 1rem;
}
.blog .card .card-body {
  padding: 1.5rem;
}
.blog__post {
  margin-bottom: 2rem;
}
.blog__post-details {
  font-size: 1.125rem;
  padding-top: 1rem;
}
.blog__post-details p {
  font-size: 1.125rem;
  line-height: 1.8;
}
.blog .post-name {
  font-size: 2rem;
  color: #757575;
  font-weight: 600;
  line-height: 1.2;
}
.blog .post-name a {
  font-size: 2rem;
  color: #757575;
  font-weight: 600;
  line-height: 1.2;
}
.blog .post-category {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.blog .post-category a {
  margin-right: 15px;
  color: #0c6cb9;
}
.blog .post-image {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.blog .post-description {
  line-height: 1.75;
  padding-top: 1rem;
  font-size: 1.125rem;
}
.blog .title-block {
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: #262636;
  font-size: 0.9rem;
}
.blog__categories ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.blog__categories ul li {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 0.5rem;
}
.blog__categories ul li:last-child {
  border: none;
}
.blog__categories ul a {
  color: #0c6cb9;
  font-size: 0.9rem;
}
.blog__search {
  margin-bottom: 3rem;
}
.blog .filters {
  padding: 0;
}
.blog__pagination .pagination-block {
  justify-content: flex-start;
}

.ultimos-posts {
  padding: 2rem 0 3rem 0;
  font-size: 1.3rem;
}
.ultimos-posts .posts {
  margin-top: 1rem;
}
.ultimos-posts .blog__post {
  font-weight: bold;
  margin-bottom: 1rem;
}
.ultimos-posts .blog__post .post-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ultimos-posts .post-image {
  border-radius: 1rem;
  margin-bottom: 1rem;
  width: auto;
  max-height: 300px;
}
.ultimos-posts .post-title {
  overflow: hidden;
  max-height: 4rem;
  color: #757575;
  margin-bottom: 1.5rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ultimos-posts .post-title a {
  color: #757575;
}

@media (max-width: 575.98px) {
  .blog .post-name {
    font-size: 1.5rem;
  }
  .blog .post-name a {
    font-size: 1.5rem;
  }
  .blog .card .card-body {
    padding: 1.2rem;
  }
  .blog .filters {
    margin-top: 2rem;
    padding: 0;
  }
  .blog__post-details {
    padding-top: 2rem;
  }

  .ultimos-posts .post-title {
    margin-bottom: 0.5rem;
  }
  .ultimos-posts .blog__post {
    margin-bottom: 3rem;
  }
}
.fatura-site {
  background-color: #ffffff;
  margin: 0 auto;
}
.fatura-site .topo {
  background-color: #edeae4;
}
.fatura-site .container {
  max-width: 800px;
}
.fatura-site .topo--conteudo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.fatura-site .logo {
  max-width: 200px;
}
.fatura-site .cliente {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fatura-site .info-cliente {
  font-size: 1rem;
  font-weight: bold;
}
.fatura-site .detalhes {
  padding: 20px 0;
}
.fatura-site .titulos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.fatura-site .titulo-secundario {
  font-size: 1.5rem;
  font-weight: 500;
}
.fatura-site .vencimento {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.fatura-site .data {
  font-size: 1.5rem;
  font-weight: 500;
}
.fatura-site .produtos {
  padding: 20px 0;
}
.fatura-site .label {
  color: #928c7e;
  text-transform: uppercase;
  margin-top: 0.5rem;
  font-weight: 500;
}
.fatura-site .tabela {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.fatura-site .linha {
  display: table-row;
}
.fatura-site .coluna {
  display: table-cell;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
.fatura-site .cabecalho {
  border-bottom: 3px solid #edeae4;
}
.fatura-site .valor {
  text-align: right;
}
.fatura-site .subtotal .coluna,
.fatura-site .desconto .coluna,
.fatura-site .total .coluna {
  text-align: right;
}
.fatura-site .total .descricao {
  width: 70%;
}
.fatura-site .total {
  font-size: 1.5rem;
  font-weight: bold;
}
.fatura-site .border-dotted {
  margin: 2rem 0;
  padding: 1rem 0;
  color: #edeae4;
  border: 0;
  border-top: 8px dotted;
  width: 100%;
  height: 5px;
}
.fatura-site .status {
  display: flex;
  justify-content: center;
  width: 190px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: -45px;
  color: white;
  padding: 12px 60px;
  transform: rotate(45deg);
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #928c7e;
}
.fatura-site .status-badge {
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;
  height: 150px;
  overflow: hidden;
}
.fatura-site .status--paid {
  background-color: #05a425;
}
.fatura-site .status--pending {
  background-color: #dc9514;
}
.fatura-site .status--refund {
  background-color: #c51414;
}
.fatura-site .ambiente-badge {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  transform: rotate(315deg);
  text-align: center;
}
.fatura-site .fatura-teste {
  background-color: #c51414;
  color: #fff;
  margin: 0;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 1rem;
}
.fatura-site .invoice {
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
}
.fatura-site .invoice .bank_slip_checkout {
  max-width: 800px;
  margin: 0 auto;
}
.fatura-site .invoice .bank_slip_form {
  position: relative;
}
.fatura-site .invoice .bank_slip_label.icon_space {
  line-height: 10px;
  margin-left: 56px;
}
.fatura-site .invoice .bank_slip_label {
  min-height: 34px;
  margin-bottom: 9px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 0 7px 7px;
  padding-right: 0;
}
.fatura-site .invoice .bank_slip_label div {
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}
.fatura-site .invoice .printer {
  display: none;
}
.fatura-site .invoice .bank_slip_form .form_header img {
  position: absolute;
  top: 5px;
  right: 0;
  max-width: 75px;
}
.fatura-site .invoice .bank_slip_label.reverse {
  text-align: right;
}
.fatura-site .invoice .bank_slip_label span {
  font-size: 10px;
  line-height: 10px;
  margin: 0;
  padding: 0;
  display: block;
  text-transform: uppercase;
  color: #7f7f7f;
}
.fatura-site .invoice .barcode_info p {
  display: block;
  text-align: center;
  max-width: 405px;
  font-size: 0.7rem;
}
.fatura-site .invoice .bank_slip_icon {
  position: absolute;
  top: 0;
  left: 0;
}
.fatura-site .invoice .bank_slip_form .pay-bank-slip-button {
  height: 44px;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  width: 140px;
  text-align: center;
  border: 0 !important;
  border-radius: 5px;
  transition: all 50ms ease;
  color: #fff;
  text-shadow: 0 1px 0 #b3b3b3;
  background-color: #ccc;
  box-shadow: 0 6px 0 #b3b3b3;
}
.fatura-site .invoice .status_warning {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-25deg);
  transform-origin: left;
  font-size: 5rem;
  color: #7f7f7f;
  font-weight: 500;
}
.fatura-site .invoice .bank-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.fatura-site .invoice .bank-info span {
  font-size: 1rem;
}
.fatura-site .qrcode {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1rem;
  border: 1px solid #ccc;
}
.fatura-site .btn-pix {
  border: 1px solid #ccc;
  width: 100%;
  font-weight: 400;
}