.fatura-site {
    background-color: #ffffff;
    margin: 0 auto;

    .topo {
        background-color: #edeae4;
    }
    .container {
        max-width: 800px;
    }

    .topo--conteudo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
    }

    .logo {
        max-width: 200px;
    }

    .cliente {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .info-cliente {
        font-size: 1rem;
        font-weight: bold;
    }

    .detalhes {
        padding: 20px 0;
    }

    .titulos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
    }
    .titulo-secundario {
        font-size: 1.5rem;
        font-weight: 500;
    }

    .vencimento {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .data {
        font-size: 1.5rem;
        font-weight: 500;
    }

    .produtos {
        padding: 20px 0;
    }

    .label {
        color: #928c7e;
        text-transform: uppercase;
        margin-top: .5rem;
        font-weight: 500;
    }

    .tabela {
        display: table;
        width: 100%;
        border-collapse: collapse;
    }

    .linha {
        display: table-row;
    }

    .coluna {
        display: table-cell;
        padding: 8px;
        border-bottom: 1px solid #ddd;
    }

    .cabecalho {
        border-bottom: 3px solid #edeae4;
    }

    .valor {
        text-align: right;
    }

    .subtotal .coluna,
    .desconto .coluna,
    .total .coluna {
        text-align: right;
    }

    .total .descricao {
        width: 70%;
    }

    .total {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .border-dotted {
        margin: 2rem 0;
        padding: 1rem 0;
        color: #edeae4;
        border: 0;
        border-top: 8px dotted;
        width: 100%;
        height: 5px;
    }

    .status {
        display: flex;
        justify-content: center;
        width: 190px;
        height: 50px;
        position: absolute;
        top: 20px;
        right: -45px;
        color: white;
        padding: 12px 60px;
        transform: rotate(45deg);
        font-weight: bold;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        background-color: #928c7e;
    }

    .status-badge {
        position: absolute;
        right: 0;
        top: 0;
        width: 150px;
        height: 150px;
        overflow: hidden;

    }

    .status--paid {
        background-color: #05a425;
    }

    .status--pending {
        background-color: #dc9514;
    }

    .status--refund {
        background-color: #c51414;
    }

    .ambiente-badge {
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
        height: 200px;
        overflow: hidden;
        transform: rotate(315deg);
        text-align: center
    }

    .fatura-teste {
        background-color: #c51414;
        color: #fff;
        margin: 0;
        font-weight: 500;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        padding: 1rem;
    }

    // Fatura Iugu
    .invoice {
        background-color: #fff;
        font-size: 14px;
        line-height: 1.5;
        position: relative;

        .bank_slip_checkout {
           max-width: 800px;
           margin: 0 auto;
       }

        .bank_slip_form {
           position: relative;
       }

       .bank_slip_label.icon_space {
           line-height: 10px;
           margin-left: 56px;
       }

       .bank_slip_label {
           min-height: 34px;
           margin-bottom: 9px;

           border-left: 1px solid #000;
           border-bottom: 1px solid #000;
           padding: 0 7px 7px;

           padding-right: 0;
           div {
               font-size: .85rem;
               font-weight: 500;
               margin-top: 10px;
               margin-bottom: 10px;
           }
       }
       .printer {
           display: none;
       }
       .bank_slip_form .form_header img {
           position: absolute;
           top: 5px;
           right: 0;
           max-width: 75px;
       }
       .bank_slip_label.reverse {
           text-align: right;
       }
       .bank_slip_label span {
           font-size: 10px;
           line-height: 10px;
           margin: 0;
           padding: 0;
           display: block;
           text-transform: uppercase;
           color: #7f7f7f;
       }
       .barcode_info p {
           display: block;
           text-align: center;
           max-width: 405px;
           font-size: .7rem;
       }
       .bank_slip_icon {
           position: absolute;
           top: 0;
           left: 0;
       }
        .bank_slip_form .pay-bank-slip-button {
            height: 44px;
            font-size: 18px;
            font-weight: 700;
            line-height: 30px;
            width: 140px;
            text-align: center;
            border: 0 !important;
            border-radius: 5px;
            transition: all 50ms ease;
            color: #fff;
            text-shadow: 0 1px 0 #b3b3b3;
            background-color: #ccc;
            box-shadow: 0 6px 0 #b3b3b3;
        }
        .status_warning {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-25deg);
            transform-origin: left;
            font-size: 5rem;
            color: #7f7f7f;
            font-weight: 500;
        }
        .bank-info  {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;

            span {
                font-size: 1rem;
            }
        }
    }
    .qrcode {
        display: block;
        margin: 0 auto;
        margin-top: 20px;
        padding: 1rem;
        border: 1px solid #ccc;
    }
    .btn-pix {
        border: 1px solid #ccc;
        width: 100%;
        font-weight: 400;
    }

}
