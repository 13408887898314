.authentication {
    margin-bottom: 2rem;

    .card {
        border: none;
        box-shadow: $box-shadow-light;

        .card-header {
            background-color: transparent;
            text-align: center;
            border-bottom: none;
        }
        .card-title {
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 0;
            padding-top: 1rem;
        }
        .card-footer {
            background-color: transparent;
            border-top: none;
            text-align: center;
            margin-bottom: 0.5rem;
        }
    }
    .btn {
        font-weight: bold;
    }

    a {
        color: $color-text;
        font-weight: bold;

        &:hover {
            opacity: 0.7;
        }
    }
    .btn-outline-primary {
        color: var(--color-primary);

        &:hover,
        &:focus {
            color: #fff;
        }
    }
}
