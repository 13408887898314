.blog {
    background-color: #fff;
    padding-bottom: 4rem;
    min-height: 71vh;

    // Imagem upload dentro do editor texto
    .raw-html-embed {
        img {
            margin-bottom: 1rem;
            max-width: 100%;
            height: auto;
        }
    }

    .card {
        border: none;
        padding: 1rem;
        .card-body {
            padding: 1.5rem;
        }
    }

    &__post {
        margin-bottom: 2rem;
    }

    &__post-details {
        font-size: 1.125rem;
        padding-top: 1rem;
        p {
            font-size: 1.125rem;
            line-height: 1.8;
        }
    }
    .post-name {
        font-size: 2rem;
        color: $color-text-light;
        font-weight: 600;
        line-height: 1.2;
        a {
            font-size: 2rem;
            color: $color-text-light;
            font-weight: 600;
            line-height: 1.2;
        }
    }
    .post-category {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        a {
            margin-right: 15px;
            color: $color-link;
        }
    }
    .post-image {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .post-description {
        line-height: 1.75;
        padding-top: 1rem;
        font-size: 1.125rem;
    }

    .title-block {
        font-weight: bold;
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: $color-text;
        font-size: 0.9rem;
    }

    &__categories {
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                margin-bottom: 0.5rem;
                border-bottom: 1px solid #f4f4f4;
                padding-bottom: 0.5rem;

                &:last-child {
                    border: none;
                }
            }
            a {
                color: $color-link;
                font-size: 0.9rem;
            }
        }
    }

    &__search {
        margin-bottom: 3rem;
    }
    .filters {
        padding: 0;
    }

    &__pagination {
        .pagination-block {
            justify-content: flex-start;
        }
    }
}

.ultimos-posts {
    padding: 2rem 0 3rem 0;
    font-size: 1.3rem;
    .posts {
        margin-top: 1rem;
    }
    .blog__post {
        font-weight: bold;
        margin-bottom: 1rem;

        .post-link {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .post-image {
        border-radius: 1rem;
        margin-bottom: 1rem;
        width: auto;
        max-height: 300px;
    }
    .post-title {
        overflow: hidden;
        max-height: 4rem;
        color: #757575;
        margin-bottom: 1.5rem;

        a {
            color: #757575;
        }

        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
@media (max-width: $breakpoint-sm) {
    .blog {
        .post-name {
            font-size: 1.5rem;
            a {
                font-size: 1.5rem;
            }
        }
        .card {
            .card-body {
                padding: 1.2rem;
            }
        }
        .filters {
            margin-top: 2rem;
            padding: 0;
        }
        &__post-details {
            padding-top: 2rem;
        }
    }
    .ultimos-posts {
        .post-title {
            margin-bottom: 0.5rem;
        }
        .blog__post {
            margin-bottom: 3rem;
        }
    }
}
