/* Busca */
.search-block {
    display: flex;
    flex: 1;
    padding: 0 1rem;
}
.search-form {
    width: 100%;
    flex: 1;

    input {
        border-radius: 50px;
        border-right: none;
        height: 39px;

        &:focus {
            box-shadow: none;
            border-color: #ced4da;
        }
    }
    .input-group {
        width: 100%;
        margin: 0;

        &:focus {
            border-radius: 50px;
        }
    }
    .btn-search {
        background-color: #ffffff;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border: 1px solid #ced4da;
        border-left: none;
        height: 39px;
        &:hover {
            background-color: #ffffff;
            border-color: #ced4da;
            color: #333333;
        }
        &:focus {
            box-shadow: none;
        }
    }
}
.search__results {
    .search__results--message {
        font-weight: bold;
    }
}
.search-container {
    @extend .sidebar-container;

    top: 0; //-375px;

    transform: translateY(-375px);
    transition: 0.5s ease all;

    height: 18%;
    max-width: 100%;
    min-height: 130px;
    width: 100%;

    a,
    button {
        color: var(--color-text-header);
    }

    &.open-block {
        //transform: translateY(0);
        transform: translateY(0);
        box-shadow: $box-shadow-light;
        height: auto;
        padding: 0.5rem 1rem;
    }
}
