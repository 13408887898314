.filters {
    background-color: #ffffff;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;

    .filters-content {
        justify-content: space-between;
    }

    .filter {
        border: none;

        &__title {
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            font-size: 0.85rem;
            color: $color-text;
            border-radius: 0;
            text-decoration: none;

            &[aria-expanded="true"] {
                color: #000;
            }

            &:focus {
                box-shadow: none;
            }
        }
        .card-header {
            background-color: #ffffff;
            padding: 0;
            border: none;
        }
        .form-group {
            margin-bottom: 0.5rem;
        }
        .form-check-label {
            font-size: 0.9rem;
        }
    }
}

.products__orderby {
    select {
        border: none;
        background-color: transparent;
        color: $color-text;
        font-size: 0.9rem;
        font-weight: 600;
    }
}

.products__filter {
    text-align: right;
    a {
        color: $color-text;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .products__filter {
        text-align: left;
    }
}
