.product-block {
    // 3 produtos por linha
    &--3 {
        .product {
            .product__photo {
                .product__photo--link {
                    height: 250px;
                }
            }
            .product__buttons {
                a {
                    width: 70%;
                    margin: 0 auto;
                }
            }
        }
    }

    // 6 produtos por linha
    &--6 {
        .product {
            .product__photo {
                .product__photo--link {
                    height: 150px;
                }
            }
            .product__name {
                .product__name--link {
                    font-size: 0.9rem;
                }
            }
            .product__price {
                font-size: 0.75rem;
            }
        }
    }
}

.card-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    height: 100%;
    width: calc(99% - 3px);
}

.product {
    border: none;
    border-radius: 0;
    background-color: #ffffff;
    margin-top: 0.5rem;
    position: relative;
    transition: all 0.3s ease;

    height: 100%;
    width: 100%;
    flex: 1;

    &:hover {
        box-shadow: $box-shadow-light;
        -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        .product__buttons {
            display: flex;
            bottom: 15px;
        }

        img {
            -moz-transform: scale(1.1, 1.1);
            -ms-transform: scale(1.1, 1.1);
            -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
            backface-visibility: hidden;
            transform-origin: center center;
        }
    }

    .product__photo {
        min-height: 10rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .product__photo--link {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
            width: 100%;
        }

        .product__photo--content {
            height: 100%;
            padding: 0.5rem 0.5rem 1rem;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 100%;
                width: auto;
                -webkit-transition: all 0.25s
                    cubic-bezier(0.25, 0.46, 0.45, 0.94);
                transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
        }
    }

    .product__content {
        padding: 0 1rem 1rem;
        -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        .produto-estoque {
            font-weight: bold;
            font-size: 0.85rem;

            .produto-em-estoque {
                color: $color-success-text;
            }
            .produto-fora-estoque {
                color: $color-text-light;
            }
            .produto-sob-consulta {
                color: $color-primary;
            }
        }
    }

    .product__name {
        line-height: 1.2;
        margin-bottom: 1rem;
        overflow: hidden;
        height: 2.5rem;

        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        .product__name--link {
            color: $color-text;
            text-decoration: none;
            font-weight: 600;
            font-size: 1rem;
        }
    }

    .product__price {
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;

        min-height: 22px;

        .product-pre,
        .product-pos {
            font-weight: 100;
            font-size: 0.75rem;
        }
    }
    .product__buttons {
        display: none;
        position: absolute;
        bottom: 30px;
        right: 15px;
        left: 15px;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
        );
        padding-top: 1.5rem;

        -webkit-transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
}

.product__image-placeholder {
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 40px;

    svg {
        width: 30%;
        color: #aaa;
    }
    &.thumb-sm {
        svg {
            width: 100%;
            padding: 1rem 0.5rem;
            color: #999;
            background-color: #f9f9f9;
        }
    }
    &.thumb-md {
        svg {
            width: 100%;
            padding: 1rem 1.5rem;
            color: #999;
            background-color: #f9f9f9;
        }
    }
}

.product__tag {
    color: #fff;
    background-color: $color-offer;
    border-radius: 3rem;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 3px 7px;
    font-weight: bold;
    font-size: 0.65rem;
    text-transform: uppercase;
    z-index: 1;
}

.price--original {
    font-size: 75%;
    text-decoration: line-through;
    color: #757575;
    margin-right: 0.5rem;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .product-block {
        // 3 produtos por linha
        &--3,
        &--6 {
            .product {
                .product__photo {
                    .product__photo--link {
                        height: auto;
                    }
                }
                .product__buttons {
                    a {
                        width: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }

        // 6 produtos por linha
        &--6 {
            .product {
                .product__name {
                    .product__name--link {
                        font-size: 0.9rem;
                    }
                }
                .product__price {
                    font-size: 0.75rem;
                }
            }
        }
    }

    .product {
        flex-direction: row;
        margin: 0;
        border-bottom: 1px solid #f7f7f7;

        .product__content {
            width: 63vw;
            padding: 1rem;

            .produto-estoque {
                margin-bottom: 0.3rem;
            }
        }

        .product__buttons {
            position: relative;
            display: flex;
            left: 0;
            top: 0;
            padding-top: 1rem;
            .btn-rent {
                font-size: 0.8rem;
                width: 100%;
            }
        }

        .product__photo {
            min-height: auto;
            max-width: 25vw;
            width: 100px;

            .product__photo--link {
                height: auto;
                padding: 0;
            }

            .product__image-placeholder {
                height: 60px;
                svg {
                    width: 70%;
                }
            }
        }
        .product__tag {
            top: 5px;
            right: 15px;
            font-size: 0.7rem;
        }
        .product__price {
            min-height: auto;
        }
    }
    .product__tag {
        top: 0;
        right: 15px;
    }
    .card-product {
        width: 100%;
    }
}
