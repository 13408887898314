.product-details {
    background-color: #fff;

    hr {
        margin: 2rem 0;
    }

    &.product-info {
        padding: 2rem 0;
    }
    .product-details__more-info {
        padding-bottom: 2rem;
    }

    .product-details__title {
        font-size: 2rem;
        font-weight: 500;
    }

    .product-gallery-block {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40vh;
        text-align: center;

        .product__image-placeholder {
            background-color: #fbfbfb;
            min-height: 40vh;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                color: #bbb;
                width: 150px;
            }
        }
    }

    .product-details__gallery {
        width: 100%;
        align-self: flex-start;

        .product-gallery {
            min-height: 50vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &:hover {
                .carousel-control-next,
                .carousel-control-prev {
                    display: flex;
                }
            }

            .carousel-inner {
                height: 50vh;
                display: flex;
                align-items: center;
                border: 1px solid #f7f9fa;
                text-align: center;
                width: 100%;
            }

            .carousel-item {
                padding: 0 3rem;
            }
            .carousel-indicators {
                position: relative;
                li {
                    height: 50px;
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                }
            }
            .carousel-control-next,
            .carousel-control-prev {
                color: #333;
                border-radius: 50px;
                width: 50px;
                height: 100%;
                top: 0;
                display: none;
            }

            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                width: 26px;
                height: 26px;
                background-image: none;
            }
        }
    }

    .img-zoom {
        overflow: hidden;
        width: 100%;

        img {
            max-width: 100%;
            max-height: 50vh;
        }
    }

    .product-details__price {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 1rem;

        .product-pre,
        .product-pos {
            font-weight: 100;
            font-size: 80%;
        }
    }

    .produto-estoque {
        font-weight: bold;
        font-size: 0.85rem;

        .produto-em-estoque {
            color: $color-success-text;
        }
        .produto-fora-estoque {
            color: $color-text-light;
        }
        .produto-sob-consulta {
            color: $color-primary;
        }
    }

    .product-details__buttons {
        margin: 1rem 0;

        .select-date {
            margin-bottom: 0;

            .form-control[readonly] {
                background-color: #ffffff;
                &:hover {
                    cursor: pointer;
                    background-color: #f4f4f4;
                }
            }
        }
    }
    .product-details__label {
        font-weight: 100;

        color: #717171;
        font-size: 0.9rem;
    }

    .product__code,
    .product__categories,
    .product__brand {
        font-size: 0.9rem;
        a {
            color: $color-text;
            margin-right: 0.5rem;
            font-weight: bold;

            &:hover {
                text-decoration: none;
                opacity: 0.8;
            }
        }
        .brand__thumb {
            max-width: 100px;
        }
    }
    .product__lojista-link {
        color: $color-text;
        font-weight: bold;
    }

    .product-details__extra-info {
        .attribute {
            margin: 0.5rem 0;
            .attribute__key {
                text-transform: uppercase;
            }
            .attribute__value {
                color: $color-text-light;
            }
        }
    }

    .product-details__tag {
        color: #fff;
        background-color: $color-offer;
        border-radius: 3rem;
        padding: 3px 7px;
        font-weight: bold;
        font-size: 0.7rem;
        width: auto;
        max-width: 60px;
        display: flex;
        animation: c;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    .product__faixas {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 1rem;

        select {
            min-width: 240px;
            padding: 0.375rem 3rem 0.375rem 0.75rem;
            height: 38px;
        }

        &.faixas__dropdown {
            flex-direction: row;
            align-items: center;
        }

        .faixas__mini-bloco {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 0.5rem;
            flex-wrap: wrap;
            position: relative;

            .mini-bloco {
                position: relative;
                background-color: #ffffff;
                font-weight: bold;
                text-align: center;
                margin-right: 1rem;
                margin-bottom: 0.3rem;
                border: 1px solid #ccc;
                border-radius: 50px;
                cursor: pointer;

                min-width: 100px;

                &:hover {
                    opacity: 0.8;
                }

                &.is-invalid {
                    border-color: $color-error-dark;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }

                label {
                    padding: 0.5rem 0.8rem;
                    margin-bottom: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                .tag-desconto {
                    font-size: 0.65rem;
                    background-color: var(--color-primary);
                    color: #ffffff;
                    position: absolute;
                    border-radius: 50px;
                    top: -16px;
                    right: -12px;
                    padding: 7px 3px;
                    min-width: 29px;
                }

                &.option-selected {
                    background-color: var(--color-primary);
                    color: #ffffff;
                }
            }
        }
    }

    .product__accessory {
        padding-bottom: 0.75rem;
        max-width: 80%;

        .custom-switch {
            margin-bottom: 0.5rem;
        }
        .custom-control-label {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 70%;
            padding-top: 0.25rem;

            .with-badge {
                display: flex;
                flex-direction: row;
            }

            .name {
                padding-left: 1.5rem;
            }

            .price {
                min-width: 100px;
                margin-left: 0.5rem;
                font-weight: bold;
                text-align: right;
            }

            &::before {
                width: 3rem;
                height: 1.5rem;
                border-radius: 3rem;
            }

            &::after {
                width: calc(1.5rem - 4px);
                height: calc(1.5rem - 4px);
                border-radius: 3rem;
            }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            transform: translateX(1.5rem);
        }

        .with-badge {
            .badge {
                float: right;
            }
        }

        .name {
            padding-left: 0.65rem;
            display: flex;
            align-items: center;
        }

        .price {
            min-width: 100px;
            margin-left: 0.5rem;
            font-weight: bold;
            text-align: right;
        }
    }

    .product__hide-price {
        font-weight: bold;
        margin-top: 1rem;
        padding: 0.85rem 1.25rem;
        display: inline-block;
        background-color: #f2f8fd;
        border: 1px solid #cddff2;
        border-radius: 0.5rem;
    }

    .alert-tempo-minimo {
        color: $color-primary;
        background-color: #ffffff;
        font-weight: bold;
        margin-bottom: 1rem;
        border-radius: 0.25rem;
        display: block;
        position: relative;
        font-size: 0.9rem;
    }

    .bloco-data-devolucao {
        font-weight: bold;
        color: green;
    }

    .product__shipping {
        .shipping__form {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            label {
                margin-bottom: 0;
            }

            .form-input {
                display: flex;
            }
        }
        .shipping__results {
            border: 1px solid #e5e5e5;
            padding: 0.5rem;
            border-radius: 8px;
            margin-top: 1rem;
            font-size: 0.9rem;

            .result__loading {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
            }

            .result {
                margin: 0.5rem;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid #ddd;
                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                &__price {
                    font-weight: bold;
                    color: $color-success-text;
                }
                &__deadline {
                    font-weight: bold;
                }
            }
        }
    }

    .pedido-assinatura {
        .form-check {
            padding-left: 0;
            position: relative;
            .form-check-label {
                font-weight: bold;
                cursor: pointer;
                border: 2px solid #ddd;
                border-radius: 5px;
                padding: 1rem 1.5rem 1.5rem;
                width: 70%;
                color: #666;

                display: flex;
                align-items: center;

                .descricao {
                    font-weight: 100;
                }

                &:hover {
                    opacity: 0.8;
                }
                .selected {
                    display: none;
                }
                .option {
                    &::before {
                        border: 2px solid #ccc;
                        content: "";
                        height: 24px;
                        width: 24px;
                        border-radius: 50px;
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
                .price {
                    font-size: 1.15rem;
                    font-weight: bold;
                    &--original {
                        color: inherit;
                    }
                }
            }
            svg {
                border-color: #ccc;
                color: #ccc;
            }
            input {
                opacity: 0;
                position: absolute;
                left: 46px;
                top: 35px;
                width: 24px;
                height: 24px;

                &:checked + label {
                    color: var(--color-primary);
                    border-color: var(--color-primary);
                    background-color: rgba(
                        var(--color-primary-r),
                        var(--color-primary-g),
                        var(--color-primary-b),
                        0.05
                    );
                    .option {
                        display: none;
                    }
                    .selected {
                        display: block;
                    }
                    svg {
                        color: var(--color-primary);
                        border-color: var(--color-primary);
                    }
                }
            }
        }
    }
}

/* Modal - Galeria de fotos */

.product-carousel-modal {
    padding-right: 0 !important;
    z-index: 9999999999; //10002;
    overflow: hidden !important;

    .modal-dialog {
        height: 100vh;
        max-width: 100vw;
        margin: 0;
        padding: 0;

        .modal-content {
            height: 100%;
            width: 100%;
            border-radius: 0;
            border: none;
            background-color: rgba(255, 255, 255, 0.96);

            .modal-body {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .product-details__gallery {
        .product-gallery {
            height: 90vh;
            width: 100vw;

            .carousel-inner {
                height: 100%;
                border: none;
                width: 90vw;
            }
            .carousel-item {
                padding: 0;
                margin: 0 auto;
                text-align: center;

                img {
                    max-width: 900px;
                    max-height: 90vh;
                }
            }
            .carousel-control-prev,
            .carousel-control-next {
                width: 100px;
            }
        }
    }
}

/* Tipo de Produto - Spot */
.product-spot {
    margin-bottom: 1rem;
    .product-spot__total {
        font-weight: bold;
        font-size: 1.5rem;
        margin-top: 1rem;
    }
    .alert {
        padding: 7px 20px;
    }
}

/* Produtos Relacionados */
.products__related {
    padding-bottom: 2rem;
}

@media (max-width: $breakpoint-lg) {
    .product-details {
        .product-details__title {
            font-size: 1.5rem;
        }
        .product-gallery-block {
            align-items: flex-start;
        }
        .product-details__gallery {
            .product-gallery {
                min-height: 30vh;
                .carousel-inner {
                    height: 30vh;
                }
            }
        }

        .product__accessory {
            max-width: 100%;
            .custom-control-label {
                width: 100%;
                padding-top: 4px;
                .with-badge {
                    flex-direction: column;
                }
            }

            .custom-checkbox {
                flex-direction: column;
                align-items: stretch;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                left: 0;

                .name {
                    padding-left: 0.5rem;
                }
                .custom-checkbox__label--svg {
                    &:before {
                        width: 32px;
                        height: 32px;
                    }
                    svg {
                        left: 7px;
                    }
                }

                .with-badge {
                    .price {
                        position: absolute;
                        bottom: 5px;
                        right: 0;
                    }
                }
            }
        }

        .product-carousel-modal {
            .product-gallery {
                height: 70vh;
                .carousel-inner {
                    height: auto;
                }
            }
        }

        .product-details__buttons {
            .btn-rent {
                margin-top: 1rem;
            }
        }

        .product__faixas {
            .faixas__mini-bloco {
                .mini-bloco {
                    margin-top: 1rem;
                }
            }
            &.faixas__dropdown {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .product-details {
        padding: 0 0 2rem 0;

        .product-details__gallery {
            .product-gallery {
                justify-content: center;
                min-height: auto;

                .carousel-inner {
                    height: 40vh;
                }
            }
        }

        .product-details__title {
            font-size: 1.25rem;
            margin-top: 1rem;
        }

        .product-details__buttons {
            .select-date {
                margin-bottom: 0;
            }
            .qtd-label {
                margin-top: 1rem;
            }
        }

        .product-details__tag {
            margin-top: 0.5rem;
        }

        .product__shipping {
            .shipping__form {
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
            }
            .shipping__results {
                font-size: 0.8rem;
            }
        }
        .pedido-assinatura {
            .form-check {
                .form-check-label {
                    width: 100%;
                }
            }
        }
    }
    .product-scheduled {
        .btn-rent {
            margin-top: 1rem;
        }
    }

    .product-carousel-modal {
        .product-details__gallery {
            width: auto;
            .product-gallery {
                height: 80vh;
                .carousel-inner {
                    height: 80vh;
                }
                .carousel-item {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
