.contact__page {
    background-color: #fff;
    .map-block {
        display: flex;
        margin-top: 3rem;
        background-color: #eee;
        margin-bottom: 0;
        border-radius: 1rem;
        overflow: hidden;
    }
}

// Mapa
.map__page {
    .map-block {
        overflow: hidden;
        border-radius: 1rem;
        max-width: 100%;
        margin-bottom: 3rem;

        iframe {
            overflow: hidden;
            max-width: 100%;
        }
    }
}
