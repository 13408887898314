.navbar--block {
    padding: 0.5rem 0;
    background-color: var(--color-bg-navbar);
    color: var(--color-text-navbar);

    &.fixed-height {
        margin-top: $fixed-height;
    }

    a,
    button {
        color: var(--color-text-navbar);
        background-color: var(--color-bg-navbar);
        font-size: 0.93rem;
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;

        width: 100%;
        font-weight: bold;

        .link-allcategories {
            white-space: nowrap;
        }

        .nav-link {
            padding: 0.5rem 0.4rem;
            &:hover {
                background-color: rgba(
                    var(--color-text-navbar-r),
                    var(--color-text-navbar-g),
                    var(--color-text-navbar-b),
                    0.2
                );
                color: var(--color-text-navbar);
                opacity: 0.8;
                border-radius: 3px;
            }
        }
    }

    .dropdown-menu {
        @extend .dropdown-menu-custom;

        background-color: var(--color-bg-navbar);
        color: var(--color-text-navbar);
        min-width: 20rem;
        padding: 1rem 0;

        .nav-link {
            padding: 0.5rem 1rem;

            &:hover {
                background-color: rgba(
                    var(--color-text-navbar-r),
                    var(--color-text-navbar-g),
                    var(--color-text-navbar-b),
                    0.2
                );
                color: var(--color-text-navbar);
                opacity: 0.8;
            }
        }
        /* Menu Horizontal - Mais que 15 categorias */
        .list-many-categories {
            -webkit-column-count: 3;
            column-count: 3;
            -webkit-column-fill: auto;
            column-fill: auto;
            height: 100%;
            width: 100%;
            min-width: 50vw;
            box-sizing: border-box;
            overflow: visible;
        }
        /* Mais que 35 categorias */
        .list-many-categories--large {
            -webkit-column-fill: auto;
            column-fill: auto;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            overflow: visible;
            -webkit-column-count: 4;
            column-count: 4;
            min-width: 65vw;
        }
    }

    .btn-dropdown {
        color: var(--color-text-navbar);
    }
}

.nav-link {
    &:hover {
        opacity: 0.8;
    }

    &.subcategory__link {
        padding-left: 2rem !important;
        font-weight: 100;
        font-size: 90%;
        background-color: var(--color-bg-navbar);
    }


    &__show-all {
        border-top: 1px solid
            rgba(
                var(--color-text-navbar-r),
                var(--color-text-navbar-g),
                var(--color-text-navbar-b),
                0.1
            );
        padding-top: 0.5rem;
        margin-top: 0.5rem;
    }
}

.menu-list {
    @extend .clean-list;

    .nav-link {
        padding: 0.3rem 0;
        &:hover {
            opacity: 0.8;
        }
    }
}

/* Desktop - Submenu com Hover animado */
@media all and (min-width: 992px) {
    .custom-hover-dropdown {
        .dropdown-menu {
            opacity: 0;
            visibility: hidden;
            transition: 0.1s;
            margin-top: 0;
            display: none;
            top: 80%;
            transform: rotateX(-75deg);
            transform-origin: 0% 0%;

            li {
                position: relative;

                &:hover > .submenu {
                    display: block;
                }
            }
        }

        &:hover {
            position: relative;

            .dropdown-menu {
                display: block;
                position: absolute;
                transition: 0.1s;
                opacity: 1;
                visibility: visible;
                top: 100%;
                transform: rotateX(0deg);
            }
            .icon-arrow-down {
                svg {
                    transition: all 0.5s ease;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }
        .icon-arrow-right {
            svg {
                width: 6px;
                float: right;
            }
        }

        .subcategory {
            position: relative;
            .submenu {
                display: none;
                position: absolute;
                transition: 0.1s;
                opacity: 1;
                visibility: visible;
                left: 100%;
                top: 0px;
                transform: rotateX(0deg);
            }
        }
    }
}

// Icones
.menu-icon {
    width: 32px;
    max-height: 26px;

    &.header__icon {
        rect {
            fill: var(--color-bg-header);
        }
    }
}

@media (max-width: $breakpoint-md) {
    .navbar--block {
        .navbar-nav {
            .nav-link {
                padding: 0.5rem;
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .navbar--block {
        display: none;
    }
    .nav-link {
        &.subcategory__link {
            background-color: #fff;
        }
    }

    .menu-icon {
        &.header__icon {
            rect {
                fill: #fff;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .navbar--block {
        display: none;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .navbar--block {
        display: none;
    }
    .custom-hover-dropdown {
        .dropdown-menu {
            display: block;
            opacity: 1;
            border: none;
            padding: 0;
            position: relative;
            width: 100%;
        }
        .btn-dropdown {
            font-weight: 400;
            text-align: left;
        }
    }
}

// XXL - Extra Large
@media (min-width: $breakpoint-xxl) {
    .navbar--block {
        .navbar-nav {
            .nav-link {
                padding: 0.5rem 0.7rem;
            }
        }
    }
}
