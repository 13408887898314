.checkout__steps {
    background-color: var(--color-bg-header);
    width: 100%;

    .progress-bar__bg {
        background-color: var(--color-text-header);
        height: 2px;
        width: 85%;
        position: relative;
        bottom: 10px;
        margin: 0 auto;
        opacity: 0.5;
    }

    .steps {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 0;

        .step {
            text-align: center;
            opacity: 0.5;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .step__number {
                color: var(--color-text-header);

                line-height: 1.7;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
            }

            svg {
                display: none;
            }

            &.done {
                .step__icon {
                    background-color: transparent;
                    border: none;
                    svg {
                        width: 30px;
                        height: 30px;
                        display: block;
                    }
                }
                .step__number {
                    display: none;
                }
            }

            &.current {
                opacity: 1;

                .step__icon {
                    background-color: var(--color-text-header);
                }
                .step__number {
                    display: block;
                    color: var(--color-bg-header);
                }
            }
        }

        .step__line {
            background-color: var(--color-text-header);
            height: 2px;
            width: 100%;
            opacity: 0.5;
        }

        .step__icon {
            border-radius: 50px;
            width: 30px;
            height: 30px;
            background-color: transparent;
            border: 2px solid var(--color-text-header);
        }
    }

    .step__link {
        color: var(--color-text-header);
        text-transform: uppercase;
        font-size: 0.75rem;
        margin-top: 0.35rem;
        font-weight: bold;
    }
}

.checkout__page {
    @extend .my-account;

    .checkout__container {
        padding-bottom: 3rem;
    }
    .link-login {
        text-align: right;
        margin-bottom: 0.5rem;
    }

    .checkout__question {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .last-step__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    /* Tipos de Pagamento */
    .payment-methods {
        display: flex;
        flex-wrap: nowrap;
        text-align: center;
        justify-content: flex-start;
        flex: 1;
        width: 100%;

        .payment-type-content {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: inherit;

            svg {
                margin-bottom: 1rem;
                height: 26px;
                color: #666;
            }
        }

        .card-selected {
            svg {
                color: $color-success-darker;
            }
        }

        .card-custom-radio {
            flex: 1;
            margin-right: 0.5rem;
            max-width: 48%;
            .form-check {
                height: 100%;
            }

            .custom-radio {
                padding-left: 0;
                height: 100%;
            }
        }
    }

    .checkout__footer {
        padding: 1rem 0;
    }

    /* Retirada */
    .locais-retirada {
        margin-top: 0.5rem;
        border-radius: 10px;
        .select-date {
            input {
                width: auto;
                min-width: 200px;
            }

            .form-control[readonly] {
                background-color: #ffffff;
                &:hover {
                    cursor: pointer;
                    background-color: #f4f4f4;
                }
            }
        }
        &__opcoes {
            ul {
                list-style: none;
                padding-left: 0;
            }
        }
        .local-name {
            font-weight: bold;
        }
        .custom-radio {
            position: relative;
            padding-left: 2.5rem;
        }
        .icon-selected {
            position: relative;
            left: -10px;
        }

        .radio-checkmark {
            left: 10px;
        }
        .card-selected {
            .local-name {
                color: $color-success-darker;
            }
        }
    }

    /* Iugu */
    .custom-creditcard {
        width: 380px;
        position: relative;
        margin: 1rem 0 0 0;

        .input-invalid-value {
            border-color: red !important;
            color: red;
        }
        .input-valid-value {
            border-color: green !important;
            color: green;
        }

        .usable-creditcard-form {
            margin-left: 0;
            width: 100%;
            position: relative;

            input {
                background-color: #fff;
                outline: none;
                font-size: 1rem;
            }

            .wrapper {
                border: none;
                width: 100%;
                height: auto;
            }
            .input-group {
                .icon {
                    top: 12px;
                }
                input {
                    border-radius: 3rem !important;
                }

                /* Numero do cartao */
                &.nmb_a {
                    width: auto;
                    position: relative;
                    input {
                        margin-bottom: 1rem;
                        border: 1px solid #ced4da;
                    }
                }
                /* CVV */
                &.nmb_b {
                    position: relative;
                    top: auto;
                    width: auto;
                    input {
                        text-align: left;
                        border: 1px solid #ced4da;
                        border-radius: 3rem;
                        margin-bottom: 1rem;
                    }
                }

                /* Titular do Cartão */
                &.nmb_c {
                    position: relative;
                    top: auto;
                    width: auto;
                    input {
                        border: 1px solid #ced4da;
                        border-radius: 3rem;
                        margin-bottom: 1rem;
                    }
                }

                /* Data vencimento */
                &.nmb_d {
                    position: relative;
                    top: auto;
                    width: auto;
                    input {
                        text-align: left;
                        border: 1px solid #ced4da;
                        border-radius: 3rem;
                        margin-bottom: 1rem;
                    }
                }

                /* Numero de Parcelas */
                &.nmb_np {
                    position: relative;
                    top: auto;
                    width: auto;
                    select {
                        text-align: left;
                        border: 1px solid #ced4da;
                        border-radius: 3rem;
                        margin-bottom: 1rem;
                        padding: 0.625rem 1rem;
                        height: auto;
                        width: 100%;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .checkout__resumo {
        padding: 1rem;
        margin-bottom: 1rem;
        border: none;
        box-shadow: $box-shadow-light;
        background-color: #fff;

        .list-products {
            border-bottom: 1px solid #ccc;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            li {
                border: none;
                padding: 0.1rem 0;
                font-size: 0.8rem;

                .product__name {
                    max-width: 60%;
                    color: $color-text;
                }
            }
        }

        &--detailed {
            @extend .checkout__resumo;

            box-shadow: none;
            padding: 0;

            .list-products {
                display: flex;
                li {
                    align-items: flex-start;
                    justify-content: space-between;
                    display: flex;
                    font-size: 0.9rem;

                    border-bottom: 1px solid $color-bg-light;
                    padding-bottom: 0.5rem;
                    margin-bottom: 0.5rem;

                    &:last-child {
                        border-bottom: none;
                    }

                    .product__info {
                        display: flex;
                        flex-direction: row;
                    }
                }
            }

            .product__photo {
                margin-right: 1rem;
                width: 110px;

                img {
                    max-width: 100px;
                    width: auto;
                    max-height: 100px;
                    height: auto;
                }
            }
            .product__name {
                font-weight: bold;
            }
        }

        &--orcamento {
            @extend .checkout__resumo;

            .list-products {
                border-bottom: none;
                img {
                    width: 50px;
                    margin-right: 0.5rem;
                }
                li {
                    .product__name {
                        max-width: 100%;
                    }

                    border-bottom: 1px solid $color-bg-light;
                    padding: 0.5rem 0;
                    &:last-child {
                        border-bottom: none;
                    }
                    .product__photo--content {
                        display: inline-block;
                        .product__image-placeholder {
                            width: 50px;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }

        .title {
            font-weight: bold;
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }

        .list-group-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            padding: 0;
        }

        .total {
            font-weight: bold;
            font-size: 1.25rem;
        }

        .checkout__buttons {
            margin: 1rem 0 0.5rem;
        }
    }

    .checkout__buttons {
        &--steps {
            text-align: center;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            .btn-success {
                padding: 0.5rem 2rem;
                margin-left: 0.5rem;
            }

            .btn-block + .btn-block {
                margin-top: 0;
                margin-left: 0.5rem;
            }

            &.last-step {
                float: right;
            }
        }
    }

    .checkout__confirm {
        .card {
            height: 100%;
        }
        .card-header {
            color: $color-text;

            text-transform: uppercase;
            font-weight: bold;
            font-size: 1rem;
            margin-bottom: 0;

            display: flex;
            align-items: center;

            svg {
                height: 20px;
                width: auto;
                margin-right: 10px;
            }
        }

        .card-footer {
            background-color: $color-bg-light;
            padding: 0.5rem;
        }
        .user-details {
            margin-bottom: 2rem;
            p {
                margin-bottom: 0.3rem;
            }
        }
        .alert__custom-message {
            margin-bottom: 0;
            font-size: 1rem;
        }
    }

    .checkout__radio {
        margin-bottom: 1rem;
        padding: 0;

        label {
            cursor: pointer;
            width: 100%;
            padding: 1rem;
        }

        input:checked + label {
            span {
                color: $color-success-darker;
            }
        }

        span {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.9rem;
        }

        &.shipping {
            label {
                padding: 0.5rem;
                margin-bottom: 0;
            }
            .radio-checkmark {
                top: 0.6rem;
            }
            span {
                margin-right: 1rem;
                text-transform: none;
            }
            .deadline {
                color: #666;
                float: right;
            }

            .custom-radio--info {
                float: right;
                margin: 0;
            }
            .form-check {
                .logo {
                    max-width: 80px;
                    margin-right: 0.5rem;
                    display: block;
                }
            }

            &.card-selected {
                .deadline {
                    color: $color-success-darker;
                }
                .icon-selected {
                    svg {
                        top: 10px;
                    }
                }
            }
        }
    }

    /* Checkout - Validacao Facial */
    .face-validation {
        .camera-block {
            background-color: #333;
            &::after {
                content: "";
                background: url("../../../../../img/templates/1/mask-face.png");
                height: 300px;
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: center;
            }

            position: relative;
            text-align: center;
            height: 300px !important;
            background-color: bisque;

            video {
                height: 300px !important;
                width: 300px !important;
                background-color: #fff;
            }
            background-color: #fff;
        }
        .img-face {
            max-width: 300px;
        }
        canvas {
            height: 300px !important;
            width: 300px !important;
        }
    }
}

// Pix

.pix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    width: auto;
    max-width: 300px;
    margin: 0 auto;

    img {
        border: 2px solid #ddd;
        width: 200px;
        height: 200px;
    }
}
.pix-link {
    border: 2px solid #ddd;
    max-width: 200px;
    margin-top: 1rem;
    &:hover {
        background-color: #ddd !important;
    }
}

@media (min-width: $breakpoint-md) {
    .checkout__steps {
        .steps {
            .step {
                font-size: 0.75rem;
            }
        }
    }
}

@media (max-width: $breakpoint-xl) {
    .checkout__page {
        .link-login {
            margin-bottom: 1rem;
        }
        .checkout__resumo {
            //margin-top: 2rem;

            .list-group-item {
                font-size: 0.9rem;
            }
        }
    }
}
@media (max-width: $breakpoint-lg) {
    .checkout__page {
        .checkout__container {
            padding-bottom: 0;
        }
        .checkout__confirm {
            .card {
                margin-top: 1rem;
                height: auto;
            }
        }
        .checkout__resumo {
            margin-top: 2rem;

            .list-group-item {
                font-size: 0.9rem;
            }
        }

        .link-login {
            margin-bottom: 2rem;
            text-align: center;
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .checkout__steps {
        .steps {
            padding-left: 0;
            padding-top: 0.5rem;

            .step {
                font-size: 0.6rem;
                font-weight: bold;
                .step__link {
                    font-size: 0.7rem;
                    text-transform: capitalize;
                }
                .step__number {
                    font-size: 0.75rem;
                    line-height: 1.5;
                    text-align: center;
                }
                &.current {
                    display: flex;

                    .step__link {
                        text-align: center;
                    }
                }
                .step__icon {
                    width: 22px;
                    height: 22px;
                    margin-bottom: 0.3rem;
                }
                &.done {
                    .step__icon {
                        svg {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }
        .progress-bar__content {
            width: 85vw;
        }
    }

    .checkout__page {
        .checkout__container {
            padding-top: 0;
            padding-bottom: 0;
        }

        .custom-creditcard {
            width: 100%;
            padding: 0;

            .usable-creditcard-form {
                input {
                    font-size: 0.9rem;
                }
            }
        }

        .payment-methods {
            flex-direction: column;
            .card-custom-radio {
                margin: 0;
                flex: 1;
                width: 100%;
                max-width: 100%;
                margin-bottom: 0.5rem;
                .payment-type-content {
                    align-items: center;
                    margin-left: 1rem;
                    flex-direction: row;
                    justify-content: flex-start;
                    text-align: left;
                    svg {
                        margin-right: 0.5rem;
                        width: 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .link-login {
            margin-bottom: 2rem;
            text-align: center;
        }
        .last-step__title {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 1rem;

            .btn {
                margin-top: 1rem;
            }
        }
        .checkout__resumo {
            margin-top: 2rem;

            .list-products {
                li {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    margin-bottom: 0.5rem;

                    .product__name {
                        max-width: 100%;
                        font-size: 0.9rem;
                    }
                }
            }
        }
        .checkout__buttons {
            &--steps {
                flex-direction: column-reverse;
                .btn {
                    width: 100%;
                    font-size: 1.1rem;
                    margin-left: 0;
                }
                .btn-go-back {
                    text-transform: lowercase;
                }
            }
        }

        .checkout__radio {
            label {
                display: flex;
                flex-direction: column;
            }

            &.shipping {
                .custom-radio--info {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }
        }

        .face-validation {
            .alert {
                padding: 0.5rem 3rem 0.5rem 1rem;
                ol {
                    padding-left: 5px;
                }
            }
            canvas {
                height: auto !important;
            }
            .checkout__buttons--steps {
                a {
                    margin-top: 0.5rem;
                }
            }
        }
    }
}
