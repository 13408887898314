.faq__page {
    background-color: #ffffff;
    padding-bottom: 4rem;
    a {
        color: $color-text;
        &:hover {
            color: #666666;
        }
    }
    .faq-link-todas-perguntas {
        color: #666666;
        a {
            color: #666666;
        }
    }
}
.faq-block {
    margin-bottom: 3rem;
    background-color: #ffffff;

    .faq {
        margin-bottom: 3rem;
    }

    .faq-title {
        font-weight: bold;
        font-size: 1.4rem;
        color: #666666;
    }

    .card {
        background-color: #ffffff;
        border: none;

        .card-header {
            color: #333;
            border: none;
            background-color: #ffffff;
            padding: 0;

            button {
                color: #666666;
                font-weight: 400;
                font-size: 1.25rem;
                padding: 0.75rem 0;
                text-decoration: none;
                text-align: left;
            }
            button:focus {
                box-shadow: none;
                text-decoration: none;
            }
            button:not(.collapsed) {
                font-weight: bold;
                color: #555555;
                .accordion-icon {
                    background-color: var(--color-primary);
                    color: #ffffff;
                    border-color: var(--color-primary);
                }
                .accordion-icon path {
                    transform: rotate(315deg);
                    transform-origin: center;
                    transition: all 0.25s ease;
                }
            }
        }
    }

    /* Icone Accordion - Faq */
    .accordion-icon {
        display: inline-block;
        line-height: 1;
        background: #ffffff;
        border: 1px solid #b5b5b5;
        border-radius: 3px;
        margin-right: 5px;

        svg {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            padding: 0.5rem;
        }
    }
}

@media (max-width: 575.98px) {
    .faq-block .card .card-header button,
    .faq-block .card .card-footer {
        font-size: 1rem;
        text-align: left;
    }
}
