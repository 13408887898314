.topbar {
    background-color: var(--color-bg-topbar);
    color: var(--color-text-topbar);
    border-bottom: 1px solid rgb(221 221 221 / 54%);

    font-size: 0.85rem;

    width: 100%;
    z-index: 1020;

    height: $topbar-height;
    top: 0;

    a {
        color: var(--color-text-topbar);
    }

    .topbar__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 37px;
    }

    .topbar__menu {
        @extend .clean-list;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .topbar--full {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $topbar-height;

        font-size: 0.75rem;
        font-weight: bold;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .topbar {
        .topbar--full {
            font-size: 0.5rem;
            font-weight: 100;
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .topbar {
        display: none;
    }
}
