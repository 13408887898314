.banner-loading {
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-mobile {
    display: none;
}
.banner-desktop {
    display: block;
}
.banner-block {
    &.container {
        padding: auto;
    }
}

.carousel-indicators {
    li {
        box-sizing: content-box;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: 0.5;
        transition: opacity 0.6s ease;
    }
}
//Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .banner-block {
        &.container {
            padding: 0;
        }
    }
    .banner-mobile {
        display: block;
    }
    .banner-desktop {
        display: none;
    }

    .banner-tarja {
        .container {
            padding: 0 !important;
        }
    }
}
