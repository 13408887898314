.btn {
    font-weight: 600;
}
.btn-block {
    width: 100%;
    display: block;
}
.btn-link {
    color: $color-link-dark;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        opacity: 0.8;
    }
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled) {
        outline: none;
    }
}
.btn-success {
    background-color: $color-success-btn;
    border-color: $color-success-btn;
}
.btn-see-more {
    margin-top: 1rem;
    padding: 0.8rem 2.5rem;
    border-radius: 50px;
    font-family: var(--font-primary);

    transition: all 0.25s ease-in;
    transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    position: relative;

    .hidden-icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        opacity: 0;
        z-index: 1;
        -moz-transform: translate3d(-5px, -50%, 0);
        -webkit-transform: translate3d(-5px, -50%, 0);
        transform: translate3d(-5px, -50%, 0);
    }

    &:hover {
        padding-right: 3rem;
        .hidden-icon {
            display: inline;
            opacity: 1;
            -moz-transform: translate3d(0, -50%, 0);
            -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
        }
    }
}

.btn-show {
    @extend .btn-see-more;
    padding: 0.5rem 1rem;
    margin-top: 0;
}
.btn-go-back {
    text-align: initial;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: $color-text-medium;
}

.btn-dropdown {
    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.btn-rounded {
    border-radius: 2rem;
}

.btn-close {
    border-radius: 1rem;
    padding: 1rem 0.65rem;
}

.btn-outline-primary {
    background-color: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);

    &:hover {
        border-color: var(--color-primary) !important;
        background-color: rgba(
            var(--color-primary-r),
            var(--color-primary-g),
            var(--color-primary-b),
            1
        ) !important;
        color: #ffffff;
    }
    &:not(:disabled):not(.disabled):active {
        background-color: rgba(
            var(--color-primary-r),
            var(--color-primary-g),
            var(--color-primary-b),
            1
        );
        color: #ffffff;
    }
    &:focus {
        background-color: rgba(
            var(--color-primary-r),
            var(--color-primary-g),
            var(--color-primary-b),
            1
        );
        color: #ffffff;
    }
}

.btn-cancel {
    margin-top: 0;
}

.btn-custom-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #ffffff;

    &:hover {
        background-color: var(--color-primary) !important;
        color: #ffffff !important;
        box-shadow: $box-shadow;
    }
}
.btn-rent {
    // @extend .btn-primary;
    @extend .btn-rounded;

    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #ffffff;

    font-weight: bold;
    font-size: 1rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        border-color: var(--color-primary);
        color: #fff;
        background-color: rgba(
            var(--color-primary-r),
            var(--color-primary-g),
            var(--color-primary-b),
            1
        );
        box-shadow: $box-shadow;
        opacity: 1;

        --bs-btn-hover-color: #ffffff;
        --bs-btn-hover-bg: rgba(
            var(--color-primary-r),
            var(--color-primary-g),
            var(--color-primary-b),
            0.9
        );
    }
    &:active {
        border-color: var(--color-primary);
        color: #fff !important;
    }
    .icon-reload {
        display: none;
    }

    &.js-loading-btn {
        .icon-reload {
            display: flex;
            max-height: 24px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btn-label {
            display: none;
        }
    }

    &:not(:disabled):not(.disabled):active {
        background-color: rgba(
            var(--color-primary-r),
            var(--color-primary-g),
            var(--color-primary-b),
            0.98
        );
    }
    &:focus {
        background-color: rgba(
            var(--color-primary-r),
            var(--color-primary-g),
            var(--color-primary-b),
            0.95
        );
    }
    &:disabled {
        background-color: #ddd;
        border-color: #ccc;
        color: #777;
        &:hover {
            box-shadow: none;
        }
    }
}

.btn-buy {
    @extend .btn-rent;
}

.btn-filter {
    @extend .btn-rent;
}

.btn-show-filters {
    font-size: 0.9rem;
    border-radius: 2rem;

    svg {
        width: 16px;
    }
}

.btn-qtd {
    @extend .form-control;
    @extend .btn-rounded;

    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 2rem;
    color: #495057;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    display: flex;
    margin-right: 1rem;
    padding: 1px;
    justify-content: space-between;

    input {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 0 3px;
        width: calc(100% - 6px);
        text-align: center;
        outline: 0;
    }
    .btn {
        border-radius: 1rem;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        &:hover {
            background-color: #f4f4f4;
        }
    }
}
.btn-right-block {
    float: right;
}

.btn-download {
    @extend .btn-rent;

    font-size: 1rem;
    padding: 0.8rem 2.5rem;
    border-radius: 50px;
}
.btn-download-small {
    @extend .btn-download;

    padding: 0.5rem 1.5rem;
}

.btn-cep {
    @extend .btn-rent;

    overflow: visible;
    min-width: 51px;
}
.btn-rent-whatsapp {
    background-color: $color-whatsapp;
    border-color: $color-whatsapp;
    color: #fff;
    margin-top: 0.5rem;

    &:hover,
    &:active,
    &:focus {
        background-color: $color-whatsapp-dark !important;
        border-color: $color-whatsapp-dark !important;
    }
    &:not(:disabled):not(.disabled):active {
        background-color: $color-whatsapp;
    }
    &:disabled:hover {
        background-color: #ddd;
        border-color: #ccc;
        color: #777;
    }
}

.btn-ripple {
    background-position: center;
    transition: background 0.5s;
    &:hover {
        background: var(--success)
            radial-gradient(circle, transparent 1%, var(--success) 1%)
            center/15000%;
        border-color: green;
    }
    &:active {
        background-color: $color-success-btn;
        background-size: 100%;
        transition: background 0s;
    }
}

// CEP - retirar pessoalmente
.btn-ver-locais {
    font-size: 0.9rem;
    padding: 0;
    color: $color-link;
    line-height: 1;
}

.btn-login-facebook {
    @extend .btn-rent;
    background-color: $color-facebook;
    border-color: #385899;
    color: #fff !important;
    position: relative;
    svg {
        position: absolute;
        left: 30px;
    }
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: #052b75;
        border-color: #385899;
    }
}

.btn-login-google {
    @extend .btn-rent;
    background-color: #fff;
    border-color: #333 !important;
    position: relative;
    svg {
        position: absolute;
        left: 30px;
        height: 23px;
    }
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: #eeeeee !important;
        border-color: #eeeeee;
        color: #333 !important;
    }
}
/* WhatsApp */

.whatsapp-chat {
    position: relative;
    cursor: pointer;

    &__text {
        display: none;
    }

    &__link {
        position: fixed;
        transform: scaleX(1);
        transition: transform 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);

        right: 15px;
        left: auto;
        bottom: 15px;
        color: #fff;
        width: auto;
        height: auto;
        z-index: 10001;
        border-radius: 50px;
        padding: 0.8rem 1rem;
        border: none;

        font-size: 14px;
        text-align: center;
        text-shadow: 1px 1px 3px #29b43f;
        font-weight: bold;
        text-transform: uppercase;

        box-shadow: 1px 1px 3px #075e54;
        background-color: #29b43f;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 24px;
            height: 30px;
        }

        &:hover {
            text-decoration: none;
            background-color: #25d367;
            color: #fff;
            transition: all 0.5s;

            .whatsapp-chat__text {
                display: flex;
                margin-left: 0.5rem;
            }
        }
        &--right {
            right: 80px;
            left: auto;
        }
    }
}

.btn-cookies {
    padding: 0.5rem 1rem;
    background-color: #168330;
    border-color: #168330;
}

.back-to-top {
    position: relative;

    .back-to-top--link {
        position: fixed;
        bottom: 15px;
        right: 15px;
        background-color: rgba(255, 255, 255, 0.719);
        z-index: 10001;
        color: #333;
        padding: 0.8rem;
        border-radius: 50px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 3px #333;

        svg {
            transform: rotate(270deg);
        }

        &:hover {
            text-decoration: none;
            background-color: #dddddd;
            border-color: #333;
        }
    }
}

.btn-outline-degrade {
    background-color: #fff;
    border-color: var(--roxo);
    color: #333;
    padding: 0.625rem 3rem;
    border: double 3px transparent;
    border-radius: 0.75rem;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: linear-gradient(white, white),
        linear-gradient(to bottom, #f44eae, #a323db);

    &:hover {
        color: #444;
        .hidden-icon {
            display: none;
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .btn-qtd {
        .btn {
            padding: 5px;
        }
    }
    .btn-rent-whatsapp {
        margin-top: 0;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .whatsapp-chat {
        &__text {
            display: none;
        }
        &:hover {
            .whatsapp-text {
                display: none;
            }
        }
        &__link {
            border-radius: 50px;
            padding: 0.8rem 1rem;
            right: 15px;
            svg {
                margin-right: 0;
            }

            &--right {
                right: 15px;
                left: auto;
            }
        }
    }

    .btn-rent {
        font-size: 0.9rem;
        padding: 0.469rem 0.75rem;
    }

    .btn-show {
        width: 100%;
        margin-top: 1rem;
    }

    .btn-right-block {
        float: none;
        width: 100%;
    }
    .btn-go-back {
        text-align: center;
        margin-top: 1rem;
    }
    .btn-cancel {
        margin-top: 0.5rem;
        font-size: 0.9rem;
    }
    .btn-download {
        padding: 0.8rem 2.5rem;
    }
    .btn-download-small {
        padding: 0.75rem;
        display: block;
        width: 100%;
        margin: 1rem 0;
    }
    .btn-cookies {
        padding: 0.65rem 0.75rem;
    }

    /* Jivo chat */
    ._orientationLeft_fd90 .button_5fdd {
        margin-left: 15px;
        width: 56px;
        height: 56px;
    }
}
