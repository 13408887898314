.my-account {
    margin-bottom: 2rem;

    &__picture {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;

        .user-icon {
            background-color: #fff;
            border: 2px solid $color-text;
            border-radius: 50px;
            color: $color-text;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__user {
        margin-bottom: 2rem;
        color: $color-text;
        .user-name {
            font-weight: bold;
        }
    }

    .sub-title {
        margin-bottom: 2rem;
        font-size: 1.8rem;
        font-weight: bold;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .nav-pills {
        .nav-link {
            padding: 0.5rem 0;
            color: $color-text;
            width: fit-content;
            border-radius: 0;

            svg {
                margin-right: 0.5rem;
                width: 26px;
                max-height: 26px;
            }
            &.active {
                background-color: transparent;
                color: var(--color-primary);
                border-radius: 0;
                font-weight: bold;
            }

            &.logout-link {
                border-top: 1px solid #ddd;
                margin-top: 1rem;
                padding-top: 1.5rem;
            }
        }
    }

    .card {
        border: none;
        box-shadow: $box-shadow-light;

        .card-header {
            background-color: transparent;
            border-bottom: none;
            h3 {
                margin-bottom: 0;
                font-size: 1.25rem;
                font-weight: bold;
                padding-top: 0.5rem;
            }
        }
        .card-title {
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 0;
            padding-top: 1rem;
        }
        .card-footer {
            background-color: transparent;
            border-top: none;
        }
    }

    /* Pedidos */
    .orders__list {
        margin-bottom: 3rem;

        .card {
            border-left: 5px solid lightgray;

            &--paid {
                border-color: $color-success;
            }
            &--done {
                border-color: $color-primary;
            }
            &--devolucao {
                border-color: #e8198d;
            }
            &--canceled {
                border-color: $color-error;
            }
        }
        .card-header {
            font-size: 0.9rem;
        }
    }

    /* Pedidos - Detalhes */

    .order__details {
        /* Produtos */
        .order-item {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $color-bg-light;

            //display: flex;
            align-items: flex-start;
            justify-content: space-between;

            a {
                color: $color-text;
                font-weight: bold;
                display: flex;
                max-width: fit-content;
                &:hover {
                    color: #4d4949;
                }
            }
            .product__dates {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .date-block {
                    margin-top: 0.5rem;
                    display: inline-block;
                    padding: 0.25rem 0.5rem;
                    background-color: #f8f9fa;
                    width: 100%;
                    max-width: 33%;
                    margin-right: 0.5rem;
                    border-radius: 0.25rem;
                }
            }

            .product__image-placeholder {
                height: 100px;

                svg {
                    width: 50%;
                }
            }
        }
        /* Card Footer */
        .list-group-item {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &.total-item {
                font-size: 1.25rem;
                font-weight: bold;
            }
        }
        .payment-type {
            text-align: center;
        }
    }

    /* Endereços */
    &.user-address {
        .card {
            .card-body {
                p {
                    margin-bottom: 0;
                }
            }
            .card-footer {
                background-color: $color-bg-light;
                padding: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                form {
                    position: absolute;
                }
            }
        }
    }
    .address__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .tabs-forms {
        font-weight: bold;

        .nav-tabs {
            border: none;
        }

        .selected {
            display: none;
        }
        .nav-link {
            color: $color-text;
            border-radius: 0;
            padding: 1rem 1.5rem;
            width: 25%;
            border-radius: 1rem;
            margin-bottom: 1.5rem;
            border: 2px solid #ddd;

            &:first-child {
                margin-right: 1rem;
            }

            .tab-form-option {
                &::before {
                    border: 2px solid #ccc;
                    content: "";
                    height: 24px;
                    width: 24px;
                    margin-right: 5px;
                    border-radius: 50px;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            &.active {
                background-color: #fff;
                border-color: $color-success-btn;
                color: $color-success-btn;

                .tab-form-option {
                    &::before {
                        display: none;
                    }
                }

                .selected {
                    display: inline-block;

                    svg {
                        width: 24px;
                        height: 24px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .modal-devolucao {
        img {
            max-width: 80px;
            margin-left: 0;
        }
        .product__image-placeholder {
            width: 100px;
            margin-right: 1rem;
            height: 50px;
            text-align: center;
            svg {
                position: relative;
                width: 60%;
                height: 100%;
            }
        }
    }
}
@media (max-width: $breakpoint-xxl) {
    .my-account {
        .tabs-forms {
            .nav-link {
                width: 48%;
            }
        }
    }
}

@media (max-width: $breakpoint-xl) {
    .my-account {
        &__user {
            text-align: center;
        }

        &__picture {
            justify-content: center;
        }
    }
}

@media (max-width: $breakpoint-lg) {
    .my-account {
        .order__details {
            .order-item {
                flex-direction: row;
                .product__dates {
                    flex-direction: row;
                    .date-block {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-md) {
    .my-account {
        .sub-title {
            margin-top: 2rem;
            margin-bottom: 1rem;

            flex-direction: column;
            align-items: flex-start;
            .actions {
                .btn {
                    width: 100%;
                    margin-top: 0.5rem;
                    font-size: 1rem;
                }
            }
        }
        .order__details {
            .order-item {
                flex-direction: column;

                .product__dates {
                    flex-direction: column;
                    .date-block {
                        max-width: 100%;
                    }
                }
            }
        }
        .checkout__page {
            .custom-creditcard {
                width: 100%;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .my-account {
        .sub-title {
            .actions {
                .btn {
                    width: 100%;
                }
            }
        }

        &.user-address {
            .card {
                margin-bottom: 1rem;
                height: auto;
            }

            .address__header {
                margin-top: 0;
            }
        }

        .tabs-forms {
            .nav-link {
                padding: 0.5rem;
                font-size: 0.85rem;

                &:first-child {
                    margin-right: 0.2rem;
                }

                .tab-form-option {
                    &::before {
                        height: 20px;
                        width: 20px;
                    }
                }

                &.active {
                    .selected {
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        .order__details {
            .order-item {
                flex-direction: column;

                .product__image-placeholder {
                    height: 60px;
                    padding: 0;
                    svg {
                        width: 80%;
                    }
                }

                .product__dates {
                    flex-direction: column;
                    margin-bottom: 1rem;
                    .date-block {
                        max-width: 100%;
                    }
                }
            }
            .list-group-item {
                font-size: 0.9rem;
            }
            .payment-type {
                text-align: right;
            }
        }
    }
}
