.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $color-success;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0 auto;
    margin-bottom: 2rem;
    box-shadow: inset 0px 0px 0px $color-success;
    animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

// On Scroll

.reveal {
    position: relative;
    opacity: 0;

    &.active {
        opacity: 1;
        &.fade-bottom {
            animation: fade-bottom 1s ease-in;
        }
        &.fade-left {
            animation: fade-left 1s ease-in;
        }
        &.fade-right {
            animation: fade-right 1s ease-in;
        }
        &.fade-in--1 {
            animation: fadeIn 1s ease-in;
        }
        &.fade-in--2 {
            animation: fadeIn 2s ease-in;
        }
        &.fade-in--3 {
            animation: fadeIn 3s ease-in;
        }
        &.fade-in--4 {
            animation: fadeIn 4s ease-in;
        }
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px $color-success;
    }
}

// Delay to show

.animation-hide {
    opacity: 0;

    animation: fadeIn 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

// Placeholder
.ph-item {
    direction: ltr;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 15px 15px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    width: 100%;
}

.ph-item,
.ph-item *,
.ph-item :after,
.ph-item :before {
    box-sizing: border-box;
}

.ph-item:before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    -webkit-animation: phAnimation 0.8s linear infinite;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(
            90deg,
            hsla(0, 0%, 100%, 0) 46%,
            hsla(0, 0%, 100%, 0.35) 50%,
            hsla(0, 0%, 100%, 0) 54%
        )
        50% 50%;
}

.ph-item > * {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: 15px;
    padding-left: 15px;
}

.ph-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 7.5px;
}

.ph-row div {
    height: 10px;
    margin-bottom: 7.5px;
    background-color: #dddddd;
}

.ph-row .big,
.ph-row.big div {
    height: 20px;
    margin-bottom: 15px;
}

.ph-row .empty {
    background-color: hsla(0, 0%, 100%, 0);
}

.ph-col-2 {
    flex: 0 0 16.66667%;
}

.ph-col-4 {
    flex: 0 0 33.33333%;
}

.ph-col-6 {
    flex: 0 0 50%;
}

.ph-col-8 {
    flex: 0 0 66.66667%;
}

.ph-col-10 {
    flex: 0 0 83.33333%;
}

.ph-col-12 {
    flex: 0 0 100%;
}

.ph-avatar {
    position: relative;
    width: 100%;
    min-width: 60px;
    background-color: #dddddd;
    margin-bottom: 15px;
    border-radius: 50%;
    overflow: hidden;
}

.ph-avatar:before {
    content: " ";
    display: block;
    padding-top: 100%;
}

.ph-picture {
    width: 100%;
    height: 120px;
    background-color: #dddddd;
    margin-bottom: 15px;
}
.ph-huge-picture {
    width: 100%;
    height: 280px;
    background-color: #dddddd;
    margin-bottom: 15px;
}

// Banner
.loading-content {
    width: 100%;
    height: 400px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-spin {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 7px solid;
    border-color: var(--color-primary) rgba(255, 255, 255, 0.048);
    animation: 1.5s loadingSpin infinite;
}

@-webkit-keyframes loadingSpin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes loadingSpin {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes phAnimation {
    0% {
        transform: translate3d(-30%, 0, 0);
    }

    to {
        transform: translate3d(30%, 0, 0);
    }
}

@keyframes phAnimation {
    0% {
        transform: translate3d(-30%, 0, 0);
    }

    to {
        transform: translate3d(30%, 0, 0);
    }
}

/* Spin */

.icon-spin {
    svg {
        -webkit-animation: iconSpin 2s linear infinite;
        animation: iconSpin 2s linear infinite;
    }
}

@-webkit-keyframes iconSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes iconSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

/* Loading - CEP */

.loading-circle {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loading-circle div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: loading-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;

    right: 50%;
    left: 50%;
}
.loading-circle div:nth-child(1) {
    animation-delay: -0.45s;
}
.loading-circle div:nth-child(2) {
    animation-delay: -0.3s;
}
.loading-circle div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loading-circle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #f8fdff #065ed033;
    animation: 1.5s spin infinite;
    transition: all 0.5s ease;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.underline-effect {
    .underline-link {
        margin: 0 auto;
        padding: 3px 0;
        transition: all 0.1s ease-in-out;
        position: relative;
        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0px;
            height: 1px;
            margin: 5px 0 0;
            transition: all 0.1s ease-in-out;
            transition-duration: 0.5s;
            opacity: 0;
            background-color: darken(#fff, 5%);
        }
        &:hover {
            &:before,
            &:after {
                width: 100%;
                opacity: 1;
            }
        }
    }
}

/* Animate */
.animate__animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-duration: $animate-duration;
    animation-duration: $animate-duration;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &.animate__infinite {
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    &.animate__delay-1s {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        -webkit-animation-delay: $animate-delay;
        animation-delay: $animate-delay;
    }
}

@media (prefers-reduced-motion: reduce), print {
    .animate__animated {
        -webkit-animation-duration: 1ms !important;
        animation-duration: 1ms !important;
        -webkit-transition-duration: 1ms !important;
        transition-duration: 1ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
    }
    .animate__animated[class*="Out"] {
        opacity: 0;
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 3%, 0);
        transform: translate3d(0, 3%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 3%, 0);
        transform: translate3d(0, 3%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
.animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    /* Placeholder Card */
    .ph-picture {
        height: 60px;
        width: 80%;
    }
    .ph-col-12 {
        flex: 0 0 50%;
    }
    .ph-row {
        .empty {
            background-color: #ddd;
        }
    }
    //Banner
    .loading-content {
        height: 150px;
    }
    .loading-spin {
        width: 40px;
        height: 40px;
        border-width: 5px;
    }
}
