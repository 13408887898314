/* Categorias */
.categories--block {
    padding: 2rem 0 4rem 0;

    .category {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        .category-content {
            color: var(--color-text-categories);
            background-color: var(--color-bg-categories);
            text-shadow: 0 0 1px #555555;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 300px;
            font-size: 1.35rem;

            font-weight: bold;
            text-align: center;
            padding: 1rem;

            &.bg-opacity {
                background-color: var(--color-bg-opacity-categories);
            }
        }
    }
    .btn {
        @extend .btn-see-more;
        border-color: var(--color-text-categories);
        color: var(--color-text-categories);

        &:hover {
            background-color: var(--color-text-categories);
            color: var(--color-bg-categories);
            text-shadow: none;
        }
    }
}

/* Destaques - Mais locados */

.most-rented {
    padding-bottom: 4rem;
}

/* Marcas */
.brands--block {
    padding: 0 0 4rem 0;
    background-color: #fff;

    .brands-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .brand {
        margin-bottom: 1rem;
        a {
            &:hover {
                opacity: 0.7;
            }
        }
        img {
            width: auto;
        }
    }
}

/* Slider Marcas */

.items-carousel-scroll {
    position: relative;
    background-color: #ffffff;
    padding-bottom: 2rem;

    .items-carousel-block {
        position: relative;
    }

    .carousel-content {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        background-color: #ffffff;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }

        .items-carousel {
            list-style: none;
            padding-left: 0;
            margin: 0;
            width: auto;

            display: inline-flex;
            align-items: center;
            transition: all 0.5s ease;

            position: relative;
            top: 0px;
            left: 0px;

            .brand {
                padding: 0 1rem;
            }
            img {
                max-width: 200px;
                width: auto;
                padding: 1rem;
            }
        }
    }
    .carousel-control-prev,
    .carousel-control-next {
        color: #333;
        top: 0;
        width: 40px;
        border-radius: 50px;
    }

    .carousel-control-prev {
        left: -25px;
    }
    .carousel-control-next {
        right: -25px;
    }
}

/* Slider Produtos */

.products__slider {
    @extend .items-carousel-scroll;

    background-color: #fcfcfc;
    margin-top: 3rem;
    padding-bottom: 4rem;
    .carousel-content {
        background-color: #fcfcfc;
        padding-bottom: 1rem;

        .card-product {
            min-height: 334px;
        }
    }
    .products-content {
        background-color: transparent;
        .products {
            margin-bottom: 1rem;
        }
    }
    .product {
        padding: 0;
        .product__name {
            display: block;
        }
    }
    .card {
        width: 300px;
        margin-right: 0.3rem;
        border: none;
    }
}

/* Redes Sociais */
.social-network {
    @extend .clean-list;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-top: 1.5rem;

    li {
        margin-right: 1rem;
    }

    a {
        border: 1px solid var(--color-text-footer);
        padding: 5px;
        text-align: center;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: #fff;
            opacity: 1;
        }

        &.youtube {
            &:hover {
                background-color: $color-youtube;
                border-color: $color-youtube;
            }
        }
        &.facebook {
            &:hover {
                background-color: $color-facebook;
                border-color: $color-facebook;
            }
        }
        &.linkedin {
            &:hover {
                background-color: $color-linkedin;
                border-color: $color-linkedin;
            }
        }
        &.instagram {
            &:hover {
                background-image: $color-instagram;
                border-color: #f9b56d;
            }
        }
        &.pinterest {
            &:hover {
                background-color: $color-pinterest;
                border-color: $color-pinterest;
            }
        }
    }
}

.breadcrumb-block {
    text-transform: uppercase;
    background-color: #fff;

    .breadcrumb {
        padding: 1rem 0;
        font-size: 0.8rem;
        background-color: transparent;
        margin-bottom: 0;

        a {
            color: $color-text;
            font-weight: bold;
        }
    }
}

/* Paginação */

.pagination-block {
    justify-content: center;
}
.pagination__products {
    justify-content: center;
    margin-top: 2rem;
    .page-item {
        &.active {
            .page-link {
                background-color: transparent;
                color: $color-text;
                font-weight: bold;
                border: none;
            }
        }
    }
    .page-link,
    .page-item:first-child .page-link,
    .page-item:last-child .page-link {
        border: none;
        background-color: transparent;
        color: $color-text;
        border-radius: 50px;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        font-size: 1.25rem;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: #f4f4f4;
        }
        &:focus {
            box-shadow: none;
        }
    }
}

/* Bloco de Contato - Mobile */
.contact-buttons {
    padding: 1rem 0;
    svg {
        height: 24px;
        path {
            fill: currentColor !important;
            stroke-width: 0 !important;
        }
    }
    &__modal {
        margin: 0 1.5rem;
        .contact-option {
            background-color: $color-primary-light;
            border-radius: 1rem;
            padding: 1rem;
            margin-bottom: 0.5rem;
            .info {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 1rem;
            }
            .info-icon {
                color: #ccc;
                margin-right: 1rem;
            }
            .info-description {
                font-weight: bold;
                font-size: 0.8rem;
                color: $color-primary-darker;
            }
            .info-number {
                font-size: 1.25rem;
                font-weight: bold;
            }
        }
        .modal-title {
            font-weight: bold;
        }
        .modal-header {
            border-bottom: 0;
            padding: 1rem 1rem 0;
        }
        .modal-content {
            border-radius: 1rem;
        }
    }
}

// Validação de Senha Forte
.strong-pass {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0.5rem;

    p {
        font-size: 0.8rem;
        font-weight: 400;
        margin-bottom: 0.25rem;
        span {
            width: 6px;
            height: 6px;
            display: inline-block;
            margin-right: 10px;
            font-size: 0.7rem;
        }
    }
}

.password-status {
    margin: 0.25rem 0.5rem;
    .status {
        padding-top: 3px;
    }
}

.show-password--block {
    position: relative;
    input {
        position: relative;
    }
    &.reset-password {
        .show-password {
            top: 33px;
            a {
                color: $color-text-light;
            }
        }
    }
}
.show-password {
    position: absolute;
    right: 30px;
    top: 37px;

    a {
        color: $color-text-light;
    }
}

// Checkbox Aceite de termos
.aceite-checkbox {
    text-align: left;
    .form-check {
        padding-left: 1.5rem;
    }
    .form-check-input {
        margin-left: -1.5rem;
        margin-top: 0.35rem;
    }
    label {
        line-height: 1.8;
    }
}

// Paginas Customizadas - criadas no backoffice
.pagina-customizada {
    img {
        height: auto;
        max-width: 100%;
    }
}

// Custom Checkbox
.custom-checkbox {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &--input {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        width: 26px;
        height: 26px;
        opacity: 0;
        position: absolute;
    }
    &--label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .content {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: bold;
        img {
            max-width: 50px;
            max-height: 80px;
            margin-left: 0.65rem;
        }
        .product__image {
            min-width: 100px;
            text-align: center;
            margin-right: 1rem;
        }
    }
}
.custom-checkbox--svg input:checked + .custom-checkbox__label--svg {
    &:before {
        background: var(--color-primary);
        border-color: var(--color-primary);
    }

    .path {
        stroke: #fff;
        stroke-dashoffset: 0;
    }
}

.custom-checkbox__label {
    cursor: pointer;
    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border: 2px solid #c5c5c5;
        margin-right: 8px;
    }
}

.custom-checkbox__label--svg {
    &:before {
        position: relative;
        left: 0;
        width: 24px;
        height: 24px;
        transition: all 0.35s ease-out;
    }

    svg {
        position: absolute;
        left: 4px;
        width: 15px;
        height: 15px;
        pointer-events: none;
    }

    .path {
        stroke-dashoffset: 111.46px;
        stroke-dasharray: 111.46px;
        stroke: var(--color-primary);
        transition: all 0.35s ease-out;
    }
}

//Avaliacao
.rating-group {
    .rating {
        margin-bottom: 2rem;
        border: none;

        &__total {
            .stars-block {
                width: 116px;
                height: 24px;
                position: relative;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
            }
            .stars-original {
                position: absolute;
                svg {
                    path {
                        fill: #ccc;
                    }
                }
            }

            .stars {
                display: inline-block;
                white-space: nowrap;
                z-index: 1;
                overflow: hidden;
                position: absolute;
                svg {
                    path {
                        fill: #ffcc00;
                    }
                }
            }
            .total-block {
                font-weight: bold;
                font-size: 2.5rem;
                margin-top: 0;
            }
            .total {
                font-weight: normal;
                font-size: 1rem;
                margin-top: 0.5rem;
            }
        }

        &__star {
            color: #ccc;
        }
        &__note {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 1rem;

            .checked {
                svg {
                    path {
                        fill: #ffcc00;
                    }
                }
            }
        }
        &__title {
            font-weight: bold;
            margin-bottom: 1rem;
            font-size: 1.25rem;
        }
        &__name {
            font-weight: bold;
        }
        &__date {
            font-style: italic;
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }
    }
}

.rating__form {
    .name {
        font-weight: bold;
        font-size: 1.25rem;
    }
    .review {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ddd;
    }
    .rating {
        margin-bottom: 1rem;
        .stars {
            color: #ccc;
            position: relative;

            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
            width: 216px;

            svg {
                width: 30px;
                height: 30px;
            }

            label {
                position: relative;
                &:hover,
                &:hover ~ label {
                    cursor: pointer;
                    color: #e9c63b;
                }
            }
            .star {
                opacity: 0;
                &:checked + .star label,
                &:checked ~ svg,
                &:checked ~ label,
                &:checked {
                    color: #ffcc00;
                }
            }
        }
    }

    .rating label > span {
        border: 0;
        padding: 0;
        margin: 0;
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        white-space: nowrap;
    }
}

// 1200
@media (max-width: $breakpoint-xl) {
    .brands__slider {
        .carousel-control-next {
            right: -40px;
        }
        .carousel-control-prev {
            left: -40px;
        }
        .brand-link {
            img {
                max-width: 100px;
            }
        }
    }
    .categories--block {
        .category {
            margin-bottom: 2rem;
        }
    }
}
//992px
@media (max-width: $breakpoint-lg) {
    .brands__slider {
        .carousel-control-next {
            right: -20px;
            top: 20%;
        }
        .carousel-control-prev {
            left: -20px;
            top: 20%;
        }
        .brand-link {
            padding: 0.5rem;
            img {
                max-width: 70px;
                width: auto;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .categories--block {
        padding-bottom: 2rem;
        .category {
            margin-bottom: 2rem;
        }
    }

    .social-network {
        justify-content: center;
        margin-top: 2rem;
    }

    .most-rented {
        padding-bottom: 1rem;
    }

    .items-carousel-scroll {
        .carousel-control-prev,
        .carousel-control-next {
            display: none;
        }
    }
    .products__slider {
        background-color: #fcfcfc;
        padding-bottom: 2rem;

        .carousel-content {
            background-color: #fcfcfc;

            .items-carousel {
                .item {
                    height: 175px;
                }
                .card-product {
                    min-height: 204px;
                }
                .product__photo {
                    //width: 140px;
                    width: 35%;
                }
                .product__content {
                    width: 65%;
                }
                .product__photo--content {
                    height: 150px;
                    min-width: 120px;
                }
                img {
                    max-width: 140px;
                    min-width: 100px;
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
    .strong-pass {
        flex-direction: column;
        p {
            margin-bottom: 0;
        }
    }

    .custom-checkbox .content .product__image {
        min-width: 50px;
        img {
            max-width: 46px;
        }
    }
}
