footer {
    padding: 2rem 0;
    background-color: var(--color-bg-footer);
    color: var(--color-text-footer);

    a {
        color: var(--color-text-footer);

        &:hover {
            color: var(--color-text-footer);
            opacity: 0.7;
        }
    }

    .footer__info {
        svg {
            margin-right: 0.5rem;
        }
    }

    .footer--title {
        font-size: 1rem;
        margin-bottom: 1rem;
        font-weight: bold;
    }

    .navbar-nav {
        &.navbar-footer {
            .nav-link {
                display: inline-block;
                font-size: 1rem;
                padding: 0.35rem 0;
            }
        }
    }
    .logo-thumb {
        max-width: 160px;
        margin-bottom: 1rem;
    }
    .footer__payment {
        display: flex;
        flex-direction: column;
    }
}

.footer-bottom {
    @extend footer;

    padding: 1rem 0;
    background-color: var(--color-bg-footerbottom);
    color: var(--color-text-footerbottom);

    font-size: 0.8rem;

    .footer--store-details {
        font-size: 0.75rem;
    }

    .copyright {
        display: flex !important;
        align-items: center;
        flex-direction: row-reverse;

        opacity: 1 !important;
        visibility: visible !important;
        margin: 0 auto !important;
        position: static !important;
        overflow: visible !important;
    }
    .footer__payment-block {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footer__payment {
        padding-right: 1rem;
        margin-bottom: 1rem;

        &-title {
            margin-bottom: 0.5rem;
        }
    }
    .footer-bottom--img {
        max-height: 30px;
    }
    .icon-pix {
        color: $color-pix;
        svg {
            height: 20px;
        }
    }
    .icon-a-combinar {
        svg {
            color: $color-text;
            height: 20px;
        }
    }
    .selos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        li {
            margin-right: 1.5rem;
            &:not:first-child {
                margin: 0;
            }
        }
    }
}

.copyright {
    opacity: 1 !important;
    display: flex !important;
    visibility: visible !important;
    margin: 0 auto !important;
    position: static !important;
    overflow: visible !important;
}

@media (max-width: $breakpoint-lg) {
    footer {
        .footer--title {
            margin-top: 1rem;
        }
    }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    footer {
        text-align: center;
        .footer--title {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
    }
    .footer-bottom {
        text-align: center;
        .copyright {
            margin-top: 1rem;
            flex-direction: row-reverse;
            justify-content: center;
        }
        .footer__payment-block {
            flex-direction: column;
        }
        .footer__payment {
            margin-bottom: 1rem;

            &:last-child {
                margin-left: 0;
            }
        }
        .selos {
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 0;
            li {
                margin: 0;
                padding: 0.5rem;
            }
        }
    }
}
