.sidebar-container {
    height: 100%;
    width: 320px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    transform: translateX(-320px);
    transition: 0.2s linear all;
    padding: 0.5rem 1rem;

    background: $color-bg-sidebar !important;
    color: $color-text-sidebar !important;

    display: none;
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;

    transition: 400ms cubic-bezier(0.4, 0, 1, 1);
    transition-property: transform;

    a,
    button {
        color: $color-text-sidebar !important;
    }

    &.open-block {
        height: 100%;
        transform: translateX(0);
    }

    li {
        .nav-link,
        a {
            padding: 0.3rem 0;

            svg {
                width: 20px;
            }
        }
    }

    .sidebar-block {
        border-top: 1px solid #eeeeee;
        padding-top: 1rem;
        .sidebar-title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.8rem;
        }
    }
}

@media (max-width: 992px) {
    .sidebar-container {
        display: block;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .sidebar-container {
        padding: 1.5rem 3rem;
        font-size: 1.25rem;
        display: block;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .sidebar-container {
        padding: 0 1rem;
        font-size: 1rem;
        display: block;
    }
}
