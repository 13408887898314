.nav-fixed {
    width: 100%;
    z-index: 1020;
    height: $header-height;

    &.with-topbar {
        position: fixed;
        top: $topbar-height;
    }
}

.nav-fixed {
    &.onscroll {
        top: 0;
        height: $header-height-scroll;
        box-shadow: $box-shadow-light;
        position: fixed;

        .navbar-brand {
            img {
                max-height: calc(#{$header-height-scroll} - 20px);
                width: auto;
            }
        }
        &.with-topbar {
            top: $topbar-height;
        }
    }
}

.nav-fixed + .content {
    padding-top: 102px;
}

.navbar {
    background-color: var(--color-bg-header);
    color: var(--color-text-header);

    .navbar-brand {
        width: auto;
        min-width: 200px;

        img {
            max-width: 100%;
            transition: all 0.3s;
            max-height: calc(#{$header-height} - 20px);
            max-width: 250px;
            width: auto;
        }
    }
    .navbar-nav {
        background-color: var(--color-bg-navbar);
        color: var(--color-text-navbar);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        min-width: 200px;

        .nav-link {
            color: var(--color-text-navbar);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.9rem;
        }

        &.navbar-custom {
            background-color: var(--color-bg-header);
            color: var(--color-text-header);
            .nav-link {
                color: var(--color-text-header);
            }
        }
    }
    .navbar-collapse {
        align-items: center;
        justify-content: space-between;
    }
    .navbar-custom-block {
        justify-content: flex-end;
    }

    /* Header Simples */
    &.nav-simple {
        box-shadow: $box-shadow-light;
        height: $header-simple-height;
        .navbar-brand {
            img {
                max-height: calc(#{$header-simple-height} - 45px);
            }
        }
    }

    /* Menu do Usuário */
    .menu-user {
        @extend .clean-list;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
            font-weight: 700;
            font-size: 0.95rem;
            color: var(--color-text-header);
            background-color: var(--color-bg-header);

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            padding: 0.3rem 1rem;
            position: relative;
            outline: none;

            svg,
            i {
                font-size: 24px;
                color: var(--color-text-header);
            }
        }

        .dropdown-menu {
            @extend .dropdown-menu-custom;

            color: var(--color-text-header);
            background-color: var(--color-bg-header);
            min-width: 14rem;

            a {
                &:hover,
                &:focus {
                    color: var(--color-text-header);
                    background-color: rgba(
                        var(--color-text-header-r),
                        var(--color-text-header-g),
                        var(--color-text-header-b),
                        0.2
                    );
                    svg,
                    i {
                        color: var(--color-text-header);
                    }
                }
            }

            .logout-block {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                padding-top: 0.3rem;
                margin-top: 0.3rem;

                a {
                    font-weight: 400;
                }

                span {
                    font-weight: bold;
                }
            }
        }
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        .my-account-link {
            display: block;
            font-weight: 100;
            font-size: 0.9rem;
        }

        .menu-user--total {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .total-price {
                display: block;
                font-weight: 100;
                font-size: 0.9rem;
            }
        }
    }

    .cart-link {
        margin-right: 1rem;

        &__count {
            background-color: var(--color-text-header);
            color: var(--color-bg-header);
            border-radius: 50px;
            width: 22px;
            height: 22px;
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.75rem;
            position: absolute;
            top: 5px;
            left: 30px;
            transition: all 0.5s ease-in-out;

            &:hover {
                transform: scale3d(1.2, 1.2, 1.2);
            }
        }
    }
}
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .navbar {
        .navbar-custom {
            .nav-link {
                font-size: 0.8rem;
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .navbar {
        .navbar-brand {
            min-width: 90px;
            img {
                max-width: 100%;
                max-height: 70px;
            }
        }
        a,
        button {
            color: var(--color-text-header);

            &:focus {
                color: var(--color-text-header);
            }
        }
        .cart-link {
            &__count {
                top: 0px;
                left: 30px;
            }
        }
    }
    .header-mobile {
        width: 100%;
        margin: 0;
    }

    .nav-simple {
        .header-mobile {
            width: 30%;
        }
    }

    .nav-fixed {
        height: 90px;
        &.onscroll {
            height: 70px;
        }
    }
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .navbar {
        .cart-link {
            &__count {
                top: 0px;
                left: 30px;
                font-weight: bold;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .nav-fixed {
        height: auto;

        &.with-topbar {
            top: 0;
            position: relative;
        }

        &.onscroll {
            height: 56px;
            .navbar-brand {
                width: 100px;
                img {
                    max-height: 30px;
                }
            }
            &.with-topbar {
                top: 0;
            }
        }
    }

    .navbar {
        .navbar-brand {
            img {
                max-width: 150px;
                max-height: 40px;
            }
        }
        .search-form {
            .input-group {
                width: 100%;
            }
        }

        .menu-user {
            flex-direction: column;
            align-items: flex-start;
        }

        .navbar-collapse {
            background-color: var(--color-bg-header);
            height: auto;
        }
        .navbar-toggler {
            background-color: var(--color-bg-header);
            border-color: var(--color-text-header);
        }
        a,
        button {
            color: var(--color-text-header);

            &:hover,
            &:focus {
                color: var(--color-text-header);
            }
        }

        /* Header Simples */
        &.nav-simple {
            height: auto;
            .navbar-brand {
                img {
                    max-height: 40px;
                }
            }
        }
    }
}

// XXL - Extra Large

@media (min-width: $breakpoint-xxl) {
    .navbar {
        .menu-user {
            a {
                padding: 0.5rem 1.2rem;
            }
        }
        .cart-link__count {
            top: 7px;
            left: 35px;
        }
    }
}
