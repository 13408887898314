// Custom Colors
:root {
    --color-primary: rgb(51, 121, 190);
    --color-primary-r: 51;
    --color-primary-g: 121;
    --color-primary-b: 190;

    --color-secondary: #0097f5;

    --color-bg-topbar: #ffffff;
    --color-text-topbar: #333333;

    --color-bg-header: #ffffff;
    --color-text-header: rgb(51, 51, 51);
    --color-text-header-r: 51;
    --color-text-header-g: 51;
    --color-text-header-b: 51;

    --color-bg-navbar: rgb(50, 120, 189);
    --color-bg-navbar-r: 50;
    --color-bg-navbar-g: 120;
    --color-bg-navbar-b: 189;

    --color-text-navbar: rgb(255, 255, 255);
    --color-text-navbar-r: 255;
    --color-text-navbar-g: 255;
    --color-text-navbar-b: 255;

    --color-bg-footer: #3379be;
    --color-text-footer: #ffffff;

    --color-bg-footerbottom: #f7f7f7;
    --color-text-footerbottom: #333333;

    --color-bg-categories: #3379be;
    --color-bg-opacity-categories: #3379becc;
    --color-text-categories: #ffffff;

    --success-light: #e9f4eb;

    --font-primary: "Roboto", sans-serif;
}

// Global Colors

$color-primary: #3379be;
$color-primary-light: #f4f4f4;
$color-primary-lighter: #fcfcfc;
$color-primary-dark: #194b97;
$color-primary-darker: #2c2c84;

$color-secondary: #0097f5;

$color-text: #262636;
$color-text-medium: #444444;
$color-text-light: #757575;
$color-accent: #00df89;

$color-bg-light: #f7f7f7;

$color-link: #0c6cb9;
$color-link-default: #0357b0;
$color-link-dark: #0156b1;

$color-success-light: #e6f4e7;
$color-success: #82c785;
$color-success-dark: #699c79;
$color-success-text: #2b8454;
$color-success-btn: #2e8540;
$color-success-darker: #066806;

$color-error: #ec553d;
$color-error-dark: #dc3545;
$color-offer: #dc3545;

$color-bg-sidebar: #ffffff;
$color-text-sidebar: #343434;

$color-youtube: #ff1300;
$color-facebook: #4064ac;
$color-pinterest: #e61122;
$color-instagram: linear-gradient(
    to left,
    hsl(37, 97%, 70%),
    hsl(329, 70%, 58%)
);
$color-linkedin: #0b66c2;
$color-pix: #32bcad;
$color-whatsapp: #29b43f;
$color-whatsapp-dark: #48974b;

$breakpoint-xxs: 320px;
$breakpoint-xs: 0;
$breakpoint-sm: 575.98px;
$breakpoint-md: 767.98px;
$breakpoint-lg: 991.98px;
$breakpoint-xl: 1199.98px;
$breakpoint-xxl: 1399.98px;

// Height
$topbar-height: 38px;
$header-height: 115px;
$header-height-scroll: 70px;
$fixed-height: calc(#{$topbar-height} + #{$header-height});

$header-simple-height: 100px;

// Fonts
$font-primary: "Roboto", sans-serif;

$box-shadow-light: 0px 2px 5px #7c44443b;
$box-shadow: 0px 0px 2px #333;

// Lazy Loading
$ph-bg: #fff !default;
$ph-color: #e0e4e8;
$ph-border-radius: 2px !default;
$ph-gutter: 30px !default;
$ph-spacer: 15px !default;
$ph-avatar-border-radius: 50% !default;
$ph-animation-duration: 0.5s !default;

// Animation
$animate-duration: 1s;
$animate-delay: 1s;
$animate-repeat: 1;
