::selection {
    text-shadow: none;
    background: var(--color-bg-navbar);
    color: var(--color-text-navbar);
}
html {
    scroll-behavior: smooth;
}
html,
body {
    position: relative;
    overflow-y: auto;
}
.pre-loading {
    display: none !important;
}
body {
    font-family: var(--font-primary);
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background-color: #fff;
    transition: all 0.5s ease-out;
    scroll-behavior: smooth;

    &.modal-open {
        padding-right: 0 !important;
    }
}

#wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

#wrapper--content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 76vh;

    -webkit-flex: 1 0 auto;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.wrapper-content {
    margin-top: 0;
    &.top-fixed {
        margin-top: calc(#{$topbar-height} + #{$header-height});
    }
}

.home__margin-top {
    margin-top: calc(#{$topbar-height} + #{$header-height});
}

.overlayer {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    display: none;

    &.show-overlayer {
        display: flex;
    }

    /* CEP */
    &__loading {
        @extend .overlayer;

        display: none;
        z-index: 10002;
        align-items: center;
        justify-content: center;

        &.show-overlayer {
            display: flex;
        }
    }
}

a {
    color: $color-link-dark;
    transition: all 0.5s;

    &:hover {
        text-decoration: none;
        opacity: 0.9;
    }
}

strong {
    font-weight: bold;
}
hr {
    color: #aaaaaa;
}
.opacity-0 {
    opacity: 0;
}

.text-primary {
    color: $color-primary;
}
.text-primary-dark {
    color: $color-primary-dark;
}
.text-dark {
    color: #333;
}

.bg-primary-light {
    background-color: shade($color-primary-lighter, 3%);
}
.pl-color {
    color: #dddddd;
}

.regioes__modal {
    .link {
        color: $color-text;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        text-align: center;
        color: #fff;
        font-size: 1.25rem;

        &:hover {
            opacity: .8;
        }
    }
    .titulo {
        font-weight: bold;
        font-size: 1.5rem;
    }
    svg {
        color: #fff;
    }
}

.regiao {

    &__bloco {
       max-width: 280px;
       display: inline-block;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
    }

    &__card {
        border: 2px solid #fff;
        border-radius: .75rem;
        background-color: var(--color-primary);
    }
    &__descricao {
        font-size: .75rem;
        font-weight: 300;
        font-style: italic;
    }
    &__icone {
        svg {
            max-height: 28px;
        }
        padding-right: .5rem;
    }
}

.main-title {
    font-size: 1.5rem;
    padding-top: 3rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 1rem;
    font-weight: bold;
    color: var(--color-text);
    font-family: var(--font-primary);

    position: relative;

    &::after {
        content: " ";
        width: 4.56em;
        border-bottom: 2px solid transparent;
        border-color: var(--color-secondary);
        display: block;
        position: absolute;
        bottom: -1px;
    }
}

.clean-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.placeholder {
    margin: 0 auto;
    max-width: 100%;
    min-height: 200px;
    background-color: #eee;
}

.input-group {

    img.bandeira {
        max-width: 20px;
        margin-right: 0.5rem;
    }
    .btn.dropdown-toggle:focus {
        box-shadow: none;
    }
    .input-group-text {
        border-radius: 1.5rem;
    }
    .dropdown-item {
        cursor: pointer;
    }
}
.btn-dropdown-telefone {
    background-color: transparent;
    border-radius: 1.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #ced4da;
    display: flex;
    align-items: center;

    &:hover,
    &:active {
        border-color: #ced4da !important;
    }
}

input {
    border-radius: 2rem;
}
input.form-control,
select.form-control {
    border-radius: 2rem;
    height: 38px;
}
label {
    margin-bottom: 0.5rem;
}
// input[type="file"] {
//     border-radius: 0;
// }
.custom-file-block {
    background: #ffffff;
    width: 100%;
    background-color: #f4f4f4;
    .file-name {
        padding: 0.75rem 1rem;
        border-radius: 0;
    }

    .custom-input-file {
        background: #ffffff;
        padding: 0.75rem 1rem;
        border-radius: 0;
        width: 100%;
        border: 1px solid #f4f4f4;
    }
    .file-success {
        border-color: $color-success;
    }
    .file-error {
        border-color: $color-error-dark;
    }
    .msg-success {
        font-weight: bold;
        color: $color-success-darker;
        background-color: rgb(238, 252, 240);
    }
    .msg-danger {
        font-weight: bold;
        color: $color-error-dark;
        background-color: rgb(253, 245, 245);
    }
}

.form-group {
    margin-bottom: 1rem;
}
fieldset {
    legend {
        font-size: 1rem;
    }
}

.list-group-item {
    padding: 0.75rem 0;
}

/* Img - Editor */

.product-details__description,
.blog__post,
.blog__post-details,
.navbar-footer,
.bloco-html-banner,
.bloco-html-destaque,
.bloco-html-categorias,
.bloco-html-produtos {
    img,
    figure {
        max-width: 100%;
    }
}

/* Select - Safari */
select {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDkwLjY4OCA0OTAuNjg4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTAuNjg4IDQ5MC42ODg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiNGRkMxMDc7IiBkPSJNNDcyLjMyOCwxMjAuNTI5TDI0NS4yMTMsMzQ3LjY2NUwxOC4wOTgsMTIwLjUyOWMtNC4yMzctNC4wOTMtMTAuOTktMy45NzUtMTUuMDgzLDAuMjYyDQoJYy0zLjk5Miw0LjEzNC0zLjk5MiwxMC42ODcsMCwxNC44MmwyMzQuNjY3LDIzNC42NjdjNC4xNjUsNC4xNjQsMTAuOTE3LDQuMTY0LDE1LjA4MywwbDIzNC42NjctMjM0LjY2Nw0KCWM0LjIzNy00LjA5Myw0LjM1NC0xMC44NDUsMC4yNjItMTUuMDgzYy00LjA5My00LjIzNy0xMC44NDUtNC4zNTQtMTUuMDgzLTAuMjYyYy0wLjA4OSwwLjA4Ni0wLjE3NiwwLjE3My0wLjI2MiwwLjI2Mg0KCUw0NzIuMzI4LDEyMC41Mjl6Ii8+DQo8cGF0aCBkPSJNMjQ1LjIxMywzNzMuNDE1Yy0yLjgzMSwwLjAwNS01LjU0OC0xLjExNS03LjU1Mi0zLjExNUwyLjk5NCwxMzUuNjMzYy00LjA5My00LjIzNy0zLjk3NS0xMC45OSwwLjI2Mi0xNS4wODMNCgljNC4xMzQtMy45OTIsMTAuNjg3LTMuOTkyLDE0LjgyLDBsMjI3LjEzNiwyMjcuMTE1bDIyNy4xMTUtMjI3LjEzNmM0LjA5My00LjIzNywxMC44NDUtNC4zNTQsMTUuMDgzLTAuMjYyDQoJYzQuMjM3LDQuMDkzLDQuMzU0LDEwLjg0NSwwLjI2MiwxNS4wODNjLTAuMDg2LDAuMDg5LTAuMTczLDAuMTc2LTAuMjYyLDAuMjYyTDI1Mi43NDQsMzcwLjI3OQ0KCUMyNTAuNzQ4LDM3Mi4yODEsMjQ4LjAzOSwzNzMuNDA4LDI0NS4yMTMsMzczLjQxNXoiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K)
        no-repeat 95% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 30px;
    width: 100px;
    padding: 5px;
    background-size: 1rem;
}

.select-date {
    position: relative;
    display: flex;
    align-items: center;

    input {
        padding-left: 40px;
    }

    i,
    img,
    svg {
        position: absolute;
        left: 15px;
        width: 16px;
    }
}

.select-time {
    @extend .select-date;
    select {
        padding-left: 35px;
    }
}

.default-option {
    color: #6c757d;
}

.custom-badge {
    &.badge {
        padding: 0.25rem 0.65rem;
    }
    &.badge-primary {
        color: #194b97;
        background-color: rgb(217, 244, 253);
    }
    &.badge-success {
        color: #245324;
        background-color: #d4edd4;
    }
}

/* Datepicker Calendar */
.ui-state-default,
.ui-widget-content .ui-state-default {
    border-color: #fff;
    background-color: #fff;
    color: #333333;
    border-radius: 5px;
}
.ui-state-active,
.ui-widget-content .ui-state-active {
    background-color: $color-primary;
    color: #fff;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight {
    background-color: #e9e9e9;
    color: #333;
}

.ui-datepicker .ui-datepicker-title select {
    padding: 0;
}

.dropdown-menu-custom {
    border: none;
    box-shadow: $box-shadow-light;
}

/* Textarea */

textarea.form-control {
    border-radius: 1rem;
}

/* Alert */

.alert {
    font-weight: bold;
    font-size: 0.85rem;
    &.alert-warning {
        color: #ce7d05;
        background-color: #fffaf2;
        border-color: #ff980045;
        .alert-link {
            color: #8c3f07;
        }
    }
    &.alert-info {
        color: #0c5460;
        background-color: #d1ecf18c;
        border-color: #bee5eb;
    }
    &.alert-danger {
        background-color: #f8d7daab;
        border-color: #e8c5c8;
    }
    &.alert-success {
        background-color: #d4edda8f;
    }
}
.text-success {
    color: $color-success-darker !important;
}
.text-info {
    color: #047487 !important;
}
.text-warning {
    color: #ce7d05 !important;
}
/* Linha Ou Login */

.line-divisor {
    text-align: center;
    background-image: -webkit-linear-gradient(
        top,
        transparent 10px,
        #e3e3e3 0,
        #e3e3e3 12px,
        transparent 0
    );
    background-image: -o-linear-gradient(
        top,
        transparent 10px,
        #e3e3e3 10px,
        #e3e3e3 12px,
        transparent 12px
    );
    background-image: linear-gradient(
        180deg,
        transparent 10px,
        #e3e3e3 0,
        #e3e3e3 12px,
        transparent 0
    );
    margin-top: 2rem;

    span {
        display: inline-block;
        background-color: #fff;
        padding: 0 10px;
        text-transform: none;
    }
}

/* Empty results */

.empty-results {
    text-align: center;
    //color: var(--color-primary);
    color: #999;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    min-height: 50vh;

    h2,
    h3 {
        margin-top: 2rem;
        margin-bottom: 1rem;
        color: $color-text;
    }

    .icon-block {
        max-width: 100%;
        padding: 1rem;
        svg {
            max-width: 100%;
        }
    }
}

/* Card Shadow */
.card-shadow {
    border: 2px solid $color-primary-light;
    box-shadow: $box-shadow-light;
    border-radius: 0.3rem;
    background-color: #fff;
}

.card-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Card Selected
.icon-selected {
    display: none;
}
.card-custom-radio {
    @extend .card-shadow;
    box-shadow: none;

    &:hover {
        background-color: #f9f9f9;
    }
    .form-check {
        padding-left: 2rem;
    }
    .form-check-input:disabled {
        opacity: 0;
    }
}
.card-disabled {
    opacity: .35;
}
.card-selected {
    background-color: var(--success-light);
    border: 2px solid $color-success-darker;

    &:hover {
        background-color: var(--success-light);
    }
    .custom-radio {
        span,
        &--info {
            color: $color-success-darker;
        }
    }
    .radio-checkmark {
        display: none;
    }

    // Checkmark
    .icon-selected {
        display: flex;
        svg {
            color: $color-success-darker;
            width: 20px;
            height: 20px;
            position: absolute;
            left: -20px;
        }
    }
}

/* Custom Radio Button Card */
.custom-radio {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 8px;
        left: 0;
        width: 20px;
        height: 20px;

        &:checked ~ .radio-checkmark {
            display: none;
        }
    }

    &--info {
        margin-top: 0.5rem;
        font-weight: bold;
        font-size: 0.85rem;
        color: #666;
    }
}
.radio-checkmark {
    position: absolute;
    top: 1rem;
    left: -20px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
}

.icon-contrato {
    height: 180px;
}
// Icones

.icon-xs {
    svg {
        width: 10px;
    }
}
.icon-sm {
    svg {
        width: 15px;
    }
}
.icon-m {
    svg {
        width: 18px;
    }
}
.icon-md {
    svg {
        width: 24px;
    }
}
.icon-lg {
    svg {
        width: 40px;
    }
}
.icon-x {
    svg {
        width: 60px;
    }
}
.icon-xl {
    svg {
        width: 100px;
    }
}

.icon-arrow-down {
    svg {
        width: 12px;
        stroke: 12px;
    }
}

.tag-ambiente-testes {
    background-color: #ffe000;
    color: #333;
    padding: 0.3rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;

    position: fixed;
    z-index: 2000;
    width: 100%;
    bottom: 0;
}

// Form - Label Opcional
.label-optional {
    font-size: 0.8rem;
    font-style: italic;
    color: #9e9e9e;
}

// Form Helper
.form-helper-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

// Cookies
.cookies__modal {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: $box-shadow-light;
    padding: 1.5rem;
    background-color: #fff;
    z-index: 9999999;
    width: 40vw;

    a {
        &.btn {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
    }
}

// Movimentacoes

.card__movimentacao {
    .note {
        display: flex;
        position: relative;
        .note-content {
            padding-bottom: 2rem;
            display: flex;
            flex-direction: column;
            color: $color-text-medium;
        }

        &:last-child {
            .icon-status {
                &::after {
                    border: none;
                }
            }
        }
    }
    .icon-status {
        padding: 0.5rem;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        margin-right: 1rem;

        &.icon-delivered {
            color: #194b97;
            background-color: #d9f4fd;
        }
        &.icon-returned {
            color: #245324;
            background-color: #d4edd4;
        }
        &::after {
            content: "";
            border: 1px solid #ccc;
            height: calc(100% - 40px);
            width: 1px;
            left: 19px;
            right: 0;

            position: absolute;
            bottom: 0;
        }
    }
}
.divProdutoDestaque {
    margin-top: 1px;
}
// X-Large devices (large desktops, less than 1400px)
// @media (min-width: $breakpoint-xxl) {
//     .container {
//         max-width: 1320px !important;
//     }
// }

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    background-color: #eeeeee;
    padding-top: 30px;
    height: 0;
    width: 100%;
    overflow: hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: $breakpoint-xl) {
    .cookies__modal {
        width: 50%;
        padding: 1rem;
        left: 0;
        right: 0;
        bottom: 15px;
    }
}

@media (max-width: $breakpoint-md) {
    .cookies__modal {
        width: 90%;
        padding: 0.5rem;
        left: 0;
        right: 0;
        bottom: 85px;
    }

}

@media (max-width: $breakpoint-lg) {
    .wrapper-content {
        &.fixed-tablet {
            margin-top: 128px;
        }
    }
    .bloco-home {
        &.home__margin-top {
            margin-top: 0;
        }
    }
    .regiao {
        padding: 0 0.25rem;

        &__icone {
            svg {
                max-width: 20px;
                max-height: 34px;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    html,
    body {
        height: auto;
    }
    .wrapper-content {
        margin-top: 0;

        &.top-fixed,
        &.fixed-tablet {
            margin-top: 0;
        }
    }
    .home__margin-top {
        margin-top: 0;
    }
    .main-title {
        font-size: 1.2rem;
        padding-top: 2rem;
        margin-bottom: 1rem;
    }
    .empty-results {
        min-height: 40vh;

        h2 {
            margin: 1rem 0;
        }
    }
    .form-helper-block {
        margin-bottom: 1rem;
    }
    .cookies__modal {
        width: 90%;
        padding: 0.5rem;
        left: 0;
        right: 0;
        bottom: 85px;
        font-size: 0.8rem;
    }
    .alert {
        font-size: 0.85rem;
    }

    .display-none {
        display: none;
    }
}
